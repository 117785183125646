import protectRoutes from '@/utils/protectRoutes';

const userSettingsRoutes = protectRoutes([
  {
    children: [
      {
        path: '/properties',
        name: 'user_properties',
        component: () => import('./views/UserProperties/UserProperties.vue'),
      },
    ],
    meta: {
      requiresPropertyUser: true,
    },
  },
  {
    children: [
      {
        path: '/contact',
        name: 'contact',
        component: () => import('./views/Contact/Contact.vue'),
      },
    ],
  },
].map((route) => ({
  ...route,
  component: () => import('@/layout/Benchmark/Benchmark.vue'),
})), {
  requiresDataAccess: true,
  showHeader: true,
  showSideNav: false,
});

export default userSettingsRoutes;
