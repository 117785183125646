const keysToCamelCase = (obj) => {
  // iterate over keys
  const newObj = Object.keys(obj).reduce((acc, key) => {
    // default new key is the actual one
    let newKey = key;

    const pattern = /([-_][a-z])/ig;
    // rename key if snake_case is detected
    if (key.match(pattern)) {
      newKey = key.replace(pattern, ($1) => $1
        .toUpperCase()
        .replace('-', '')
        .replace('_', ''));
    }
    // save key with old value
    acc[newKey] = obj[key];

    return acc;
  }, {});

  // return clean object
  return newObj;
};

export default keysToCamelCase;
