import protectRoutes from '@/utils/protectRoutes';

const myDestinationsRoutes = protectRoutes([
  {
    path: '/destinations',
    component: () => import('@/layout/Benchmark/Benchmark.vue'),
    children: [
      {
        path: '',
        component: () => import('@/modules/Compsets/layout/Compsets/Compsets.vue'),
        children: [
          {
            path: '',
            name: 'destinations',
            components: {
              default: () => import('./components/DestinationsTableView/DestinationsTableView.vue'),
            },
          },
          {
            path: '/destinations/edit/:id?',
            props: true,
            name: 'destinations_edit',
            components: {
              default: () => import('./components/CreateDestination/CreateDestination.vue'),
            },
          },
        ],
      },
    ],
  },
], {
  requiresStrictDMOUser: true,
  showHeader: true,
});

export default myDestinationsRoutes;
