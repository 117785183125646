import { format } from 'd3-format';

const formatValue = (value, dataFormat, forceAbs) => {
  if (typeof value !== 'number' || !dataFormat) {
    return 0;
  }

  // dont show negative percentages
  const absValue = forceAbs || dataFormat.match(/%/)
    ? Math.abs(value)
    : value;

  return format(dataFormat)(absValue).replace(/−/, '-');
};

export default formatValue;
