import useRequestCache from '@/utils/useRequestCache';
import { dataDogAction } from '@/utils/dataDog';
import initStatRequest from '@/utils/initStatRequest';
import getGeolocationVisitsData from '@/utils/getGeolocationVisitsData';
import getOtasData from '@/utils/getOtasData';

import useCalendarViewState from '@/state/useCalendarViewState';
import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';

const {
  isBreakdownOverviewSection,
  setOverviewTableSection,
  setDataId,
  clearSectionData,
  setRunningCalls,
  setIsSectionDataLoading,
  setShouldGetOverviewStats,
  isCalendarView,
  currentPermissions,
  setPipeStatus,
  sectionName,
} = useSectionState();

const {
  currentCalendarPipe,
} = useCalendarViewState();

const {
  isOverview,
} = useMainState();

const {
  usedCacheSize,
} = useRequestCache();

const getStats = async (options = {}) => {
  const {
    reset,
    getGeolocationStats,
  } = options;
  if (!isOverview.value
    && isBreakdownOverviewSection.value) {
    setOverviewTableSection('overview');
  }

  // clear previous data?
  if (reset) {
    /*
      When we are in breakdown view and make any action that requires resetting stats
      make sure that we allow getting the default overview data when changing back to that view
    */
    if (isBreakdownOverviewSection.value) {
      setShouldGetOverviewStats(true);
    }
    // current data unique ID
    setDataId();
    // abort running endpoint calls
    setRunningCalls('data');
    // clear property data
    clearSectionData();
  }

  if (getGeolocationStats) {
    getGeolocationVisitsData();
  }

  if (sectionName.value === 'disparities') {
    getOtasData();
  }

  if (isCalendarView.value) {
    if (!currentPermissions.value.calendar) {
      setPipeStatus(currentCalendarPipe.value, { loading: false });
      return;
    }
    // eslint-disable-next-line no-param-reassign
    Object.assign(options, {
      isCtpCall: false,
      pipe: currentCalendarPipe.value,
    });
  }

  setIsSectionDataLoading(true);
  await initStatRequest(options);
  setIsSectionDataLoading(false);

  dataDogAction('userCacheUpdated', {
    usedCache: usedCacheSize.value,
  });
};

export default getStats;
