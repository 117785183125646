const getInt = (str) => parseInt(str, 10);

const smartCeil = (val, isPercent) => {
  // sum 1.5% to the actual value (min margin)
  let newVal = val;
  if (isPercent) {
    newVal *= 100;
  }
  newVal += ((newVal / 100) * 1.5);
  // convert to string to be able to count digits and extract numbers
  const strVal = `${newVal}`.split('.')[0];
  // count digits
  const digitCount = strVal.length;
  // extract first digit
  const firstDigit = getInt(strVal[0]);

  /*
    We will generate a "comparator" number based on the digit count
    this comparator will be used for the correct calculation of the input value

    There are two different cases, taking for example a three digit number,
    the comparator will be 50 if num > 500, and 10 if lower.

    That gives us the following results for these different cases:
    143 -> comparator = 10 or 50
    2434 -> comparator = 100 or 500
    75300 -> comaprator = 1000 or 5000
    etc...
  */
  let comparator = '1';

  if (digitCount > 1) {
    for (let i = 1; i < digitCount - 1; i += 1) {
      comparator += '0';
    }
  }

  comparator = getInt(comparator);

  if (digitCount === 2
    || (digitCount > 2
    && firstDigit >= 5)) {
    comparator *= 5;
  }

  let result = Math.ceil(newVal / comparator) * comparator;

  if (isPercent) {
    result /= 100;
  }

  return result;
};

export default smartCeil;
