/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import updateSectionData from '@/utils/updateSectionData';
import isAbortError from '@/utils/isAbortError';
import fetchRequest from '@/utils/fetchRequest';

import { getPipeData } from '@/state/sectionStateConfig';
import useSectionState from '@/state/useSectionState';
import useFilterState from '@/state/useFilterState';
import useMainState from '@/state/useMainState';

const initStatRequest = async (options = {}) => {
  const {
    isCtpCall,
    forced,
    pipe = 'all',
    isBreakdownIteration,
    overrides = {},
  } = options;

  // Load state variables
  const {
    isBreakdownOverviewSection,
    breakdownPropertyCallList,
    setLoadingProperty,
    isLoadingProperty,
    failedProperties,
    clearLoadingProperties,
    dataId,
    isValidDataId,
    sectionCharts,
    sectionTables,
    isChartsView,
    isTablesView,
    sectionBreakdownPipe,
    sectionName,
  } = useSectionState();

  const { propertyId, featureFlags } = useMainState();

  /*
    Breakdown section only:
    The first call ends right after the next IF block.
    It will run the function again with every property to collect its data.
  */
  const beginBreakdownIteration = pipe.match(/all/) && isBreakdownOverviewSection.value;

  if (beginBreakdownIteration) {
    // for every property
    for (const property of breakdownPropertyCallList.value) {
      // loop was broken and already handled, return
      if (breakdownPropertyCallList.value.indexOf(property) === -1 || isLoadingProperty(property)) {
        return;
      }

      setLoadingProperty(property, true);

      try {
        // fetch property data
        await initStatRequest({
          pipe: `${sectionBreakdownPipe.value}/breakdown`,
          isBreakdownIteration: true,
          // override property id for endpoint request purposes
          overrides: {
            propertyId: property,
          },
        });
      } catch {
        // requests aborted
        break;
      }
    }

    clearLoadingProperties();
    return;
  }

  const {
    ctpTag,
  } = useFilterState();

  const {
    setPipeStatus,
  } = useSectionState();

  // is VD call but should make a next CTP call?
  const shouldMakeNextCtpCall = () => !isCtpCall && !!ctpTag.value;

  /* DATA FETCHING START */

  const pid = overrides.propertyId || propertyId.value;

  const currentDataId = dataId.value;

  // avoids double-loading CTP data
  const isCtpAlreadyRunning = (currPipe) => {
    const currPipeData = getPipeData(currPipe);

    return forced && currPipeData.loading;
  };

  let pipeOrder;

  // use current chart order to detect the new order of calls when filling section from scratch (pipe = ALL)
  if (pipe.match(/all/) && !isBreakdownOverviewSection.value) {
    let itemsList = [];

    const charts = [...(sectionCharts.value || [])];
    const tables = [...(sectionTables.value || [])];

    // set priority based on current view
    if (isChartsView.value) {
      itemsList = [...charts, ...tables];
    } else if (isTablesView.value) {
      itemsList = [...tables, ...charts];
    }

    // extract all pipes and save the name + validity under the current view
    const extractedPipes = itemsList.reduce((acc, curr) => {
      const pipeName = curr.data?.pipe.name;

      if (pipeName && !acc.filter((p) => p.pipeName === pipeName)[0]
        // don't call score/days pipes, they are both managed automatically
        && !pipeName.match(/score|days/)) {
        acc.push({ pipeName, valid: curr.valid });
      }

      return acc;
    }, []);

    if (featureFlags.value.wedding && isChartsView.value && sectionName.value === 'conversion') {
      // merge both lists so valid pipes load first
      pipeOrder = extractedPipes
        .filter((p) => p.valid)
        .map(({ pipeName }) => pipeName);
    } else {
      // mantaining the previous order, split valid non-valid pipes
      const firstPipes = extractedPipes.filter((p) => p.valid);
      const lastPipes = extractedPipes.filter((p) => !p.valid);

      // merge both lists so valid pipes load first
      pipeOrder = [...firstPipes, ...lastPipes].map(({ pipeName }) => pipeName);
    }
  } else {
    // ELSE: recursive specific pipe calling
    pipeOrder = [pipe];
  }

  const getTotals = async ({ isKpi, prefix, suffix }) => {
    const totalsPipe = suffix ? `${prefix}/${suffix}` : prefix;
    const daysPipe = `${prefix}/days`;

    const sectionDays = !!sectionName.value
      .match(/booking-window|length-of-stay/) && !isBreakdownIteration;

    if (isCtpAlreadyRunning(totalsPipe) || (sectionDays && isCtpAlreadyRunning(daysPipe))) {
      return;
    }

    const callTotalsPipeWithCTP = async () => initStatRequest({
      isCtpCall: true,
      isBreakdownIteration,
      pipe: totalsPipe,
      overrides,
    });

    // set loading status
    if (!isBreakdownIteration) {
      setPipeStatus(totalsPipe, {
        loading: true,
        isCtp: !!isCtpCall,
        currentDataId,
      });
    }
    try {
      // fetch data totals
      const { data: dataTotals, meta } = await fetchRequest(totalsPipe, {
        isCtpCall,
        isKpi,
        overrides,
      });

      updateSectionData({
        pid,
        pipe: totalsPipe,
        newData: dataTotals,
        currentDataId,
        isCtpCall,
        meta,
      });

      // update pipe's CTP status
      setPipeStatus(totalsPipe, { isCtp: !!isCtpCall, currentDataId });

      // is not a CTP call but should make it next?

      if (shouldMakeNextCtpCall()) {
        // only if section has no DAYS calls to do, proceed
        if (!sectionDays) {
          // fetch CTP data
          await callTotalsPipeWithCTP();
        }
      } else if (!isBreakdownIteration) {
        // end loading status
        setPipeStatus(totalsPipe, { loading: false, currentDataId });
      } else {
        setLoadingProperty(pid, false);
      }
    } catch (err) {
      if (!isBreakdownIteration && !isAbortError(err)) {
        setPipeStatus(totalsPipe, { hasError: true, currentDataId });
      } else {
        failedProperties.value.push(pid);
      }
      return;
    }

    // Conversion days
    if (sectionDays) { // has handler
      setPipeStatus(daysPipe, {
        loading: true,
        isCtp: !!isCtpCall,
        currentDataId,
      });

      try {
        const { data: dataDays, meta } = await fetchRequest(daysPipe, {
          isCtpCall,
          overrides,
        });

        updateSectionData({
          pid,
          pipe: daysPipe,
          newData: dataDays,
          currentDataId,
          isCtpCall,
          meta,
        });

        // should make a next CTP call?
        if (shouldMakeNextCtpCall()) {
          await callTotalsPipeWithCTP();
        } else {
          // finish
          setPipeStatus(daysPipe, { loading: false, currentDataId });
        }
      } catch (err) {
        if (!isAbortError(err)) {
          setPipeStatus(daysPipe, { hasError: true, currentDataId });
          console.error(err);
        }
      }
    }
  };

  const getOverTime = async ({
    type,
    currentPipe,
  }) => {
    if (isCtpAlreadyRunning(currentPipe)) {
      return;
    }

    // set loading status
    setPipeStatus(currentPipe, {
      loading: true,
      isCtp: !!isCtpCall,
    });

    try {
      const { data: dataOverTime, meta } = await fetchRequest(currentPipe, {
        isCtpCall,
        overrides,
      });

      updateSectionData({
        pid,
        pipe: currentPipe,
        newData: dataOverTime,
        currentDataId,
        isCtpCall,
        isCalendarCall: !!currentPipe.match(/calendar/),
        interval: currentPipe.match(/\/(date|week|month)$/)[1],
        isStayDates: type === 'stay-dates',
        meta,
      });

      // should make a next CTP call?
      if (shouldMakeNextCtpCall()) {
        await initStatRequest({
          isCtpCall: true,
          isBreakdownIteration,
          pipe: currentPipe,
        });
      } else {
        // end loading
        setPipeStatus(currentPipe, { loading: false, currentDataId });
      }
    } catch (err) {
      if (!isAbortError(err)) {
        setPipeStatus(currentPipe, { hasError: true, currentDataId });
        console.error(err);
      }
    }
  };

  for (const p of pipeOrder) {
    // invalid data ID? (request mismatch)
    if (!isValidDataId(currentDataId)) break;

    if (p.match(/\/?(average|breakdown|kpi|correlated)/)) {
      // eslint-disable-next-line prefer-destructuring
      let [prefix, suffix] = p.split('/');

      const isKpi = !!p.match(/kpi/);
      const isCorrelated = !!p.match(/correlated/);

      if (isKpi) {
        suffix = '';
      } else if (!isCorrelated) {
        suffix = 'average';
      }
      await getTotals({ isKpi, prefix, suffix });
    } else {
      const type = (p.match(/(over-time|stay-dates)/) || [])[0];

      const { loading, initialized } = getPipeData(p);
      // pipe was not requested? (already loaded, user input may have changed the order)
      if (!loading && !initialized && !forced) break;

      await getOverTime({
        type,
        currentPipe: p,
      });
    }
  }
};

export default initStatRequest;
