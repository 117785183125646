const countriesData = [
  { country: 'Andorra', country_code: 'ad' },
  { country: 'United Arab Emirates', country_code: 'ae' },
  { country: 'Afghanistan', country_code: 'af' },
  { country: 'Antigua and Barbuda', country_code: 'ag' },
  { country: 'Anguilla', country_code: 'ai' },
  { country: 'Albania', country_code: 'al' },
  { country: 'Armenia', country_code: 'am' },
  { country: 'Netherlands Antilles', country_code: 'an' },
  { country: 'Angola', country_code: 'ao' },
  { country: 'Antarctica', country_code: 'aq' },
  { country: 'Argentina', country_code: 'ar' },
  { country: 'American Samoa', country_code: 'as' },
  { country: 'Austria', country_code: 'at' },
  { country: 'Australia', country_code: 'au' },
  { country: 'Aruba', country_code: 'aw' },
  { country: 'Aland Islands', country_code: 'ax' },
  { country: 'Azerbaijan', country_code: 'az' },
  { country: 'Bosnia and Herzegovina', country_code: 'ba' },
  { country: 'Barbados', country_code: 'bb' },
  { country: 'Bangladesh', country_code: 'bd' },
  { country: 'Belgium', country_code: 'be' },
  { country: 'Burkina Faso', country_code: 'bf' },
  { country: 'Bulgaria', country_code: 'bg' },
  { country: 'Bahrain', country_code: 'bh' },
  { country: 'Burundi', country_code: 'bi' },
  { country: 'Benin', country_code: 'bj' },
  { country: 'Saint Barthelemy', country_code: 'bl' },
  { country: 'Bermuda', country_code: 'bm' },
  { country: 'Brunei', country_code: 'bn' },
  { country: 'Bolivia', country_code: 'bo' },
  { country: 'Bonaire, Saint Eustatius and Saba', country_code: 'bq' },
  { country: 'Brazil', country_code: 'br' },
  { country: 'Bahamas', country_code: 'bs' },
  { country: 'Bhutan', country_code: 'bt' },
  { country: 'Bouvet Island', country_code: 'bv' },
  { country: 'Botswana', country_code: 'bw' },
  { country: 'Belarus', country_code: 'by' },
  { country: 'Belize', country_code: 'bz' },
  { country: 'Canada', country_code: 'ca' },
  { country: 'Cocos Islands', country_code: 'cc' },
  { country: 'Democratic Republic of the Congo', country_code: 'cd' },
  { country: 'Central African Republic', country_code: 'cf' },
  { country: 'Republic of the Congo', country_code: 'cg' },
  { country: 'Switzerland', country_code: 'ch' },
  { country: 'Ivory Coast', country_code: 'ci' },
  { country: 'Cook Islands', country_code: 'ck' },
  { country: 'Chile', country_code: 'cl' },
  { country: 'Cameroon', country_code: 'cm' },
  { country: 'China', country_code: 'cn' },
  { country: 'Colombia', country_code: 'co' },
  { country: 'Costa Rica', country_code: 'cr' },
  { country: 'Serbia and Montenegro', country_code: 'cs' },
  { country: 'Cuba', country_code: 'cu' },
  { country: 'Cape Verde', country_code: 'cv' },
  { country: 'Curacao', country_code: 'cw' },
  { country: 'Christmas Island', country_code: 'cx' },
  { country: 'Cyprus', country_code: 'cy' },
  { country: 'Czech Republic', country_code: 'cz' },
  { country: 'Germany', country_code: 'de' },
  { country: 'Djibouti', country_code: 'dj' },
  { country: 'Denmark', country_code: 'dk' },
  { country: 'Dominica', country_code: 'dm' },
  { country: 'Dominican Republic', country_code: 'do' },
  { country: 'Algeria', country_code: 'dz' },
  { country: 'Ecuador', country_code: 'ec' },
  { country: 'Estonia', country_code: 'ee' },
  { country: 'Egypt', country_code: 'eg' },
  { country: 'Western Sahara', country_code: 'eh' },
  { country: 'Eritrea', country_code: 'er' },
  { country: 'Spain', country_code: 'es' },
  { country: 'Ethiopia', country_code: 'et' },
  { country: 'Finland', country_code: 'fi' },
  { country: 'Fiji', country_code: 'fj' },
  { country: 'Falkland Islands', country_code: 'fk' },
  { country: 'Micronesia', country_code: 'fm' },
  { country: 'Faroe Islands', country_code: 'fo' },
  { country: 'France', country_code: 'fr' },
  { country: 'Gabon', country_code: 'ga' },
  { country: 'United Kingdom', country_code: 'gb' },
  { country: 'Grenada', country_code: 'gd' },
  { country: 'Georgia', country_code: 'ge' },
  { country: 'French Guiana', country_code: 'gf' },
  { country: 'Guernsey', country_code: 'gg' },
  { country: 'Ghana', country_code: 'gh' },
  { country: 'Gibraltar', country_code: 'gi' },
  { country: 'Greenland', country_code: 'gl' },
  { country: 'Gambia', country_code: 'gm' },
  { country: 'Guinea', country_code: 'gn' },
  { country: 'Guadeloupe', country_code: 'gp' },
  { country: 'Equatorial Guinea', country_code: 'gq' },
  { country: 'Greece', country_code: 'gr' },
  { country: 'South Georgia and the South Sandwich Islands', country_code: 'gs' },
  { country: 'Guatemala', country_code: 'gt' },
  { country: 'Guam', country_code: 'gu' },
  { country: 'Guinea-Bissau', country_code: 'gw' },
  { country: 'Guyana', country_code: 'gy' },
  { country: 'Hong Kong', country_code: 'hk' },
  { country: 'Heard Island and McDonald Islands', country_code: 'hm' },
  { country: 'Honduras', country_code: 'hn' },
  { country: 'Croatia', country_code: 'hr' },
  { country: 'Haiti', country_code: 'ht' },
  { country: 'Hungary', country_code: 'hu' },
  { country: 'Indonesia', country_code: 'id' },
  { country: 'Ireland', country_code: 'ie' },
  { country: 'Israel', country_code: 'il' },
  { country: 'Isle of Man', country_code: 'im' },
  { country: 'India', country_code: 'in' },
  { country: 'British Indian Ocean Territory', country_code: 'io' },
  { country: 'Iraq', country_code: 'iq' },
  { country: 'Iran', country_code: 'ir' },
  { country: 'Iceland', country_code: 'is' },
  { country: 'Italy', country_code: 'it' },
  { country: 'Jersey', country_code: 'je' },
  { country: 'Jamaica', country_code: 'jm' },
  { country: 'Jordan', country_code: 'jo' },
  { country: 'Japan', country_code: 'jp' },
  { country: 'Kenya', country_code: 'ke' },
  { country: 'Kyrgyzstan', country_code: 'kg' },
  { country: 'Cambodia', country_code: 'kh' },
  { country: 'Kiribati', country_code: 'ki' },
  { country: 'Comoros', country_code: 'km' },
  { country: 'Saint Kitts and Nevis', country_code: 'kn' },
  { country: 'North Korea', country_code: 'kp' },
  { country: 'South Korea', country_code: 'kr' },
  { country: 'Kuwait', country_code: 'kw' },
  { country: 'Cayman Islands', country_code: 'ky' },
  { country: 'Kazakhstan', country_code: 'kz' },
  { country: 'Laos', country_code: 'la' },
  { country: 'Lebanon', country_code: 'lb' },
  { country: 'Saint Lucia', country_code: 'lc' },
  { country: 'Liechtenstein', country_code: 'li' },
  { country: 'Sri Lanka', country_code: 'lk' },
  { country: 'Liberia', country_code: 'lr' },
  { country: 'Lesotho', country_code: 'ls' },
  { country: 'Lithuania', country_code: 'lt' },
  { country: 'Luxembourg', country_code: 'lu' },
  { country: 'Latvia', country_code: 'lv' },
  { country: 'Libya', country_code: 'ly' },
  { country: 'Morocco', country_code: 'ma' },
  { country: 'Monaco', country_code: 'mc' },
  { country: 'Moldova', country_code: 'md' },
  { country: 'Montenegro', country_code: 'me' },
  { country: 'Saint Martin', country_code: 'mf' },
  { country: 'Madagascar', country_code: 'mg' },
  { country: 'Marshall Islands', country_code: 'mh' },
  { country: 'Macedonia', country_code: 'mk' },
  { country: 'Mali', country_code: 'ml' },
  { country: 'Myanmar', country_code: 'mm' },
  { country: 'Mongolia', country_code: 'mn' },
  { country: 'Macao', country_code: 'mo' },
  { country: 'Northern Mariana Islands', country_code: 'mp' },
  { country: 'Martinique', country_code: 'mq' },
  { country: 'Mauritania', country_code: 'mr' },
  { country: 'Montserrat', country_code: 'ms' },
  { country: 'Malta', country_code: 'mt' },
  { country: 'Mauritius', country_code: 'mu' },
  { country: 'Maldives', country_code: 'mv' },
  { country: 'Malawi', country_code: 'mw' },
  { country: 'Mexico', country_code: 'mx' },
  { country: 'Malaysia', country_code: 'my' },
  { country: 'Mozambique', country_code: 'mz' },
  { country: 'Namibia', country_code: 'na' },
  { country: 'New Caledonia', country_code: 'nc' },
  { country: 'Niger', country_code: 'ne' },
  { country: 'Norfolk Island', country_code: 'nf' },
  { country: 'Nigeria', country_code: 'ng' },
  { country: 'Nicaragua', country_code: 'ni' },
  { country: 'Netherlands', country_code: 'nl' },
  { country: 'Norway', country_code: 'no' },
  { country: 'Nepal', country_code: 'np' },
  { country: 'Nauru', country_code: 'nr' },
  { country: 'Niue', country_code: 'nu' },
  { country: 'New Zealand', country_code: 'nz' },
  { country: 'Oman', country_code: 'om' },
  { country: 'Panama', country_code: 'pa' },
  { country: 'Peru', country_code: 'pe' },
  { country: 'French Polynesia', country_code: 'pf' },
  { country: 'Papua New Guinea', country_code: 'pg' },
  { country: 'Philippines', country_code: 'ph' },
  { country: 'Pakistan', country_code: 'pk' },
  { country: 'Poland', country_code: 'pl' },
  { country: 'Saint Pierre and Miquelon', country_code: 'pm' },
  { country: 'Pitcairn', country_code: 'pn' },
  { country: 'Puerto Rico', country_code: 'pr' },
  { country: 'Palestinian Territory', country_code: 'ps' },
  { country: 'Portugal', country_code: 'pt' },
  { country: 'Palau', country_code: 'pw' },
  { country: 'Paraguay', country_code: 'py' },
  { country: 'Qatar', country_code: 'qa' },
  { country: 'Reunion', country_code: 're' },
  { country: 'Romania', country_code: 'ro' },
  { country: 'Serbia', country_code: 'rs' },
  { country: 'Russia', country_code: 'ru' },
  { country: 'Rwanda', country_code: 'rw' },
  { country: 'Saudi Arabia', country_code: 'sa' },
  { country: 'Solomon Islands', country_code: 'sb' },
  { country: 'Seychelles', country_code: 'sc' },
  { country: 'Sudan', country_code: 'sd' },
  { country: 'Sweden', country_code: 'se' },
  { country: 'Singapore', country_code: 'sg' },
  { country: 'Saint Helena', country_code: 'sh' },
  { country: 'Slovenia', country_code: 'si' },
  { country: 'Svalbard and Jan Mayen', country_code: 'sj' },
  { country: 'Slovakia', country_code: 'sk' },
  { country: 'Sierra Leone', country_code: 'sl' },
  { country: 'San Marino', country_code: 'sm' },
  { country: 'Senegal', country_code: 'sn' },
  { country: 'Somalia', country_code: 'so' },
  { country: 'Suriname', country_code: 'sr' },
  { country: 'South Sudan', country_code: 'ss' },
  { country: 'Sao Tome and Principe', country_code: 'st' },
  { country: 'El Salvador', country_code: 'sv' },
  { country: 'Sint Maarten', country_code: 'sx' },
  { country: 'Syria', country_code: 'sy' },
  { country: 'Swaziland', country_code: 'sz' },
  { country: 'Turks and Caicos Islands', country_code: 'tc' },
  { country: 'Chad', country_code: 'td' },
  { country: 'French Southern Territories', country_code: 'tf' },
  { country: 'Togo', country_code: 'tg' },
  { country: 'Thailand', country_code: 'th' },
  { country: 'Tajikistan', country_code: 'tj' },
  { country: 'Tokelau', country_code: 'tk' },
  { country: 'East Timor', country_code: 'tl' },
  { country: 'Turkmenistan', country_code: 'tm' },
  { country: 'Tunisia', country_code: 'tn' },
  { country: 'Tonga', country_code: 'to' },
  { country: 'Turkey', country_code: 'tr' },
  { country: 'Trinidad and Tobago', country_code: 'tt' },
  { country: 'Tuvalu', country_code: 'tv' },
  { country: 'Taiwan', country_code: 'tw' },
  { country: 'Tanzania', country_code: 'tz' },
  { country: 'Ukraine', country_code: 'ua' },
  { country: 'Uganda', country_code: 'ug' },
  { country: 'United States Minor Outlying Islands', country_code: 'um' },
  { country: 'United States', country_code: 'us' },
  { country: 'Uruguay', country_code: 'uy' },
  { country: 'Uzbekistan', country_code: 'uz' },
  { country: 'Vatican', country_code: 'va' },
  { country: 'Saint Vincent and the Grenadines', country_code: 'vc' },
  { country: 'Venezuela', country_code: 've' },
  { country: 'British Virgin Islands', country_code: 'vg' },
  { country: 'U.S. Virgin Islands', country_code: 'vi' },
  { country: 'Vietnam', country_code: 'vn' },
  { country: 'Vanuatu', country_code: 'vu' },
  { country: 'Wallis and Futuna', country_code: 'wf' },
  { country: 'Samoa', country_code: 'ws' },
  { country: 'Kosovo', country_code: 'xk' },
  { country: 'Yemen', country_code: 'ye' },
  { country: 'Mayotte', country_code: 'yt' },
  { country: 'South Africa', country_code: 'za' },
  { country: 'Zambia', country_code: 'zm' },
  { country: 'Zimbabwe', country_code: 'zw' },
];

export default countriesData;
