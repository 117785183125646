import { reactive } from 'vue';

const initialPipeConfig = {
  isCtp: false, // contains CTP data
  loading: true, // is loading (default true for visual loader)
  dataId: 0, // pipe data ID (needed to check differences with main dataID)
  error: false, // pipe errors?
  initialized: false, // real "loading" status
};

// totals
export const summaryPropertyTotalPipe = reactive({ name: 'summary-kpi', ...initialPipeConfig });
export const conversionTotalPipe = reactive({ name: 'conversion/average', ...initialPipeConfig });
export const revenueTotalPipe = reactive({ name: 'revenue/average', ...initialPipeConfig });
export const bookingsTotalPipe = reactive({ name: 'bookings/average', ...initialPipeConfig });
export const demandTotalPipe = reactive({ name: 'demand/average', ...initialPipeConfig });
export const bookingWindowTotalPipe = reactive({
  ...initialPipeConfig,
  name: 'booking-window/average',
  mixConfig: { metrics: ['searchWindow', 'bookingWindow'], mixInto: 'searchesVsBookings' },
});
export const lengthOfStayTotalPipe = reactive({
  ...initialPipeConfig,
  name: 'length-of-stay/average',
  mixConfig: { metrics: ['searchStay', 'bookingStay'], mixInto: 'searchesVsBookings' },
});
export const ratesTotalPipe = reactive({ name: 'rates/average', ...initialPipeConfig });
export const disparitiesTotalPipe = reactive({
  ...initialPipeConfig,
  name: 'disparities/average',
  mixConfig: { metrics: ['amount', 'frequency'], mixInto: 'disparities' },
});
export const visitorProfilePipe = reactive({ name: 'visitors/average', ...initialPipeConfig });

// over time
export const conversionOverTimeDatePipe = reactive({ name: 'conversion/over-time/date', ...initialPipeConfig });
export const conversionOverTimeCalendarDatePipe = reactive({ name: 'conversion/over-time/calendar/date', ...initialPipeConfig });
export const conversionOverTimeWeekPipe = reactive({ name: 'conversion/over-time/week', ...initialPipeConfig });
export const conversionOverTimeMonthPipe = reactive({ name: 'conversion/over-time/month', ...initialPipeConfig });

export const revenueOverTimeDatePipe = reactive({ name: 'revenue/over-time/date', ...initialPipeConfig });
export const revenueOverTimeCalendarDatePipe = reactive({ name: 'revenue/over-time/calendar/date', ...initialPipeConfig });
export const revenueOverTimeWeekPipe = reactive({ name: 'revenue/over-time/week', ...initialPipeConfig });
export const revenueOverTimeMonthPipe = reactive({ name: 'revenue/over-time/month', ...initialPipeConfig });

export const bookingsOverTimeDatePipe = reactive({ name: 'bookings/over-time/date', ...initialPipeConfig });
export const bookingsOverTimeCalendarDatePipe = reactive({ name: 'bookings/over-time/calendar/date', ...initialPipeConfig });
export const bookingsOverTimeWeekPipe = reactive({ name: 'bookings/over-time/week', ...initialPipeConfig });
export const bookingsOverTimeMonthPipe = reactive({ name: 'bookings/over-time/month', ...initialPipeConfig });

export const demandOverTimeDatePipe = reactive({ name: 'demand/over-time/date', ...initialPipeConfig });
export const demandOverTimeCalendarDatePipe = reactive({ name: 'demand/over-time/calendar/date', ...initialPipeConfig });
export const demandOverTimeWeekPipe = reactive({ name: 'demand/over-time/week', ...initialPipeConfig });
export const demandOverTimeMonthPipe = reactive({ name: 'demand/over-time/month', ...initialPipeConfig });

export const bookingWindowOverTimeDatePipe = reactive({ name: 'booking-window/over-time/date', ...initialPipeConfig });
export const bookingWindowOverTimeCalendarDatePipe = reactive({ name: 'booking-window/over-time/calendar/date', ...initialPipeConfig });
export const bookingWindowOverTimeWeekPipe = reactive({ name: 'booking-window/over-time/week', ...initialPipeConfig });
export const bookingWindowOverTimeMonthPipe = reactive({ name: 'booking-window/over-time/month', ...initialPipeConfig });

export const lengthOfStayOverTimeDatePipe = reactive({ name: 'length-of-stay/over-time/date', ...initialPipeConfig });
export const lengthOfStayOverTimeCalendarDatePipe = reactive({ name: 'length-of-stay/over-time/calendar/date', ...initialPipeConfig });
export const lengthOfStayOverTimeWeekPipe = reactive({ name: 'length-of-stay/over-time/week', ...initialPipeConfig });
export const lengthOfStayOverTimeMonthPipe = reactive({ name: 'length-of-stay/over-time/month', ...initialPipeConfig });

export const ratesOverTimeDatePipe = reactive({ name: 'rates/over-time/date', ...initialPipeConfig });
export const ratesOverTimeCalendarDatePipe = reactive({ name: 'rates/over-time/calendar/date', ...initialPipeConfig });
export const ratesOverTimeWeekPipe = reactive({ name: 'rates/over-time/week', ...initialPipeConfig });
export const ratesOverTimeMonthPipe = reactive({ name: 'rates/over-time/month', ...initialPipeConfig });

export const disparitiesOverTimeDatePipe = reactive({ name: 'disparities/over-time/date', ...initialPipeConfig });
export const disparitiesOverTimeCalendarDatePipe = reactive({ name: 'disparities/over-time/calendar/date', ...initialPipeConfig });
export const disparitiesOverTimeWeekPipe = reactive({ name: 'disparities/over-time/week', ...initialPipeConfig });
export const disparitiesOverTimeMonthPipe = reactive({ name: 'disparities/over-time/month', ...initialPipeConfig });

// stay dates
export const conversionStayDatesDatePipe = reactive({ name: 'conversion/stay-dates/date', ...initialPipeConfig });
export const conversionStayDatesCalendarDatePipe = reactive({ name: 'conversion/stay-dates/calendar/date', ...initialPipeConfig });
export const conversionStayDatesWeekPipe = reactive({ name: 'conversion/stay-dates/week', ...initialPipeConfig });
export const conversionStayDatesMonthPipe = reactive({ name: 'conversion/stay-dates/month', ...initialPipeConfig });

export const revenueStayDatesDatePipe = reactive({ name: 'revenue/stay-dates/date', ...initialPipeConfig });
export const revenueStayDatesCalendarDatePipe = reactive({ name: 'revenue/stay-dates/calendar/date', ...initialPipeConfig });
export const revenueStayDatesWeekPipe = reactive({ name: 'revenue/stay-dates/week', ...initialPipeConfig });
export const revenueStayDatesMonthPipe = reactive({ name: 'revenue/stay-dates/month', ...initialPipeConfig });

export const bookingsStayDatesDatePipe = reactive({ name: 'bookings/stay-dates/date', ...initialPipeConfig });
export const bookingsStayDatesCalendarDatePipe = reactive({ name: 'bookings/stay-dates/calendar/date', ...initialPipeConfig });
export const bookingsStayDatesWeekPipe = reactive({ name: 'bookings/stay-dates/week', ...initialPipeConfig });
export const bookingsStayDatesMonthPipe = reactive({ name: 'bookings/stay-dates/month', ...initialPipeConfig });

export const demandStayDatesDatePipe = reactive({ name: 'demand/stay-dates/date', ...initialPipeConfig });
export const demandStayDatesCalendarDatePipe = reactive({ name: 'demand/stay-dates/calendar/date', ...initialPipeConfig });
export const demandStayDatesWeekPipe = reactive({ name: 'demand/stay-dates/week', ...initialPipeConfig });
export const demandStayDatesMonthPipe = reactive({ name: 'demand/stay-dates/month', ...initialPipeConfig });

export const lengthOfStayStayDatesDatePipe = reactive({ name: 'length-of-stay/stay-dates/date', ...initialPipeConfig });
export const lengthOfStayStayDatesCalendarDatePipe = reactive({ name: 'length-of-stay/stay-dates/calendar/date', ...initialPipeConfig });
export const lengthOfStayStayDatesWeekPipe = reactive({ name: 'length-of-stay/stay-dates/week', ...initialPipeConfig });
export const lengthOfStayStayDatesMonthPipe = reactive({ name: 'length-of-stay/stay-dates/month', ...initialPipeConfig });

export const ratesStayDatesDatePipe = reactive({ name: 'rates/stay-dates/date', ...initialPipeConfig });
export const ratesStayDatesCalendarDatePipe = reactive({ name: 'rates/stay-dates/calendar/date', ...initialPipeConfig });
export const ratesStayDatesWeekPipe = reactive({ name: 'rates/stay-dates/week', ...initialPipeConfig });
export const ratesStayDatesMonthPipe = reactive({ name: 'rates/stay-dates/month', ...initialPipeConfig });

export const disparitiesStayDatesDatePipe = reactive({ name: 'disparities/stay-dates/date', ...initialPipeConfig });
export const disparitiesStayDatesCalendarDatePipe = reactive({ name: 'disparities/stay-dates/calendar/date', ...initialPipeConfig });
export const disparitiesStayDatesWeekPipe = reactive({ name: 'disparities/stay-dates/week', ...initialPipeConfig });
export const disparitiesStayDatesMonthPipe = reactive({ name: 'disparities/stay-dates/month', ...initialPipeConfig });

// curves
export const bookingWindowDaysPipe = reactive({ name: 'booking-window/days', ...initialPipeConfig });
export const lengthOfStayDaysPipe = reactive({ name: 'length-of-stay/days', ...initialPipeConfig });

// scores
export const scoreMetricsTotalPipe = reactive({ name: 'score', ...initialPipeConfig });
export const scoreOverTimeMonthPipe = reactive({ name: 'score/over-time', ...initialPipeConfig });

// other
export const geolocationVisitsPipe = reactive({ name: 'geolocation/visits', ...initialPipeConfig });
export const otasPipe = reactive({ name: 'otas', ...initialPipeConfig });
export const commonPipeConfig = [
  { key: 'geolocationVisits', pipe: geolocationVisitsPipe },
];

let pipes = [
  // totals
  summaryPropertyTotalPipe,
  conversionTotalPipe,
  revenueTotalPipe,
  bookingsTotalPipe,
  demandTotalPipe,
  bookingWindowTotalPipe,
  lengthOfStayTotalPipe,
  ratesTotalPipe,
  disparitiesTotalPipe,
  visitorProfilePipe,
  // over time
  conversionOverTimeDatePipe,
  conversionOverTimeCalendarDatePipe,
  conversionOverTimeWeekPipe,
  conversionOverTimeMonthPipe,
  revenueOverTimeDatePipe,
  revenueOverTimeCalendarDatePipe,
  revenueOverTimeWeekPipe,
  revenueOverTimeMonthPipe,
  bookingsOverTimeDatePipe,
  bookingsOverTimeCalendarDatePipe,
  bookingsOverTimeWeekPipe,
  bookingsOverTimeMonthPipe,
  demandOverTimeDatePipe,
  demandOverTimeCalendarDatePipe,
  demandOverTimeWeekPipe,
  demandOverTimeMonthPipe,
  bookingWindowOverTimeDatePipe,
  bookingWindowOverTimeCalendarDatePipe,
  bookingWindowOverTimeWeekPipe,
  bookingWindowOverTimeMonthPipe,
  lengthOfStayOverTimeDatePipe,
  lengthOfStayOverTimeCalendarDatePipe,
  lengthOfStayOverTimeWeekPipe,
  lengthOfStayOverTimeMonthPipe,
  ratesOverTimeDatePipe,
  ratesOverTimeCalendarDatePipe,
  ratesOverTimeWeekPipe,
  ratesOverTimeMonthPipe,
  disparitiesOverTimeDatePipe,
  disparitiesOverTimeCalendarDatePipe,
  disparitiesOverTimeWeekPipe,
  disparitiesOverTimeMonthPipe,
  // stay dates
  conversionStayDatesDatePipe,
  conversionStayDatesCalendarDatePipe,
  conversionStayDatesWeekPipe,
  conversionStayDatesMonthPipe,
  revenueStayDatesDatePipe,
  revenueStayDatesCalendarDatePipe,
  revenueStayDatesWeekPipe,
  revenueStayDatesMonthPipe,
  bookingsStayDatesDatePipe,
  bookingsStayDatesCalendarDatePipe,
  bookingsStayDatesWeekPipe,
  bookingsStayDatesMonthPipe,
  demandStayDatesDatePipe,
  demandStayDatesCalendarDatePipe,
  demandStayDatesWeekPipe,
  demandStayDatesMonthPipe,
  lengthOfStayStayDatesDatePipe,
  lengthOfStayStayDatesCalendarDatePipe,
  lengthOfStayStayDatesWeekPipe,
  lengthOfStayStayDatesMonthPipe,
  ratesStayDatesDatePipe,
  ratesStayDatesCalendarDatePipe,
  ratesStayDatesWeekPipe,
  ratesStayDatesMonthPipe,
  disparitiesStayDatesDatePipe,
  disparitiesStayDatesCalendarDatePipe,
  disparitiesStayDatesWeekPipe,
  disparitiesStayDatesMonthPipe,
  // curves
  bookingWindowDaysPipe,
  lengthOfStayDaysPipe,
  // score
  scoreMetricsTotalPipe,
  scoreOverTimeMonthPipe,
  // other
  otasPipe,
  ...commonPipeConfig.map((p) => p.pipe),
];

export const createDynamicPipeConfig = (name) => {
  const newPipe = reactive({ name, ...initialPipeConfig, dynamic: true });

  pipes.push(newPipe);

  return newPipe;
};

export const clearDynamicPipes = () => {
  pipes = pipes.filter((pipe) => !pipe.dynamic);
};

export const getPipeData = (name) => (pipes
  .filter((pipe) => pipe.name === name) || [])[0] || initialPipeConfig;

const resetDefaults = (data) => {
  /* eslint-disable no-param-reassign */
  data.isCtp = initialPipeConfig.isCtp;
  data.error = initialPipeConfig.error;
  data.loading = initialPipeConfig.loading;
  data.initialized = initialPipeConfig.initialized;
};

export const resetPipesData = () => {
  pipes.forEach((pipe) => {
    if (pipe.name.match(/score|geolocation\/visits|otas/i)) {
      return;
    }

    const result = getPipeData(pipe.name);

    resetDefaults(result);
  });
};

export const resetScorePipe = () => {
  const result = getPipeData('score/over-time');

  resetDefaults(result);
};
