import { createApiClient } from '@thehotelsnetwork/vue-core';
import MockAdapter from 'axios-mock-adapter';
import moment from 'moment';

import env from '@/config/env';
import auth from '@/modules/Auth';

function envModeToEnvName(mode: string) {
  switch (mode) {
    case 'development':
      return 'development';
    case 'production':
      return 'production';
    default:
      return 'staging';
  }
}

const apiClient = createApiClient({
  environment: envModeToEnvName(env.MODE),
  authService: auth,
  httpClientOptions: {
    baseURL: env.VUE_APP_BENCHDIRECT_API_URL,
  },
});

const mock = new MockAdapter(apiClient, { onNoMatch: 'passthrough' });

mock.onGet(/user\/charts/).reply(() => new Promise((resolve) => {
  setTimeout(() => {
    resolve([200, {
      data: [
        // {
        //   id: 'TEST_ID_1',
        //   metric: 'conversion',
        //   over: 'disparities_frequency',
        // },
        {
          id: 'TEST_ID_2',
          metric: 'bookings',
          over: 'rates',
        },
        {
          id: 'TEST_ID_3',
          metric: 'daily_revenue',
          over: 'demand',
        },
        {
          id: 'TEST_ID_4',
          metric: 'conversion',
          over: 'rates',
        },
        {
          id: 'TEST_ID_5',
          metric: 'conversion',
          over: 'window',
        },
        {
          id: 'TEST_ID_6',
          metric: 'disparities_frequency',
          over: 'stay',
        },
        {
          id: 'TEST_ID_7',
          metric: 'daily_revenue',
          over: 'rates',
        },
      ],
    }]);
  }, 2000);
}));

mock.onPut(/user\/charts/).reply(200);

const quantumEndpoints = {
  // TEST_ID_1: '',
  TEST_ID_2: 'https://oswald.thn.app/quantum?sources[bookings][in_date_begin]={{in_date_begin}}&alias[bookings][source]=ep_quantum_bookings&sources[bookings][over]=rates&sources[bookings][interval]=25&sources[bookings][property_id]={{property_id}}&sources[bookings][in_date_end]={{in_date_end}}&sources[bookings]&sources[bookings][end_date]={{end_date}}&sources[bookings][start_date]={{start_date}}&sources[bookings][currency]={{currency}}&sources[bookings][fields]=total_bookings_avg&alias[bookings][fields]=total_bookings_avg:value&return=bookings',
  TEST_ID_3: 'https://oswald.thn.app/quantum?sources[bookings][in_date_begin]={{in_date_begin}}&alias[bookings][source]=ep_quantum_bookings&sources[bookings][over]=demand&sources[bookings][interval]=25&sources[bookings][property_id]={{property_id}}&sources[bookings][in_date_end]={{in_date_end}}&sources[bookings]&sources[bookings][end_date]={{end_date}}&sources[bookings][start_date]={{start_date}}&sources[bookings][currency]={{currency}}&sources[bookings][fields]=avg_day_revenue_avg&alias[bookings][fields]=avg_day_revenue_avg:value&return=bookings',
  TEST_ID_4: 'https://oswald.thn.app/quantum?sources[conversion][in_date_begin]={{in_date_begin}}&alias[conversion][source]=ep_quantum_conversion&sources[conversion][over]=rates&sources[conversion][interval]=25&sources[conversion][property_id]={{property_id}}&sources[conversion][in_date_end]={{in_date_end}}&sources[conversion]&sources[conversion][end_date]={{end_date}}&sources[conversion][start_date]={{start_date}}&sources[conversion][currency]={{currency}}&sources[conversion][fields]=total_conversion_rate_avg&alias[conversion][fields]=total_conversion_rate_avg:value&return=conversion',
  TEST_ID_5: 'https://oswald.thn.app/quantum?sources[conversion][in_date_begin]={{in_date_begin}}&alias[conversion][source]=ep_quantum_conversion&sources[conversion][over]=window&sources[conversion][interval]=25&sources[conversion][property_id]={{property_id}}&sources[conversion][in_date_end]={{in_date_end}}&sources[conversion]&sources[conversion][end_date]={{end_date}}&sources[conversion][start_date]={{start_date}}&sources[conversion][currency]={{currency}}&sources[conversion][fields]=total_conversion_rate_avg&alias[conversion][fields]=total_conversion_rate_avg:value&return=conversion',
  TEST_ID_6: 'https://oswald.thn.app/quantum?sources[disparities][in_date_begin]={{in_date_begin}}&alias[disparities][source]=ep_quantum_disparities&sources[disparities][over]=stay&sources[disparities][interval]=25&sources[disparities][property_id]={{property_id}}&sources[disparities][in_date_end]={{in_date_end}}&sources[disparities]&sources[disparities][end_date]={{end_date}}&sources[disparities][start_date]={{start_date}}&sources[disparities][currency]={{currency}}&sources[disparities][fields]=disparities_frequency_avg&alias[disparities][fields]=disparities_frequency_avg:value&return=disparities',
  TEST_ID_7: 'https://oswald.thn.app/quantum?sources[bookings][in_date_begin]={{in_date_begin}}&alias[bookings][source]=ep_quantum_bookings&sources[bookings][over]=rates&sources[bookings][interval]=25&sources[bookings][property_id]={{property_id}}&sources[bookings][in_date_end]={{in_date_end}}&sources[bookings]&sources[bookings][end_date]={{end_date}}&sources[bookings][start_date]={{start_date}}&sources[bookings][currency]={{currency}}&sources[bookings][fields]=avg_day_revenue_avg&alias[bookings][fields]=avg_day_revenue_avg:value&return=bookings',
};

mock.onGet(/correlated/).reply(async (config) => {
  try {
    const targetChart = config.url.match(/correlated\/(.+)\?/)[1];
    const startDate = config.url.match(/start_date=(\d{4}-\d{2}-\d{2})/)[1];
    const endDate = config.url.match(/end_date=(\d{4}-\d{2}-\d{2})/)[1];

    const params = {
      property_id: (config.url.match(/property_id=(\d+)/) ?? [])[1],
      start_date: startDate,
      end_date: endDate,
      in_date_begin: startDate,
      in_date_end: moment(startDate).add(1, 'year').format('YYYY-MM-DD'),
      currency: config.url.match(/currency=([A-Z]{3})/)[1],
    };

    const callUrl = Object
      .entries(params)
      .reduce((acc, [key, value]) => acc
        .replace(new RegExp(`{{${key}}}`), value), quantumEndpoints[targetChart]);

    if (callUrl) {
      const { data } = await (await fetch(callUrl)).json();
      return [
        200,
        {
          data: {
            [`default_${targetChart}`]: data 
          },
        },
      ];
    }
  } catch (err) {
    console.error(err);
  }
  return [500, {}];
});

export default apiClient;
