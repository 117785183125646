import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from 'tailwind-config';

const { theme } = resolveConfig(tailwindConfig);

/*
 * Shared config between charts
 */

// General
export const font = 'Avenir, sans-serif';
export const fontSizeBig = 18;
export const fontSizeMedium = 16;
export const fontSizeSmall = 14;
export const mainColor = theme.colors.primary['500'];
export const hotelColor = theme.colors.hotel['500'];
export const fontColor = theme.colors.base['500'];
export const fontBlack = theme.colors.base['700'];
export const fontDark = theme.colors.base['900'];
export const tooltip = { content: 'data' };

export const axis = {
  title: null,
  ticks: false,
  grid: false,
  tickRound: false,
  tickCount: 1,
  bandPosition: 0.5,
  labelFont: font,
  labelFontSize: fontSizeMedium,
  labelColor: fontBlack,
  labelPadding: 10,
  labelFontWeight: 500,
  domainColor: fontColor,
};

export const text = {
  font,
  fontSize: fontSizeMedium,
};

export const view = {
  stroke: null,
};

export const colorField = {
  field: 'color',
  type: 'nominal',
  scale: null,
  legend: null,
};

// Line
export const strokeDash = [6];
export const strokeWidth = 1.5;
export const lineChartWidth = 3;
export const activeLineChartWidth = 5;

// Bar
export const bigSize = 24;
export const smallSize = 16;
export const barChartYOffset = 20;
export const barChartSingleStep = 36;
export const barChartComparedStep = 50;
export const cornerRadius = 2;

/*
  Display the value inside the bar
  Show only if the value is above the specified threshold
  "highestValue" must be specified in rank charts
  in order to be applied to both concatenated charts,
  the rest will use the default highest value.
*/
export const getValueDisplayLayer = (position, format) => [
  { // current date values
    transform: [
      { calculate: 'abs(datum.value)', as: 'absValue' },
    ],
    mark: {
      type: 'text',
      align: position,
      fontWeight: 600,
      dx: position === 'right' ? -10 : 10,
      fontSize: fontSizeBig,
      tooltip,
      color: theme.colors.base['900'],
    },
    encoding: {
      text: {
        field: 'absValue',
        type: 'quantitative',
        axis: { format },
      },
    },
  },
];

export const getFixedBarValues = (highest) => ([
  { // fixed value (display only) calculated 20% of the max value in order to show labels
    calculate: `(${highest} * 20) / 100`,
    as: 'fixedMinValue',
  },
  { // white color for empty values
    calculate: 'datum.value ? datum.color : \'#FFFFFF\'',
    as: 'color',
  },
  { // new bar name
    calculate: 'datum.value || datum.fixedMinValue', as: 'valueBar',
  },
]);

export const getDiffValueDisplay = (position, isCtp) => ({ // past values & diff
  transform: [
    { filter: `abs(datum.pastValue) >${isCtp ? '=' : ''} 0` },
    {
      calculate: '((datum.diff === 0 && " ") || (abs(datum.diff) - datum.diff === 0 && "↑ ") || (abs(datum.diff) - datum.diff !== 0 && "↓ ") || "") + format(abs(datum.diff), ".0~%")', as: 'formattedDiff',
    },
  ],
  layer: [
    {
      mark: {
        color: fontBlack,
        type: 'text',
        align: position,
        fontWeight: 200,
        dx: position === 'left' ? 10 : -10,
        fontSize: fontSizeSmall,
      },
      encoding: {
        text: {
          field: 'formattedDiff',
          type: 'nominal',
        },
      },
    },
  ],
});
