import {
  percentFormat,
  numericCurrFormat,
  numericFormat,
} from '@/config/valueFormatConfig';

import { ChartBuilderMetricConfig } from '@/declarations/chartBuilder';

export const metricConfig: ChartBuilderMetricConfig = {
  conversion: {
    dataFormat: percentFormat,
    title: 'Total Conversion',
  },
  daily_revenue: {
    dataFormat: numericCurrFormat,
    title: 'Avg. Daily Revenue',
  },
  bookings: {
    dataFormat: numericFormat,
    title: 'Bookings',
  },
  searches: {
    dataFormat: numericFormat,
    title: 'Searches',
  },
  disparities_frequency: {
    dataFormat: percentFormat,
    title: 'Disparity Frequency',
  },
};

export const overConfig: ChartBuilderMetricConfig = {
  rates: {
    dataFormat: numericCurrFormat,
    title: 'Rates',
  },
  disparities_frequency: {
    dataFormat: percentFormat,
    title: 'Disparity Frequency',
  },
  window: {
    dataFormat: numericFormat,
    title: 'Booking Window',
  },
  stay: {
    dataFormat: numericFormat,
    title: 'Length of Stay',
  },
  demand: {
    dataFormat: numericFormat,
    title: 'Demand',
  },
};
