type ResponseHeaders = Record<string, string> & {
  'set-cookie'?: string[]
};

const getFilenameFromHeaders = (headers: ResponseHeaders = {}) => {
  let result = '';

  Object.values(headers)
    .some((headerValue) => {
      if (typeof headerValue !== 'string') {
        return;
      }

      const fileNameMatch = (headerValue.match(/filename="(.+)"/i) ?? [])[1];

      if (fileNameMatch) {
        result = fileNameMatch;
        return true;
      }

      return false;
    });

  return result;
};

export default getFilenameFromHeaders;
