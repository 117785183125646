import { ref, computed } from 'vue';

import moment from 'moment';

import i18n from '@/config/i18n';

import updateLocalStorage from '@/utils/updateLocalStorage';
import keysToCamelCase from '@/utils/keysToCamelCase';
import languages from '@/utils/languages';

import useDebugState from '@/state/useDebugState';

const {
  disableCountDown,
} = useDebugState();

/* State on this file:
*
*   - Property ID:  [Integer]
*   - Token:        [String]
*   - Compsets:     [Array]
*   - Compset Names:[Array]
*   - Hotel:        [String]
*/

const propertyId = ref(0);
const hotelId = ref(0);
const token = ref('');
const bearerToken = ref('');
const csrfToken = ref('');
const userProperties = ref([]);
const featureFlags = ref({});
const benchmarkUserProperties = computed(() => userProperties.value.filter((p) => p.active));
const activeUserProperties = computed(() => benchmarkUserProperties.value
  .filter((p) => p.onboarded));
const propertiesPendingTerms = computed(() => userProperties.value
  .filter((p) => !p.hasAcceptedTerms));
const isOverview = ref(false);
const isOnboarded = computed(() => !!(benchmarkUserProperties.value
  .filter((p) => p.propertyId === propertyId.value).pop() || {}).onboarded);
const language = ref('');
const lampoLang = computed(() => {
  const langList = {
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    it: 'it-IT',
    de: 'de-DE',
    pt: 'pt-PT',
    sv: 'sv-SE',
    th: 'th-TH',
  };

  return langList[language.value || 'en'];
});
const firstVisit = computed(() => (benchmarkUserProperties.value
  .filter((p) => p.propertyId === propertyId.value).pop() || {}).firstVisit || 0);
const isReady = computed(() => {
  if (disableCountDown.value) {
    return true;
  }
  return (benchmarkUserProperties.value
    .filter((p) => p.propertyId === propertyId.value).pop() || {}).ready;
});
// Is current selected property valid?
const isValid = computed(() => isOnboarded.value && isReady.value);
// Is there atleast one property valid to show its data?
const minPropertiesValid = computed(() => benchmarkUserProperties.value
  .some((p) => p.onboarded && p.ready));

const minPropertiesToShowCountdown = computed(() => benchmarkUserProperties.value
  .some((p) => p.onboarded));

// Methods
const setIsOverview = (val) => {
  isOverview.value = val;
};

const formatPropertyData = (p) => {
  // format rating value
  const rating = parseInt(p.rating, 10) || 0;

  // transform to camelCase
  const propertyData = keysToCamelCase({
    ...p,
  });

  return {
    scores: {}, // default, "propertyData" will overwrite if existing
    ...propertyData,
    rating,
    ready: p.countdownCompleted,
  };
};

const setUserProperties = (properties) => {
  userProperties.value = Array.isArray(properties) ? properties.map(formatPropertyData) : [];
};

const setPropertyData = (pid, data) => {
  userProperties.value = userProperties.value.map((p) => {
    let propertyData = { ...p };

    if (p.propertyId === pid) {
      propertyData = formatPropertyData({ ...propertyData, ...data });
    }

    return propertyData;
  });
};

const setLanguage = async (l) => {
  updateLocalStorage('language', l);
  // Update current app language
  const targetLang = languages.find((lang) => lang.value === l).value ?? 'en';
  const messages = await import(
    `@/locales/${targetLang}.json`
  );
  i18n.global.setLocaleMessage(targetLang, messages.default);
  i18n.global.locale.value = targetLang;

  moment.locale(targetLang);
  language.value = targetLang;
};
const getCurrentMessages = () => i18n.global.getLocaleMessage(
  i18n.global.locale.value,
);

const setProperty = (pid) => {
  let tmpPid = pid;

  const propertyExists = userProperties.value.some((property) => property.propertyId === tmpPid);

  if (!propertyExists) {
    tmpPid = 0;
  }

  // Property ID
  setIsOverview(tmpPid === 0);
  propertyId.value = tmpPid;
};

const setToken = (tkn) => {
  token.value = tkn;
};

const setBearerToken = (tkn) => {
  bearerToken.value = tkn;
};

const setCsrfToken = (tkn) => {
  csrfToken.value = tkn;
};

const setFlags = (flags) => {
  featureFlags.value = flags;
};

export default function useMainState() {
  return {
    // Data
    propertyId,
    hotelId,
    token,
    bearerToken,
    userProperties,
    benchmarkUserProperties,
    activeUserProperties,
    propertiesPendingTerms,
    isOverview,
    isOnboarded,
    firstVisit,
    isReady,
    isValid,
    minPropertiesValid,
    minPropertiesToShowCountdown,
    csrfToken,
    language,
    lampoLang,
    featureFlags,

    // Methods
    setProperty,
    setToken,
    setBearerToken,
    setUserProperties,
    setIsOverview,
    setCsrfToken,
    setLanguage,
    getCurrentMessages,
    setFlags,

    setPropertyData,
  };
}
