import calculateDiff from '@/utils/calculateDiff';

import useSectionState from '@/state/useSectionState';

const { compsetsFilter } = useSectionState();
/*
  Merges over-time data.
  For each compset, the new data is matched using the current base data index.
  If the new data array is shorter, the rest of the values will remain untouched (no past data)
  If the new data array is larger, only those that fit the base data will stay, and the rest will be discarded
*/
const mergeOverTimeDataValues = (baseData, newData) => compsetsFilter.value
  .reduce((acc, currCompset) => {
    // isolate data with provided compset
    const compsetBaseData = baseData.filter((i) => i.compsetTag === currCompset);
    const compsetNewData = newData.filter((i) => i.compsetTag === currCompset);

    // iterate over base data
    compsetBaseData.forEach((item, idx) => {
      // find next matching data
      const pastItem = compsetNewData[idx];
      // found?
      if (pastItem) {
        // merge base item and generate "past" values with the new data
        const mergedItem = {
          ...item,
          pastValue: pastItem.value,
          pastDate: pastItem.date,
          diff: calculateDiff(item.value, pastItem.value),
        };
        // push changes
        acc.push(mergedItem);
        return;
      }
      // else, push untouched baseData item
      acc.push(item);
    });
    return acc;
  }, []);

export default mergeOverTimeDataValues;
