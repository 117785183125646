import apiHelper from '@/utils/apiHelper';
import customCompsetStatus from '@/config/customCompsetStatus';

const getDestinations = async () => {
  const { data } = await apiHelper(
    'GET',
    '/destination-compset',
    null,
    { useCache: true },
  );

  return data.data.map((item) => {
    const {
      text: statusText,
      color: statusColor,
      tooltipText: statusTooltipText = '',
    } = customCompsetStatus[item.status || 'draft'];

    return {
      ...item,
      type: '',
      statusText,
      statusColor,
      statusTooltipText,
    };
  });
};

export default getDestinations;
