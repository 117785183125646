import { computed, ref, watch } from 'vue';

import { numericCurrFormat, numericFormat } from '@/config/valueFormatConfig';
import i18n from '@/config/i18n';

import {
  capitalize,
} from '@/utils/stringHelpers';

import bdLogger from '@/utils/bdLogger';
import mergeCalendarValues from '@/utils/mergeCalendarValues';

import { getPipeData } from '@/state/sectionStateConfig';
import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';
import getRandomCalendarData from '@/utils/getRandomCalendarData';

const { t } = i18n.global;

const { selectedMetric } = useFilterState();
const { propertyId, isOverview } = useMainState();
const {
  sectionName,
  hotel,
  compsetNames,
  getSectionData,
  compsets,
  sectionMetrics,
  currentPermissions,
} = useSectionState();

// compset to compare switch
const selectedCalendarFirstCompset = ref(null);
const selectedCalendarSecondCompset = ref(null);

const availableFirstCompsets = computed(() => compsetNames.value
  .filter((c) => c !== selectedCalendarSecondCompset.value));

const availableSecondCompsets = computed(() => compsetNames.value
  .filter((c) => c !== selectedCalendarFirstCompset.value));

const setSelectedCalendarFirstCompset = (val) => {
  selectedCalendarFirstCompset.value = val;
};

const setSelectedCalendarSecondCompset = (val) => {
  selectedCalendarSecondCompset.value = val;
};

// updated selected compset color theme
const selectedFirstCompsetTheme = computed(() => {
  const { color } = compsets.value
    .filter((c) => c.compset === selectedCalendarFirstCompset.value)[0] || {};

  return color;
});
// updated selected compset color theme
const selectedSecondCompsetTheme = computed(() => {
  const { color } = compsets.value
    .filter((c) => c.compset === selectedCalendarSecondCompset.value)[0] || {};

  return color;
});
// over-time / stay-dates switch
const calendarStatsType = ref('over-time');

// modal bar chart data format
const calendarMetricDataFormat = ref('');

const setCalendarMetricDataFormat = (val) => {
  calendarMetricDataFormat.value = val;
};

const currentSizeMeasureConfig = computed(() => {
  const {
    calendarMeasureText = '',
    placeholder = '',
  } = sectionMetrics.value.filter((m) => selectedMetric.value === m.name)[0] || {};

  return {
    metricName: t(placeholder),
    measureText: t(capitalize(calendarMeasureText)),
    format: calendarMeasureText === 'revenue' ? numericCurrFormat : numericFormat,
  };
});

// updated data pipe name
const currentCalendarPipe = computed(() => `${sectionName.value?.replace(/_/g, '')}/${calendarStatsType.value}/calendar/date`);
// updated data pipe status (loading, errors)
const pipeData = computed(() => getPipeData(currentCalendarPipe.value));

// computed raw data based on user input
const matchData = computed(() => {
  if (currentPermissions.value.calendar) {
    if (pipeData.value.loading) {
      return null;
    }
    const propertyData = getSectionData(propertyId.value);
    const typePrefix = calendarStatsType.value === 'stay-dates' ? 'Sd' : '';
    const dataKey = `${selectedMetric.value}${typePrefix}DateCal`;
    const selectedMetricData = propertyData[dataKey];
    return selectedMetricData.values;
  }

  const values = getRandomCalendarData({
    hotelName: hotel.value,
    sectionName: sectionName.value,
    isOverview: isOverview.value,
  });

  return values;
});

const dataByCompset = ref({});
// wait for data to have length and split by compset
watch(matchData, () => {
  dataByCompset.value = {};
  if (matchData.value !== null && matchData.value.length) {
    const tmpData = {};

    matchData.value.forEach((i) => {
      const compsetKey = tmpData[i.compset];

      if (!compsetKey) {
        tmpData[i.compset] = [];
      }

      tmpData[i.compset].push({
        ...i,
      });
    });

    dataByCompset.value = tmpData;
  }
});

// indicate which compsets are filled with data
const compsetsWithData = computed(() => compsetNames.value.filter((c) => {
  const compsetKey = dataByCompset.value[c];
  return compsetKey && !!compsetKey.length;
}));

// update selected compset value by priority and data length
watch(compsetsWithData, () => {
  if (compsetsWithData.value.length > 1) {
    [
      {
        setCompset: setSelectedCalendarFirstCompset,
        getUpdatedOrder: () => [
          selectedCalendarFirstCompset.value,
          hotel.value,
          ...availableFirstCompsets.value,
        ],
      },
      {
        setCompset: setSelectedCalendarSecondCompset,
        getUpdatedOrder: () => [
          selectedCalendarSecondCompset.value,
          'Proximity',
          ...availableSecondCompsets.value,
        ],
      },
    ].forEach(({ setCompset, getUpdatedOrder }) => {
      getUpdatedOrder().some((c) => {
        if (compsetsWithData.value.includes(c)) {
          setCompset(c);
          return true;
        }
        return false;
      });
    });
  }
});
// merge data with selected values
const calendarData = computed(() => {
  if (selectedCalendarFirstCompset.value && selectedCalendarSecondCompset.value) {
    const baseData = dataByCompset.value[selectedCalendarFirstCompset.value] || [];
    const secondaryData = dataByCompset.value[selectedCalendarSecondCompset.value] || [];

    if (baseData.length && secondaryData.length) {
      return mergeCalendarValues({
        baseData,
        secondaryData,
        selectedFirstCompsetTheme: selectedFirstCompsetTheme.value,
        selectedSecondCompsetTheme: selectedSecondCompsetTheme.value,
        isDisparitiesSection: sectionName.value === 'disparities',
        isPremiumPlan: currentPermissions.value.calendar,
      });
    }

    bdLogger.log(`Not enough calendar data to display: hotel data length: ${baseData.length} / compset data length: ${secondaryData.length}`);
  }
  return null;
});
const isValidCalendarData = computed(() => (
  !!((calendarData.value?.maxNegativeDiff
  && calendarData.value?.minNegativeDiff)
  || (calendarData.value?.minPositiveDiff
  && calendarData.value?.maxPositiveDiff))
));

export default function useCalendarViewState() {
  return {
    calendarStatsType,
    compsetsWithData,
    currentCalendarPipe,
    pipeData,
    selectedCalendarFirstCompset,
    selectedCalendarSecondCompset,
    availableFirstCompsets,
    availableSecondCompsets,
    calendarData,
    isValidCalendarData,
    selectedFirstCompsetTheme,
    selectedSecondCompsetTheme,
    calendarMetricDataFormat,
    currentSizeMeasureConfig,
    setSelectedCalendarFirstCompset,
    setSelectedCalendarSecondCompset,
    setCalendarMetricDataFormat,
  };
}
