import { userTracking } from '@/modules/userTracking';
import auth from '@/modules/Auth';

import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';
import useUserState from '@/state/useUserState';

import getPropertyData from '@/utils/getPropertyData';

import bdLogger from '@/utils/bdLogger';

import checkInternalUser from '@/utils/checkInternalUser';

import browserStorage from '@/modules/browserStorage';

export const track = (ev, { disableSendData } = {}) => {
  const event = `bd - ${ev}`;

  bdLogger.log(`Analytics event triggered: [${event}]`);

  checkInternalUser(() => {
    const {
      propertyId,
      isOverview,
    } = useMainState();
    const {
      sectionName,
      sectionView,
      currentPermissions,
    } = useSectionState();
    const {
      dateBeginYYYYMMDD,
      dateEndYYYYMMDD,
      ctpDateBeginYYYYMMDD,
      ctpDateEndYYYYMMDD,
      ctpTag,
      isStayDatesActive,
      sdDateBeginYYYYMMDD,
      sdDateEndYYYYMMDD,
      geolocations,
      devices,
      travelParty,
      filteredOtas,
      hotelType,
      hotelCategory,
      selectedMetric,
    } = useFilterState();

    // Data to send with every event
    const data = {};

    if (!disableSendData) {
      // Section

      data.section_name = sectionName.value;
      data.section_view = sectionView.value;

      if (selectedMetric.value) {
        data.section_selected_metric = selectedMetric.value;
      }

      // Hotel Data
      const pData = getPropertyData(propertyId.value);

      const { calendar, compsets } = currentPermissions.value;

      if (calendar) {
        data.hotel_plan = compsets ? 'BD Unlimited' : 'BD Pro';
      } else {
        data.hotel_plan = 'BD Free';
      }

      if (isOverview.value) {
        data.hotel_name = 'Overview';
      } else {
        data.hotel_name = pData.name;
        data.hotel_address = pData.address;
        data.hotel_chain_id = pData.chainId;
        data.hotel_city = pData.city;
        data.hotel_country = pData.country;
        data.hotel_currency = pData.currency;
        data.hotel_id = pData.hotelId;
        data.hotel_type = pData.hotelType;
        data.hotel_rating = pData.rating;
        data.hotel_rooms = pData.rooms;
      }

      // Dates
      data.browsing_date_begin = dateBeginYYYYMMDD.value;
      data.browsing_date_end = dateEndYYYYMMDD.value;

      if (ctpTag.value) {
        data.compare_to_date_begin = ctpDateBeginYYYYMMDD.value;
        data.compare_to_date_end = ctpDateEndYYYYMMDD.value;
      }

      if (isStayDatesActive.value) {
        data.stay_date_date_begin = sdDateBeginYYYYMMDD.value;
        data.stay_date_date_end = sdDateEndYYYYMMDD.value;
      }

      // Filters
      const filters = [
        {
          type: 'geolocations',
          value: geolocations.value,
        },
        {
          type: 'devices',
          value: devices.value,
        },
        {
          type: 'travel_party',
          value: travelParty.value,
        },
        {
          type: 'otas',
          value: filteredOtas.value,
        },
        {
          type: 'hotel_type',
          value: hotelType.value,
        },
        {
          type: 'hotel_category',
          value: hotelCategory.value,
        },
      ];

      filters.forEach((filter) => {
        if (filter.value) {
          data[`filter_${filter.type}`] = filter.value.join();
        }
      });
    }

    userTracking.addAction(event, data);

    if (window.Appcues) {
      window.Appcues.track(event, data);
    }
  }, { allow: false });
};

export const initAnalytics = () => {
  const {
    userData,
    isStrictlyDMOUser,
  } = useUserState();

  let userType;

  if (isStrictlyDMOUser.value) {
    userType = 'dmo-only';
  } else if (!userData.personalizationAccess) {
    userType = 'bd-only';
  } else {
    userType = 'cp-and-bd';
  }

  bdLogger.log('Init analytics');
  bdLogger.log(`User type: ${userType}`);

  checkInternalUser(() => {
    userTracking.init();
    userTracking.identify({
      id: userData.id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      impersonated: auth.isImpersonatedUser(),
      bdAccess: userType,
      currency: userData.currency,
      language: userData.language,
    });

    const appcuesScript = document.createElement('script');
    appcuesScript.id = 'thn-benchdirect-appcues-script';
    appcuesScript.setAttribute('src', '//fast.appcues.com/86927.js');
    appcuesScript.onload = () => {
      window.Appcues.identify(userData.id, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        role: userData.access,
        language: userData.language,
        type: userType,
        flow_sequence: Number(browserStorage.getItem('flow_sequence')) || 1,
        demand: userData.demand,
        safe_direct: userData.safe_direct,
        predictive_personalization: userData.predictive_personalization,
        predictive_audience: userData.predictive_audience,
      });
    };
    document.head.appendChild(appcuesScript);
  }, { allow: false });
};
