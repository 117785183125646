import useUserState from '@/state/useUserState';
import useMainState from '@/state/useMainState';

const {
  isStrictlyDMOUser,
} = useUserState();
const {
  propertyId,
} = useMainState();

export const getPropertyIdRouteParam = (): string | number => (propertyId.value === 0 ? '' : propertyId.value);

export const getSummaryRouteType = (): string => (propertyId.value === 0 ? 'overview' : 'property');

export const getUserTypeDefaultRedirect = (): string => {
  if (isStrictlyDMOUser.value) {
    return '/conversion';
  }

  let url = `/summary/${getSummaryRouteType()}`;

  const propertyParam = getPropertyIdRouteParam();

  if (propertyParam) {
    url += `/${getPropertyIdRouteParam()}`;
  }

  return url;
};
