import {
  computed,
  ref,
  isRef,
  unref,
  watch,
} from 'vue';

import getBarChartSpec from '@/utils/getBarChartSpec';
import getLineChartSpec from '@/utils/getLineChartSpec';
import getSingleDivergingBarChartSpec from '@/utils/getSingleDivergingBarChartSpec';
import getMultiDivergingBarChartSpec from '@/utils/getMultiDivergingBarChartSpec';
import getStackedBarChartSpec from '@/utils/getStackedBarChartSpec';
import getBoxplotChartSpec from '@/utils/getBoxplotChartSpec';
import constructTable from '@/utils/constructTable';
import getStats from '@/utils/getStats';
import getKeyFromObject from '@/utils/getKeyFromObject';
import getLocaleConfig from '@/utils/getLocaleConfig';
import getFunnelChartSpec from '@/utils/getFunnelChartSpec';
import getCorrelatedLineChartSpec from '@/utils/getCorrelatedLineChartSpec';
import handleMultiDivergingBarChartData from '@/utils/handleMultiDivergingBarChartData';

import useUserState from '@/state/useUserState';
import useMainState from '@/state/useMainState';
import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';

import scoreMetricsConfig from '@/config/scoreMetricsConfig';
import i18n from '@/config/i18n';

const { t } = i18n.global;

const getDefaultConfig = (items, target) => {
  const {
    compsetsFilter,
    compsetNames,
    getSectionData,
    sectionData,
    sectionName,
    dataId,
    sectionMetrics,
    isBreakdownOverviewSection,
  } = useSectionState();

  const { propertyId, isOverview } = useMainState();
  const { selectedMetric, filteredOtas, ctpTag } = useFilterState();
  const { isStrictlyDMOUser } = useUserState();

  const getFirstCompset = () => (isStrictlyDMOUser.value ? compsetsFilter.value[0] : 'own');
  const getSecondCompset = () => {
    const proximityIndex = compsetsFilter.value.indexOf('proximity');

    return proximityIndex > -1
      ? compsetsFilter.value[proximityIndex] // Get the Proximity one
      : [...compsetsFilter.value].reverse()[0]; // Get the last of the list
  };

  // spec getters
  const getSpec = {
    score: getLineChartSpec,
    bar: getBarChartSpec,
    funnel_bar: getFunnelChartSpec,
    kpi_bar: getBarChartSpec,
    single_diverging_bar: getSingleDivergingBarChartSpec,
    multi_diverging_bar: getMultiDivergingBarChartSpec,
    stacked_bar: getStackedBarChartSpec,
    boxplot: getBoxplotChartSpec,
    line: getLineChartSpec,
    correlated: getCorrelatedLineChartSpec,
  };

  // setup
  const configuredItems = items.map((item) => {
    const result = {
      ...item,
    };

    result.id = `${sectionName.value}_`;

    result.defaultTitle = result.title;

    result.title = computed(
      () => t((isRef(item.title) ? item.title.value : item.title) || ''),
    );

    if (item.columnDescription) {
      result.defaultColumnDescription = result.columnDescription;

      result.columnDescription = computed(
        () => t(item.columnDescription),
      );
    }

    const isScoreType = !!result.type.match(/score/i);
    const isBoxplotType = !!result.type.match(/boxplot/i);
    const isLineChart = !!result.type.match(/line/i);
    const isRankChart = !!result.type.match(/multi_diverging/i);
    const isDivergingChart = !!result.type.match(/single_diverging/i);

    let isStayDatesType = false;
    let isOtaType = false;

    if (result.uniqueDataKey) result.id += `${result.uniqueDataKey.toLowerCase()}_`;

    // default data suffix for boxplot, only if no specific value set
    if (isBoxplotType && !result.dataSuffix) {
      result.dataSuffix = 'Range';
    }

    if (result.dataSuffix) {
      if (result.dataSuffix.match(/sd/i)) {
        isStayDatesType = true;
        result.id += 'stay_dates_';
      } else if (result.dataSuffix.match(/ota/i)) {
        isOtaType = true;
        result.id += 'otas_';
      } else {
        result.id += `${result.dataSuffix.toLowerCase()}_`;
      }
    }

    if (result.selectedInterval) {
      // set default
      result.selectedInterval.value = isStayDatesType
        ? 'month'
        : 'week';

      result.id += 'over_time_';

      // Watch interval changes and fetch data according to the selection
      watch([
        result.selectedInterval,
      ], async () => {
        // Update data pipe
        result.data.value.pipe.name = result.data.value.pipe.name
          .replace(/(date|week|month)$/, result.selectedInterval.value.toLowerCase());

        // Fetch data ONLY if dataId is different and it hasn't been initialized yet
        if (!result.data.value.pipe.initialized
          && result.data.value.pipe.dataId !== dataId.value) {
          await getStats({
            pipe: result.data.value.pipe.name,
          });
        }
      });
    }

    if (isRankChart) {
      result.showAll = ref();

      watch(dataId, () => {
        result.showAll.value = undefined;
      });

      result.compareFirstCompset = ref(getFirstCompset());

      watch(isOverview, () => {
        result.compareSecondCompset = ref(getSecondCompset());
      }, { immediate: true });
    }

    if (result.subType) {
      result.id += `${result.subType}_`;
    }

    result.id += `${result.type}_${target.replace(/.$/, '')}`;

    result.elemRef = ref(null);

    result.valid = computed(() => {
      if (item.valid === undefined) {
        return !result.dataKeys
        || result.dataKeys.indexOf(selectedMetric.value) !== -1;
      }

      if (isRef(item.valid)) {
        return item.valid.value;
      }

      return item.valid;
    });

    // computed data
    result.data = target === 'tables' && result.type.match(/summary|breakdown/)
      ? computed(() => {
        const data = getSectionData(propertyId.value);

        let matchData;

        if (result.type === 'summary') {
          // append "metricName" key for column organisation
          matchData = result.dataKeys
            .map((key) => {
              const metricName = sectionMetrics.value.filter((i) => i.name === key)[0].placeholder;

              return {
                ...data[key],
                values: data[key].values.map((v) => ({
                  ...v,
                  metricName,
                })),
              };
            });
        } else if (result.type === 'breakdown') {
          // append "propertyId" key for column organisation
          matchData = Object.keys(sectionData)
            .filter((pid) => pid > 0) // filter out overview data
            .map((pid) => {
              const currData = getSectionData(pid)[selectedMetric.value];
              return {
                ...currData,
                values: currData.values.map((v) => ({
                  ...v,
                  propertyId: Number(pid),
                })),
              };
            });
        }

        return {
          values: matchData,
          pipe: {
            loading: matchData.some((i) => i.pipe.loading),
            error: matchData.some((i) => i.pipe.error),
            isCtp: matchData.some((i) => i.pipe.isCtp),
          },
        };
      })
      : computed(() => {
        const data = getSectionData(propertyId.value);

        // format selected interval into snakeCase if existing.
        const intervalDataName = (
          (result.selectedInterval
            && result.selectedInterval.value
              .replace(/^./, result.selectedInterval.value[0].toUpperCase()))
            || ''
        );

        // generate data key mixing provided config with user input or defaults.
        const key = `
          ${result.uniqueDataKey || selectedMetric.value}
          ${result.dataSuffix || ''}
          ${intervalDataName}
        `.replace(/\s/g, '');

        const matchData = data[key] || {};

        if (Object.keys(matchData).length) {
          return matchData;
        }

        return null;
      });

    result.currency = computed(() => {
      const { meta = {} } = result.data.value;

      return meta.currency || getLocaleConfig().currency;
    });

    // computed loading status
    result.loading = computed(() => result.data.value.pipe.loading);

    // computed error status
    result.error = computed(() => {
      const config = {
        title: '',
        text: '',
      };

      if (!result.loading.value) {
        if (result.data.value.pipe.error) {
          config.title = t('There\'s been an error while loading the data');
          config.text = t('Try again later or contact us if errors persist');
        } else if (!result.data.value.values.length) {
          config.title = t('No available data on the selected query');
          config.text = t('Try broadening your date selection or clearing filters');
        }
      }

      config.show = !!(config.title && config.text);

      return config;
    });

    // computed data-ready status
    result.dataReady = computed(() => (
      !result.loading.value
      && !result.error.value.show
      && !!result.data.value
      && !!result.data.value.values.length
    ));

    // charts specific config
    if (target === 'charts') {
      // computed spec
      result.spec = computed(() => {
        if (!result.dataReady.value) {
          return null;
        }

        // filter items based on compsets filter dropdown selection.
        let filteredData = result.data.value.values;

        if (!isScoreType) {
          filteredData = filteredData
            .filter((i) => compsetsFilter.value.indexOf(i.compsetTag) !== -1);
        }

        if (isRankChart && compsetsFilter.value.length > 1) {
          if (compsetsFilter.value.indexOf(result.compareSecondCompset.value) === -1) {
            result.compareSecondCompset.value = getSecondCompset();
          }

          if (compsetsFilter.value.indexOf(result.compareFirstCompset.value) === -1) {
            result.compareFirstCompset.value = getFirstCompset();
          }

          filteredData = handleMultiDivergingBarChartData(
            filteredData,
            result.compareFirstCompset.value,
            result.compareSecondCompset.value,
          );

          const shouldEnableShowAll = filteredData
            .filter((i) => i.compsetTag === result.compareFirstCompset.value).length > 20;

          if (result.showAll.value === undefined && shouldEnableShowAll) {
            // set initial showall value
            result.showAll.value = false;
          }
        }

        if (isOtaType) { // is ota chart?
          // filter by OTA - only if the filter has length
          filteredData = filteredData
            .filter((i) => !filteredOtas.value.length
              || filteredOtas.value.indexOf(i.source) !== -1);
        }

        const config = {
          // Chart controls data
          compareFirstCompset: unref(result.compareFirstCompset),
          compareSecondCompset: unref(result.compareSecondCompset),
          defaultSort: unref(result.defaultSort),
          showAll: unref(result.showAll),
          // Stats data
          data: filteredData,
          dataFormat: unref(result.dataFormat),
          id: result.id,
          isCtp: result.data.value.pipe.isCtp || result.forceCtp,
          hideRule: result.forceCtp,
          legendValues: compsetNames.value,
        };

        if (isDivergingChart) {
          const [leftTitle, rightTitle] = sectionMetrics.value
            .map((m) => ({ metricTag: m.name, metricName: m.placeholder }));

          Object.assign(config, {
            leftTitle,
            rightTitle,
          });
        }

        if (result.specConfig) {
          Object.assign(config, result.specConfig);
        }

        if (isScoreType || isLineChart) {
          const interval = result.selectedInterval && result.selectedInterval.value;
          let xTitle;
          let legendValues;

          if (interval) {
            config.interval = interval;
            if (isStayDatesType
              || (result.subType || '').match(/revdirect|occupancyrate/i)) {
              xTitle = 'Stay Dates';
            } else {
              xTitle = 'Selected Dates';
            }
          } else if (result.subType === 'curves') {
            const curvesTitles = {
              'length-of-stay': 'Duration of Stay',
              'booking-window': 'Days until check in',
            };

            xTitle = getKeyFromObject(curvesTitles, sectionName.value);
          } else if (result.id.match(/score/)) {
            xTitle = 'Month';
          }

          if (isScoreType) {
            legendValues = Object.values(scoreMetricsConfig);
          } else {
            legendValues = compsetNames.value;
          }

          Object.assign(config, {
            xTitle: t(xTitle),
            legendValues,
          });

          if (result.subType === 'small') {
            config.small = true;
          }
        }

        return getSpec[result.type](config);
      });

      // computed ready status
      result.ready = computed(() => !!result.spec.value);

      result.getImg = ref(null);

      result.finished = computed(() => !!result.getImg.value);

    // tables specific config
    } else if (target === 'tables') {
      result.tableRows = computed(() => {
        if (result.type !== 'breakdown'
          && !result.dataReady.value) {
          return null;
        }

        const config = {
          type: result.type,
          interval: result.selectedInterval && result.selectedInterval.value,
          rowKey: result.rowKey,
          dataFormat: unref(result.dataFormat),
          currency: result.currency && result.currency.value,
          isCtp: ctpTag.value,
        };

        let constructedRows = [];

        // Summary tables are treated differerntly
        if (result.type.match(/summary|breakdown/)) {
          // Of all the metrics
          result.data.value.values.forEach((metric) => {
            const rows = constructTable(config, metric.values);

            if (!rows) {
              return;
            }

            // Merge all the resulting rows
            rows.forEach((row) => {
              constructedRows.push(row);
            });
          });
        } else {
          // Get formatted data to fit in the table
          constructedRows = constructTable(config, result.data.value.values);
        }

        if (!constructedRows) {
          return null;
        }

        if (result.type === 'breakdown') {
          return constructedRows;
        }

        return constructedRows.map((r) => ({
          ...r,
          values: r.values.filter((v) => compsetsFilter.value.indexOf(v.compsetTag) !== -1),
        }));
      });

      result.ready = computed(() => isBreakdownOverviewSection.value
        || (result.tableRows.value
            && !!result.tableRows.value.length));

      result.getExportData = ref(null);

      result.finished = computed(() => !!result.getExportData.value);
    }

    return result;
  });

  return configuredItems;
};

export default getDefaultConfig;
