import useSectionState from '@/state/useSectionState';
import {
  axis,
  text,
  view,
  bigSize,
  strokeDash,
  smallSize,
  tooltip,
  colorField,
  barChartYOffset,
  barChartComparedStep,
  barChartSingleStep,
  getValueDisplayLayer,
  strokeWidth,
} from '@/config/charts';

const getSingleDivergingBarChartSpec = ({
  dataFormat,
  data,
  isCtp,
  leftTitle,
  rightTitle,
}) => {
  const {
    compsets,
    compsetsFilter,
    compsetNames,
  } = useSectionState();

  const width = 'container';

  const step = isCtp ? barChartComparedStep : barChartSingleStep;

  // catch the highest value only from valid entries
  let highest = Math.max(...data.map((i) => {
    let { value } = i;

    if (isCtp && i.pastValue > value) {
      value = i.pastValue;
    }

    return value || 0;
  }));

  // reduce the bar size making the axis domain higher to let the "diff" text have space
  highest = isCtp ? highest + ((highest * 5) / 100) : highest;

  const leftValuesLayer = getValueDisplayLayer('right', dataFormat, highest);
  const rightValuesLayer = getValueDisplayLayer('left', dataFormat, highest);

  const rightBarLayerConfig = [{
    mark: {
      type: 'bar',
      size: bigSize,
      tooltip,
      cornerRadiusTopRight: 2,
      cornerRadiusBottomRight: 2,
    },
    encoding: {
      color: colorField,
    },
  }];

  const leftBarLayerConfig = [{
    mark: {
      type: 'bar',
      size: bigSize,
      tooltip,
      cornerRadiusTopLeft: 2,
      cornerRadiusBottomLeft: 2,
    },
    encoding: {
      color: colorField,
    },
  }];

  const fieldsConfig = [
    {
      encoding: {
        x: {
          field: 'pastValue',
          type: 'quantitative',
          sort: 'descending',
        },
      },
      layer: [
        {
          encoding: { color: colorField },
          mark: {
            type: 'bar',
            size: smallSize,
            tooltip,
            yOffset: barChartYOffset,
            opacity: 0.5,
          },
        },
        ...compsets.value.map((c) => ({
          transform: [{ filter: `datum.patternId == ${c.patternId}` }],
          mark: {
            type: 'bar',
            size: smallSize,
            tooltip,
            yOffset: barChartYOffset,
          },
          encoding: {
            fill: { value: `url(#pattern_${c.patternId})` },
          },
        })),
      ],
    },
  ];

  const rightLayerConfig = [
    ...rightBarLayerConfig,
    ...fieldsConfig,
  ];

  const leftLayerConfig = [
    ...leftBarLayerConfig,
    ...fieldsConfig,
  ];

  return {
    data: {
      values: data,
    },

    height: { step },
    width,
    autosize: { type: 'fit', contains: 'padding' },
    padding: 5,
    config: {
      view,
      axis: {
        ...axis,
        domain: false,
        labelAlign: 'top',
        labelPadding: 180,
        labelLimit: 150,
      },
      text,
    },

    transform: [
      { calculate: '0', as: 'axis' },
    ],

    encoding: {
      x: {
        field: 'value',
        type: 'quantitative',
        sort: 'descending',
        axis: {
          labels: false,
          gridDash: strokeDash,
          gridColor: 'white',
          gridWidth: strokeWidth,
          grid: true,
          zindex: 1,
          domain: false,
        },
        scale: {
          domain: [highest * -1, highest],
          nice: false,
          padding: 50,
        },
      },
      y: {
        field: 'compset',
        type: 'nominal',
        sort: compsetNames.value,
      },
    },

    layer: [
      {
        transform: [
          { filter: `datum.metric === "${leftTitle.metricTag}"` },
        ],
        layer: [
          ...leftLayerConfig,
          ...leftValuesLayer,
          {
            transform: [{ filter: `datum.compsetTag === "${compsetsFilter.value[0]}"` }],
            mark: {
              type: 'text',
              color: 'black',
              align: 'right',
              fontWeight: 500,
              dx: -20,
            },
            encoding: {
              text: {
                value: leftTitle.metricName,
              },
              x: {
                field: 'axis',
                type: 'quantitative',
              },
              y: {
                value: -10,
              },
            },
          },
        ],
      },

      {
        transform: [
          { filter: `datum.metric === "${rightTitle.metricTag}"` },
          { calculate: 'datum.value * -1', as: 'value' },
          { calculate: 'datum.pastValue * -1', as: 'pastValue' },
        ],
        layer: [
          ...rightLayerConfig,
          ...rightValuesLayer,
          {
            transform: [{ filter: `datum.compsetTag === "${compsetsFilter.value[0]}"` }],
            mark: {
              type: 'text',
              color: 'black',
              align: 'left',
              fontWeight: 500,
              dx: 20,
            },
            encoding: {
              text: {
                value: rightTitle.metricName,
              },
              x: {
                field: 'axis',
                type: 'quantitative',
              },
              y: {
                value: -10,
              },
            },
          },
        ],
      },
    ],
  };
};

export default getSingleDivergingBarChartSpec;
