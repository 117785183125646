import calculateDiff from '@/utils/calculateDiff';

const mergeDataValues = (baseData, newData) => {
  const baseDataCopy = [...baseData];

  newData.forEach((curr) => {
    // type flags
    const isSourceType = curr.source !== undefined;
    const isCurvesType = curr.step !== undefined;
    const isMixedType = curr.metric !== undefined;
    // find existing matching data for merging
    const matchDataIdx = baseDataCopy
      .map((item) => curr.compsetTag === item.compsetTag
        && (!isSourceType || curr.source === item.source)
        && (!isCurvesType || curr.step === item.step)
        && (!isMixedType || curr.metric === item.metric))
      .indexOf(true);

    const matchData = baseDataCopy[matchDataIdx];

    let baseItem;

    // not found? create a new one with empty values
    if (!matchData) {
      // fill a new baseItem (BASE) with the new config
      baseItem = { ...curr };
      // clean new object (keep compset, source, step)
      delete baseItem.value;
      delete baseItem.min;
      delete baseItem.q1;
      delete baseItem.median;
      delete baseItem.q3;
      delete baseItem.max;
    } else {
      // use found data as base
      baseItem = { ...matchData };
    }

    // merge new values with "past" prefix into base data
    if (curr.median !== undefined) {
      // boxplot range keys
      Object.assign(baseItem, {
        pastMin: curr.min,
        pastQ1: curr.q1,
        pastMedian: curr.median,
        pastQ3: curr.q3,
        pastMax: curr.max,
        diff: calculateDiff(baseItem.median, curr.median),
      });
    } else {
      Object.assign(baseItem, {
        pastValue: curr.value,
        diff: calculateDiff(baseItem.value, curr.value),
      });
    }

    if (matchDataIdx >= 0) {
      baseDataCopy.splice(matchDataIdx, 1, baseItem);
    } else {
      baseDataCopy.push(baseItem);
    }
  }, []);

  return baseDataCopy;
};

export default mergeDataValues;
