import moment from 'moment';

import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';

import { sectionFiltersConfig } from '@/config/sectionFiltersConfig';

import getLocaleConfig from '@/utils/getLocaleConfig';
import bdLogger from './bdLogger';
import env from '@/config/env';

const generateFetchUrl = (pipe, requestConfig = {}) => {
  const {
    isCtpCall,
    isScoreCall,
    isKpi,
    overrides = {},
    excludeHost,
    endpointVersion = 'v2',
  } = requestConfig;

  const {
    isSummarySection,
  } = useSectionState();

  const {
    geolocations,
    devices,
    travelParty,
    filteredOtas,
    hotelType,
    hotelCategory,
    source,
    isStayDatesActive,
    sdDateBeginYYYYMMDD,
    sdDateEndYYYYMMDD,
    ctpSdDateBeginYYYYMMDD,
    ctpSdDateEndYYYYMMDD,
    ctpDateBeginYYYYMMDD,
    ctpDateEndYYYYMMDD,
    dateBeginYYYYMMDD,
    dateEndYYYYMMDD,
    applyCtpStayDates,
  } = useFilterState();

  const {
    token,
    isOverview,
    propertyId,
  } = useMainState();

  // Move interval from the pipe name into url parameter
  let interval;

  let tmpPipe = pipe;

  const overTimeType = pipe.match(/over-time|stay-dates/);

  if (overTimeType) {
    // eslint-disable-next-line prefer-destructuring
    interval = (pipe.match(/(date|week|month)$/) || [])[0];
    tmpPipe = tmpPipe.replace(/\/(date|week|month)$/, '');
  }

  let url = '';

  if (!excludeHost) {
    url += `${env.VUE_APP_BENCHDIRECT_API_URL}`;
  }

  url += `/api/${endpointVersion}/`;

  url += tmpPipe;

  const config = {
    propertyId: overrides.propertyId || propertyId.value,
    token: token.value,
  };

  // Query part
  if (isOverview.value && config.propertyId === 0) {
    // don't send properties param, backend will take care of that
    url += '?overview=1';
  } else {
    url += `?property_id=${config.propertyId}`;
  }

  if (overrides.params) {
    overrides.params.forEach((param) => {
      url += `&${param.name}=${param.value}`;
    });
  }

  if (interval) {
    url += `&period=${interval}`;
  }

  // currency
  const { currency } = getLocaleConfig();

  if (currency) {
    url += `&currency=${currency}`;
  }

  // Scores request config
  if (isScoreCall) {
    const scoreEnd = moment();

    let scoreStart;

    if (isOverview.value) {
      // current month progress for the rest of the sections
      scoreStart = moment().startOf('month');
    } else if (isSummarySection.value) {
      // last 6 months for summary section (scores chart)
      scoreStart = moment().subtract(6, 'month').startOf('month');
    } else {
      scoreStart = moment().subtract(30, 'days').startOf('day');
    }

    config.dateBegin = scoreStart.format('YYYY-MM-DD');
    config.dateEnd = scoreEnd.format('YYYY-MM-DD');

    // Section Data request config
  } else {
    config.dateBegin = isCtpCall ? ctpDateBeginYYYYMMDD.value : dateBeginYYYYMMDD.value;
    config.dateEnd = isCtpCall ? ctpDateEndYYYYMMDD.value : dateEndYYYYMMDD.value;

    if (sectionFiltersConfig.value.active && !overrides.noFilters) {
      // Apply active filters
      [
        {
          param: 'device',
          filters: devices.value,
          active: sectionFiltersConfig.value.devices,
        },
        {
          param: 'geoname_id',
          filters: geolocations.value,
          active: sectionFiltersConfig.value.geolocations,
        },
        {
          param: 'travel_party',
          filters: travelParty.value,
          active: sectionFiltersConfig.value.travelParty,
        },
        {
          param: 'ota',
          filters: filteredOtas.value,
          active: sectionFiltersConfig.value.otas,
        },
        {
          param: 'type',
          filters: hotelType.value,
          active: sectionFiltersConfig.value.hotelType,
        },
        {
          param: 'rating',
          filters: hotelCategory.value,
          active: sectionFiltersConfig.value.hotelCategory,
        },
        {
          param: 'origin',
          filters: source.value,
          active: sectionFiltersConfig.value.sources,
        },
      // For each filter config
      ].forEach(({ param, filters, active }) => {
        // Make sure there are active filters
        if (filters.length && active) {
          // Set param name
          url += `&${param}=`;
          // Append all the filters
          filters.forEach((filter) => {
            url += `${filter.toLowerCase().replace(/\s/, '-')},`;
          });

          // Remove last filter's comma
          url = url.replace(/,$/, '');
        }
      });
    }
  }

  // User input
  if (isStayDatesActive.value && sectionFiltersConfig.value.stayDates) {
    let sdParam = '';
    if (isCtpCall && applyCtpStayDates.value) {
      sdParam += `&in_date_begin=${ctpSdDateBeginYYYYMMDD.value}`;
      sdParam += `&in_date_end=${ctpSdDateEndYYYYMMDD.value}`;
    } else {
      sdParam += `&in_date_begin=${sdDateBeginYYYYMMDD.value}`;
      sdParam += `&in_date_end=${sdDateEndYYYYMMDD.value}`;
    }
    url += sdParam;
    bdLogger.log(`
    Staydates filter debug:
      Endpoint: ${pipe}
      Is CTP request?: ${!!isCtpCall}
      Apply CTP staydates?: ${applyCtpStayDates.value}
      Staydates parameter: ${sdParam}
    `);
  } else if (pipe.match(/stay-dates/)) {
    /*
      Stay dates filter:
      The default filter is "twelve months from today"
    */
    const twelveMonthsFromNow = moment(config.dateBegin).add(12, 'months').format('YYYY-MM-DD');

    url += `&in_date_begin=${config.dateBegin}`;
    url += `&in_date_end=${twelveMonthsFromNow}`;
  }

  // Fixed params (needed in every call)
  if (isKpi) {
    const daysBetween = Math.abs(moment(config.dateBegin).diff(moment(config.dateEnd), 'days')) || 1;
    url += `&days=${daysBetween}`;
  } else {
    url += `
      &start_date=${config.dateBegin}
      &end_date=${config.dateEnd}
    `;
  }

  url = url.replace(/\s/g, '');

  return url;
};

export default generateFetchUrl;
