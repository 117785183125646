import { metricConfig, overConfig } from '@/config/charts/correlatedMetricsConfig';
import {
  commonPipeConfig,
  createDynamicPipeConfig,
} from '@/state/sectionStateConfig';
import getDefaultConfig from './getDefaultConfig';
import getDefaultSectionDataConfig from './getDefaultSectionDataConfig';

import { CorrelatedChartConfigRaw, UserRawChartData } from '@/declarations/chartBuilder';

const getUserChartsConfig = (charts: UserRawChartData[]) => {
  const config = {} as { data: [], charts: [] };

  const sectionData: any = [
    ...commonPipeConfig,
  ];

  const chartsConfig: CorrelatedChartConfigRaw[] = charts.map((chart) => {
    const metric = metricConfig[chart.metric];
    const over = overConfig[chart.over];

    sectionData.push({
      key: `default_${chart.id}`,
      pipe: createDynamicPipeConfig(`correlated/${chart.id}`),
    });

    return {
      chartId: chart.id,
      type: 'correlated',
      title: `${metric.title} to ${over.title}`,
      specConfig: {
        yConfig: {
          title: metric.title,
          dataFormat: metric.dataFormat,
        },
        xConfig: {
          title: over.title,
          dataFormat: over.dataFormat,
        },
      },
      dataKeys: ['default'],
      dataSuffix: `_${chart.id}`,
    };
  });

  config.data = getDefaultSectionDataConfig(sectionData);
  config.charts = getDefaultConfig(chartsConfig, 'charts');

  return config;
};

export default getUserChartsConfig;
