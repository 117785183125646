/* eslint-disable no-param-reassign */
import isEqual from 'lodash/isEqual';

import auth from '@/modules/Auth';
import env from '@/config/env';
import useUserState from '@/state/useUserState';
import useMainState from '@/state/useMainState';
import useSectionState from '@/state/useSectionState';
import useAppState from '@/state/useAppState';
import {
  setupInitialAppConfig,
  updateAppConfig,
} from '@/utils/setupInitialAppConfig';
import getDestinations from '@/utils/getDestinations';
import getUserInfo from '@/utils/getUserInfo';
import getHistoryUser from '@/utils/getHistoryUser';
import { getUserTypeDefaultRedirect } from '@/utils/getUserTypeDefaultRedirect';
import { track } from '@/utils/analytics';
import useRequestCache from '@/utils/useRequestCache';
import { initDataDog } from '@/utils/dataDog';
import loadFeatureFlags from '@/utils/featureFlags';
import navigate from '@/utils/navigate';
import getUserCompsets from '@/utils/getUserCompsets';
import getKeyFromObject from '@/utils/getKeyFromObject';
import sectionExceptions from '@/utils/sectionExceptions';
import handleOnPropertyChange from '@/utils/handleOnPropertyChange';
import isAbortError from '@/utils/isAbortError';
import clearLocalStorage from '@/utils/clearLocalStorage';

const {
  isStrictlyDMOUser,
  isKeyUser,
  userData,
} = useUserState();

const {
  minPropertiesToShowCountdown,
} = useMainState();

const {
  filtersQuery,
  setSectionName,
  setIsBenchDirectLoading,
  sectionName,
  setRunningCalls,
} = useSectionState();

const { setAppData } = useAppState();

const { initRequestCache, clearCacheStorage } = useRequestCache();

export const beforeGuard = async (to, from, next) => {
  if (to.meta.noUser) {
    next();
    return;
  }

  let trackLogin = false;
  let shouldClearCache = false;

  // first load
  if (!auth.state.initialized) {
    await auth.init();
  }

  if (to.query.loginAs) {
    auth.loginAs(to.query.loginAs);
    clearLocalStorage();
    shouldClearCache = true;
  }

  const authUser = await auth.getUser();
  if (!await auth.isAuthenticated()) {
    if (to.query.code && to.query.state) {
      await auth.handleAuthRedirect();
      // track login after user info request
      trackLogin = true;
    } else {
      await auth.login();
      return;
    }
  } else if (auth.isImpersonatedUser()
      && !authUser.email?.match(/thehotelsnetwork.com/)) {
    // force remove impersonation when detected under an external user
    auth.loginAs('none');
  }

  if (!userData.email) {
    let userInfo;
    let userHistory;

    try {
      userInfo = await getUserInfo();
      if (userInfo.user.type === 'internal') {
        navigate(`${env.VUE_APP_NAKAMA_URL}/redirect/latte`);
      }
    } catch (err) {
      if (err?.response.status === 401) {
        navigate(`${env.VUE_APP_NAKAMA_URL}?no-access=benchdirect`, { external: true });
      }

      next({ name: 'error', params: { err } });
      return;
    }

    try {
      userHistory = await getHistoryUser(userInfo.user.id);
    } catch (err) {
      throw new Error('User history not available');
    }

    initDataDog();
    const { setFlags } = useMainState();
    await loadFeatureFlags(userInfo, setFlags);
    setAppData(userInfo);
    setAppData({
      user: {
        demand: !!userHistory.active_products.demand,
        safe_direct: !!userHistory.active_products.safe_direct,
        predictive_personalization: !!userHistory.active_products.predictive_personalization,
        predictive_audience: !!userHistory.active_products.predictive_audience,
      },
    });
    await setupInitialAppConfig();
    await initRequestCache();

    if (shouldClearCache) {
      await clearCacheStorage();
      shouldClearCache = false;
    }

    if (trackLogin) {
      track('login');
    }
  }

  if (to.path === '/') {
    next(getUserTypeDefaultRedirect());
    return;
  }

  setSectionName(to.name);

  const {
    requiresStrictDMOUser,
    requiresPropertyUser,
    requiresDataAccess,
    requiresKeyUser,
    isDataRoute,
  } = to.matched[0].meta;

  if (requiresDataAccess) {
    if (isStrictlyDMOUser.value) {
      const destinations = await getDestinations();

      if (!destinations.length) {
        next('/destinations');
        return;
      }
    } else if (!minPropertiesToShowCountdown.value) {
      if (!isKeyUser()) {
        next('/waiting-room');
        return;
      }

      next('/onboarding');
      return;
    }
  }

  if ((requiresKeyUser && !isKeyUser())
  || (requiresStrictDMOUser && !isStrictlyDMOUser.value)
  || (requiresPropertyUser && isStrictlyDMOUser.value)) {
    next(getUserTypeDefaultRedirect());
    return;
  }

  const hasPropertyParam = to.matched
    .some((matchedRoute) => matchedRoute.path?.match(/:propertyId\?$/i));

  let updateRouteData;

  if (!hasPropertyParam) {
    delete to.params.propertyId;
    to.query = {};
  } else {
    if (!to.params.type && from.params.type) {
      updateRouteData = true;
      to.params.type = from.params.type;
    }

    if (to.params.type !== 'overview' && !to.params.propertyId && from.params.propertyId) {
      updateRouteData = true;
      to.params.propertyId = from.params.propertyId;
    }

    if (isDataRoute
      && Object.keys(filtersQuery.value).length
      && (!isEqual(filtersQuery.value, to.query))) {
      updateRouteData = true;
    }
  }

  // if route has to keep query && stored query && to query is not that
  if (updateRouteData) {
    next({ name: to.name, query: filtersQuery.value, params: to.params });
  } else {
    next();
  }
};

export const afterGuard = async (to, from) => {
  document.getElementById('mainSectionElementRef')?.scrollTo({ top: 0, behavior: 'smooth' });
  window.Appcues?.page();

  if (from.path !== to.path) {
    setIsBenchDirectLoading(true);
    await updateAppConfig();

    if (to.meta.isDataRoute) {
      setRunningCalls();

      if (from.params.propertyId !== to.params.propertyId) {
        try {
          await getUserCompsets();
        } catch (err) {
          isAbortError(err);
          return;
        }
      }

      setIsBenchDirectLoading(false);

      handleOnPropertyChange({
        userInput: true,
        disableStats: !!getKeyFromObject(sectionExceptions, sectionName.value).disableStats,
      });
    }
    setIsBenchDirectLoading(false);
  }
};
