import bdLogger from '@/utils/bdLogger';
import fetchRequest from '@/utils/fetchRequest';
import { defaultDemoProperty } from '@/utils/demoPropertiesUtils';

import {
  scoreMetricsTotalPipe,
} from '@/state';

import useSectionState from '@/state/useSectionState';

import useMainState from '@/state/useMainState';
import useUserState from '@/state/useUserState';

const {
  userProperties,
  setUserProperties,
} = useMainState();

const {
  setPipeStatus,
} = useSectionState();

const { isDemoUser } = useUserState();

const getTotalScores = async () => {
  const { name: pipeName } = scoreMetricsTotalPipe;

  setPipeStatus(pipeName, { loading: true });

  try {
    const { data } = await fetchRequest(pipeName, { isScoreCall: true });

    const updatedProperties = userProperties.value
      .reduce((acc, curr) => {
        let existingData = data.filter((i) => i.compset_id === curr.propertyId)[0];

        if (isDemoUser.value) {
          existingData = existingData
            || data.filter((i) => i.compset_id === defaultDemoProperty)[0];
        }
        // default values
        const scores = {
          dbi: 0,
          bookings: 0,
          conversion: 0,
          demand: 0,
          disparities: 0,
          revenue: 0,
        };

        if (existingData) {
          Object.assign(scores, {
            dbi: Math.round(existingData.score),
            bookings: Math.round(existingData.score_bookings),
            conversion: Math.round(existingData.score_conversion),
            demand: Math.round(existingData.score_direct_occupancy_rate),
            disparities: Math.round(existingData.score_disparity_frequency),
            revenue: Math.round(existingData.score_user_revenue),
          });
        }
        // update property data
        acc.push({
          ...curr,
          scores,
        });

        return acc;
      }, []);

    // update property list
    setUserProperties(updatedProperties);

    setPipeStatus(pipeName, { loading: false });
  } catch (err) {
    bdLogger.warn(err);
    setPipeStatus(pipeName, { hasError: true });
  }
};

export default getTotalScores;
