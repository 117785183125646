import moment from 'moment';

const getDateRange = (start, end, format) => {
  const startDate = moment.isMoment(start) ? start : moment.unix(start);
  const endDate = moment.isMoment(end) ? end : moment.unix(end);

  let tmpDateBeginFormat = format;
  // default date end format value
  const dateEndFormat = format || 'D MMM YYYY';
  // format not provided? use default system (try to get the shortest value)
  if (!tmpDateBeginFormat) {
    // for every period
    tmpDateBeginFormat = [
      {
        period: 'day',
        format: 'D',
      },
      {
        period: 'months',
        format: 'MMM',
      },
      {
        period: 'years',
        format: 'YYYY',
      },
    ].reduce((acc, curr) => {
      // accumulate string
      let str = acc;
      // does date begin share the same month/year as date end?
      if (!startDate.isSame(endDate, curr.period)) {
        // add format for this period
        str += `${acc ? ' ' : ''}${curr.format}`;
      }

      return str;
    }, '');
  }

  let result = '';

  if (tmpDateBeginFormat) {
    result += `${startDate.format(tmpDateBeginFormat)} - `;
  }

  result += endDate.format(dateEndFormat);

  return result;
};

export default getDateRange;
