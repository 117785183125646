import { ref, computed, watch } from 'vue';

import moment from 'moment';

import router from '@/router';

import {
  capitalize,
} from '@/utils/stringHelpers';

import { track } from '@/utils/analytics';

import updateLocalStorage from '@/utils/updateLocalStorage';
import cleanFilterValues from '@/utils/cleanFilterValues';

import useSectionState from '@/state/useSectionState';

const {
  sectionView,
  filtersQuery,
  setFiltersQuery,
} = useSectionState();

/**
 * Helpers
 */

/**
 * Given a object containing date values, return a moment object
 * Expected input: {y: 2020, M: 5, d: 26, h: 13, m: 30, s: 24}
 * If the given object is not valid, it returns the current moment date object.
 *
 * @param {object} dateObj
 */
const toMoment = (dateObj) => {
  const date = moment([dateObj.y, dateObj.M, dateObj.d, dateObj.h, dateObj.m, dateObj.s]);
  return date.isValid() ? date : moment();
};

/**
 * Visit Dates
 */

const dateBegin = ref(0);
const dateBeginYYYYMMDD = computed(() => moment.unix(dateBegin.value).format('YYYY-MM-DD'));
const dateBeginObj = computed(() => {
  const db = moment.unix(dateBegin.value);
  return {
    s: db.seconds(),
    m: db.minutes(),
    h: db.hour(),
    d: db.date(),
    M: db.month(),
    y: db.year(),
  };
});

const dateEnd = ref(0);
const dateEndYYYYMMDD = computed(() => moment.unix(dateEnd.value).format('YYYY-MM-DD'));
const dateEndObj = computed(() => {
  const de = moment.unix(dateEnd.value);
  return {
    s: de.seconds(),
    m: de.minutes(),
    h: de.hour(),
    d: de.date(),
    M: de.month(),
    y: de.year(),
  };
});

const vdTag = ref('');
const summVdTag = ref('');
const propertySummaryDaysSelected = computed(
  () => Number(summVdTag.value.match(/\d+/)),
);

/**
 * Device Filter
 */

const devices = ref([]);

/**
 * geolocations Filter
 */
const geolocations = ref([]);

/**
 * Otas Filter
 */
const otas = ref([]);
const filteredOtas = ref([]);

/**
 * Hotel Type
 */
const hotelType = ref([]);

/**
 * Hotel Rating
 */
const hotelCategory = ref([]);

/**
 * Source
 */
const source = ref([]);

/**
 * Travel Party
 */
const travelParty = ref([]);

/**
 * CTP (Compare To Past dates)
 */
const ctpTag = ref('');
const applyCtpStayDates = ref(false);

const updateSystemFilters = async (key, newValue) => {
  const currentQuery = filtersQuery.value;
  const newQuery = {
    ...currentQuery,
  };

  if (newValue.length === 0) {
    delete newQuery[key];
  } else if (Array.isArray(newValue)) {
    newQuery[key] = newValue.join(',');
  } else {
    newQuery[key] = newValue;
  }

  const sortedQuery = Object.keys(newQuery).sort().reduce(
    (obj, objKey) => {
      // eslint-disable-next-line no-param-reassign
      obj[objKey] = newQuery[objKey];
      return obj;
    },
    {},
  );

  setFiltersQuery(sortedQuery);

  updateLocalStorage('filtersQuery', sortedQuery);

  await router.push({
    query: filtersQuery.value,
  });
};

const getCtpDateBegin = (mode) => {
  const dateDiff = moment.unix(dateBegin.value).diff(moment.unix(dateEnd.value), 'days');

  switch (mode) {
    case 'pp':
      return moment.unix(dateBegin.value).subtract(Math.abs(dateDiff) + 1, 'days');

    case 'py':
      return moment.unix(dateBegin.value).subtract(1, 'years');

    default:
      return moment(0);
  }
};

const getCtpDateEnd = (mode) => {
  switch (mode) {
    case 'pp':
      return moment.unix(dateBegin.value).subtract(1, 'days');

    case 'py':
      return moment.unix(dateEnd.value).subtract(1, 'years');

    default:
      return moment(0);
  }
};

const ctpDateBegin = ref(0);
const ctpDateEnd = ref(0);
const ctpSdDateBegin = ref(0);
const ctpSdDateEnd = ref(0);

const ctpDateBeginYYYYMMDD = computed(() => moment(ctpDateBegin.value * 1000).format('YYYY-MM-DD'));
const ctpDateEndYYYYMMDD = computed(() => moment(ctpDateEnd.value * 1000).format('YYYY-MM-DD'));

const ctpSdDateBeginYYYYMMDD = computed(() => moment(ctpSdDateBegin.value * 1000).format('YYYY-MM-DD'));
const ctpSdDateEndYYYYMMDD = computed(() => moment(ctpSdDateEnd.value * 1000).format('YYYY-MM-DD'));

/**
 * Stay Dates
 */

const sdDateBegin = ref(0);
const sdDateBeginYYYYMMDD = computed(() => moment(sdDateBegin.value * 1000).format('YYYY-MM-DD'));
const sdDateEnd = ref(0);
const sdDateEndYYYYMMDD = computed(() => moment(sdDateEnd.value * 1000).format('YYYY-MM-DD'));
const sdTag = ref('');
const isStayDatesActive = computed(() => !!(sdDateBegin.value && sdDateEnd.value));

/**
 * Metric Selector
 */

const selectedMetric = ref(null);
const selectedChartType = ref(null);
const selectedChartSubType = ref(null);
const opportunitiesMetric = ref(null);

const setDates = ({
  type,
  db,
  de,
  isTimestamp,
  userInput,
}) => {
  if (!db || !de) {
    return;
  }

  const dbTmp = isTimestamp ? db : toMoment(db).unix();
  const deTmp = isTimestamp ? de : toMoment(de).unix();

  let selectionType;
  // verifies that both dates aren't identical (already set)
  const sameDates = (begin, end) => dbTmp === begin
    && Math.round(deTmp / 100000) === Math.round(end / 100000);

  if (ctpTag.value === 'custom' && applyCtpStayDates.value) {
    applyCtpStayDates.value = false;
    updateLocalStorage('applyCtpStayDates', applyCtpStayDates.value);
  }
  switch (type) {
    case 'vd': // Visit Dates
      if (!sameDates(dateBegin.value, dateEnd.value)) {
        if (userInput) {
          updateSystemFilters('dateBegin', moment.unix(dbTmp).format('YYYY-MM-DD'));
          updateSystemFilters('dateEnd', moment.unix(deTmp).format('YYYY-MM-DD'));
        }
        dateBegin.value = dbTmp;
        dateEnd.value = deTmp;
        selectionType = 'browsing dates';
      }
      break;
    case 'summVd': // Summary Visit Dates
      if (!sameDates(dateBegin.value, dateEnd.value)) {
        dateBegin.value = dbTmp;
        dateEnd.value = deTmp;
        selectionType = 'summary browsing dates';
      }
      break;
    case 'sd': // Stay Dates
      if (!sameDates(sdDateBegin.value, sdDateEnd.value)) {
        updateSystemFilters('sdDateBegin', moment.unix(dbTmp).format('YYYY-MM-DD'));
        updateSystemFilters('sdDateEnd', moment.unix(deTmp).format('YYYY-MM-DD'));
        sdDateBegin.value = dbTmp;
        sdDateEnd.value = deTmp;
        selectionType = 'stay dates';
      }

      if (applyCtpStayDates.value) {
        const diffFromDb = Math.abs(
          moment
            .unix(dateBegin.value)
            .startOf('day')
            .diff(moment
              .unix(sdDateBegin.value)
              .startOf('day'), 'days'),
        );

        const diffFromDe = Math.abs(
          moment
            .unix(sdDateBegin.value)
            .startOf('day')
            .diff(moment
              .unix(sdDateEnd.value)
              .startOf('day'), 'days'),
        );

        ctpSdDateBegin.value = moment.unix(ctpDateBegin.value).add(diffFromDb, 'days').endOf('day').unix();
        ctpSdDateEnd.value = moment.unix(ctpSdDateBegin.value).add(diffFromDe, 'days').endOf('day').unix();
      }
      break;
    case 'ctp': // Compare to past
      // Set ctpDateBegin
      if (!sameDates(ctpDateBegin.value, ctpDateEnd.value)) {
        updateSystemFilters('ctpDateBegin', moment.unix(dbTmp).format('YYYY-MM-DD'));
        updateSystemFilters('ctpDateEnd', moment.unix(deTmp).format('YYYY-MM-DD'));
        ctpDateBegin.value = dbTmp;
        ctpDateEnd.value = deTmp;
        selectionType = 'compare to past';
      }
      if (applyCtpStayDates.value) {
        const diffFromDb = Math.abs(
          moment
            .unix(dateBegin.value)
            .startOf('day')
            .diff(moment
              .unix(sdDateBegin.value)
              .startOf('day'), 'days'),
        );

        const diffFromDe = Math.abs(
          moment
            .unix(sdDateBegin.value)
            .startOf('day')
            .diff(moment
              .unix(sdDateEnd.value)
              .startOf('day'), 'days'),
        );

        ctpSdDateBegin.value = moment.unix(ctpDateBegin.value).add(diffFromDb, 'days').endOf('day').unix();
        ctpSdDateEnd.value = moment.unix(ctpSdDateBegin.value).add(diffFromDe, 'days').endOf('day').unix();
      }
      break;
    default:
      break;
  }

  if (userInput && selectionType) {
    track(`dates - ${selectionType}`);
  }
};

const unsetDates = (type) => {
  switch (type) {
    case 'vd':
      updateSystemFilters('dateBegin', '');
      updateSystemFilters('dateEnd', '');
      dateBegin.value = 0;
      dateEnd.value = 0;
      break;
    case 'sd':
      updateSystemFilters('sdDateBegin', '');
      updateSystemFilters('sdDateEnd', '');
      sdDateBegin.value = 0;
      sdDateEnd.value = 0;
      break;
    case 'ctp':
      updateSystemFilters('ctpDateBegin', '');
      updateSystemFilters('ctpDateEnd', '');
      ctpDateBegin.value = 0;
      ctpDateEnd.value = 0;
      break;
    default:
      break;
  }
};

const setSelectedTag = (type, tag) => {
  switch (type) {
    case 'vd':
      updateLocalStorage('vdTag', tag);
      vdTag.value = tag;
      break;
    case 'summVd':
      updateLocalStorage('summVdTag', tag);
      summVdTag.value = tag;
      break;
    case 'sd':
      updateLocalStorage('sdTag', tag);
      sdTag.value = tag;
      break;
    case 'ctp':
      updateLocalStorage('ctpTag', tag);
      ctpTag.value = tag;
      break;
    default:
      break;
  }
};

const setApplyCtpStayDates = (value) => {
  applyCtpStayDates.value = value;
};

const setSelectedMetric = (metric) => {
  selectedMetric.value = metric;
};

const setSelectedChartType = (chartType) => {
  selectedChartType.value = chartType;
};

const setSelectedChartSubType = (chartType) => {
  selectedChartSubType.value = chartType;
};

const setOpportunitiesMetric = (metric) => {
  opportunitiesMetric.value = metric;
};

// Filters saved in localstorage
const setDevices = (dev) => {
  devices.value = dev;
};

const setGeolocations = (c) => {
  geolocations.value = c;
};

const setTravelParty = (tp) => {
  travelParty.value = tp;
};

const setHotelType = (hT) => {
  hotelType.value = hT;
};

const setHotelCategory = (hC) => {
  hotelCategory.value = hC;
};

const setSource = (s) => {
  source.value = s;
};

const setFilteredOtas = (o = []) => {
  filteredOtas.value = o.map((ota) => capitalize(ota));
};

const setOtas = (o = []) => {
  cleanFilterValues(otas, o);
  otas.value = o.map((ota) => capitalize(ota));
  setFilteredOtas(filteredOtas.value.filter((ota) => otas.value.includes(ota)));
};

const clearAllSavedFilters = async () => {
  setDevices([]);
  setGeolocations([]);
  setTravelParty([]);
  setHotelType([]);
  setHotelCategory([]);
  setSource([]);
  setOtas([]);
  unsetDates('sd');
  unsetDates('vd');
  unsetDates('ctp');
};

// Observe changes in filters and save into localStorage
watch(devices, async () => updateSystemFilters('devices', devices.value));
watch(geolocations, async () => updateSystemFilters('geolocations', geolocations.value));
watch(travelParty, async () => updateSystemFilters('travelParty', travelParty.value));
watch(hotelType, async () => updateSystemFilters('hotelType', hotelType.value));
watch(hotelCategory, async () => updateSystemFilters('hotelCategory', hotelCategory.value));
watch(source, async () => updateSystemFilters('source', source.value));
watch(filteredOtas, async () => updateSystemFilters('otas', filteredOtas.value));
watch(sectionView, async () => updateSystemFilters('sectionView', sectionView.value));
watch(applyCtpStayDates, async () => updateLocalStorage('applyCtpStayDates', applyCtpStayDates.value));

export default function useFilterState() {
  return {
    // Data
    dateBegin,
    dateBeginYYYYMMDD,
    dateBeginObj,
    dateEnd,
    dateEndYYYYMMDD,
    dateEndObj,
    vdTag,
    summVdTag,
    propertySummaryDaysSelected,
    devices,
    geolocations,
    travelParty,
    hotelType,
    hotelCategory,
    source,
    ctpTag,
    ctpDateBegin,
    ctpDateBeginYYYYMMDD,
    ctpDateEnd,
    ctpDateEndYYYYMMDD,
    selectedMetric,
    selectedChartType,
    selectedChartSubType,
    sdDateBegin,
    sdDateBeginYYYYMMDD,
    sdDateEnd,
    sdDateEndYYYYMMDD,
    sdTag,
    isStayDatesActive,
    otas,
    filteredOtas,
    opportunitiesMetric,
    filtersQuery,
    applyCtpStayDates,
    ctpSdDateBegin,
    ctpSdDateEnd,
    ctpSdDateBeginYYYYMMDD,
    ctpSdDateEndYYYYMMDD,
    // Methods
    setDates,
    unsetDates,
    setSelectedTag,
    setApplyCtpStayDates,
    toMoment,
    setDevices,
    setGeolocations,
    setSelectedMetric,
    setSelectedChartType,
    setSelectedChartSubType,
    setOpportunitiesMetric,
    setOtas,
    setFilteredOtas,
    setTravelParty,
    setHotelType,
    setHotelCategory,
    setSource,
    getCtpDateBegin,
    getCtpDateEnd,
    clearAllSavedFilters,
    setFiltersQuery,
  };
}
