import { ref, watch, Ref } from 'vue';

import i18n from '@/config/i18n';

import useMainState from '@/state/useMainState';

import { HotelType } from '@/declarations/main';

type HotelTypeOptions = Array<{ label: string, value: HotelType }>;

const { language } = useMainState();

const types: HotelTypeOptions = [
  { label: 'Adults Only', value: 'adults only' },
  { label: 'Airport Hotel', value: 'airport hotel' },
  { label: 'Aparthotel', value: 'aparthotel' },
  { label: 'B&B', value: 'bb' },
  { label: 'Beach', value: 'beach' },
  { label: 'Boutique', value: 'boutique' },
  { label: 'Business', value: 'business' },
  { label: 'Casino', value: 'casino' },
  { label: 'City', value: 'city' },
  { label: 'Conference', value: 'conference' },
  { label: 'Family', value: 'family' },
  { label: 'Golf', value: 'golf' },
  { label: 'Hostel', value: 'hostel' },
  { label: 'Leisure', value: 'leisure' },
  { label: 'Luxury', value: 'luxury' },
  { label: 'Motel', value: 'motel' },
  { label: 'Resort', value: 'resort' },
  { label: 'Single Apartment', value: 'apartment' },
  { label: 'Ski Resort', value: 'ski resort' },
  { label: 'Timeshare', value: 'timeshare' },
  { label: 'Wellness & Spa', value: 'wellness & spa' },
];

const hotelTypeOptions: Ref<HotelTypeOptions> = ref([]);

watch(language, () => {
  hotelTypeOptions.value = types
    // @ts-ignore
    .map((type) => ({ ...type, label: i18n.global.t(type.label) }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

export default hotelTypeOptions;
