import useMainState from '@/state/useMainState';

const { userProperties, setUserProperties } = useMainState();

const demoProperties = [1000060, 1000078, 1000672, 1000674, 1000675, 1000676, 1000677]; // H10 properties for demo users

export const defaultDemoProperty = demoProperties[0];

export const replaceDemoProperties = () => {
  const formattedProperties = userProperties.value.map((p, idx) => {
    const propertyId = demoProperties[idx] || demoProperties[0];

    return { ...p, propertyId };
  });

  setUserProperties(formattedProperties);
};
