import { LDFlagSet } from 'launchdarkly-js-client-sdk';

import auth from '@/modules/Auth';
import { featureFlags } from '@/modules/featureFlags';
import { UserInfo } from '@/declarations/main';

const loadFeatureFlags = (
  userInfo: UserInfo,
  setFlags: (flags: LDFlagSet) => void,
) => new Promise((resolve, reject) => {
  const propertyCount = userInfo.properties?.length;

  const user = {
    kind: 'user',
    ...userInfo.user,
    key: userInfo.user?.id,
    dmo: userInfo.user?.permissions?.destination === 2,
    dmoOnly: userInfo.user?.permissions?.destination === 2 && !propertyCount,
    hybrid: !!(userInfo.user?.permissions?.destination === 2 && propertyCount),
    propertyCount,
    isLoginAs: auth.isImpersonatedUser(),
    app: 'benchdirect',
  };

  featureFlags.init({ user, setFlags }).then(resolve).catch(reject);
});

export default loadFeatureFlags;
