import { computed } from 'vue';
import moment from 'moment';

import {
  disablePastDays,
} from '@/utils/disabledCalendarFn';

import getDateRange from '@/utils/getDateRange';

import useFilterState from '@/state/useFilterState';

const {
  dateBegin,
  sdTag,
  setDates,
  setSelectedTag,
  unsetDates,
} = useFilterState();

// STAY DATES
export const minStayDatesMonth = computed(() => moment(dateBegin.value * 1000).startOf('month').unix());

// Define its config and its isDisabled function
export const isStayDateDisabled = (date) => {
  const disabledFunctions = [
    (item) => disablePastDays({ date: item.date, from: dateBegin.value * 1000 }),
  ];

  return disabledFunctions.some((fn) => {
    if (typeof fn === 'function') {
      return fn({ date });
    } return false;
  });
};

// When selecting Stay Dates (custom or predefined option)
export const onStayDatesSelect = ({
  db,
  de,
  tag,
  userInput,
  label,
}) => {
  // Early exit
  if (!tag) {
    return;
  }

  if (tag === 'custom') {
    setDates({
      type: 'sd',
      db,
      de,
      isTimestamp: true,
      userInput,
    });
  } else {
    const days = tag.match(/\d+/)[0];
    setDates({
      type: 'sd',
      db: dateBegin.value,
      de: moment.unix(dateBegin.value).add(days, 'days').endOf('day').unix(),
      isTimestamp: true,
      userInput,
      label,
    });
  }
  setSelectedTag('sd', tag);
};

export const onStayDatesClear = () => {
  unsetDates('sd');
  setSelectedTag('sd', '');
};

export const stayDatesConfig = computed(() => {
  const stayDatesOptions = [
    {
      label: 'Next 7 days',
      sublabel: getDateRange(dateBegin.value, moment.unix(dateBegin.value).add(7, 'days')),
      tag: 'next7',
    },
    {
      label: 'Next 30 days',
      sublabel: getDateRange(dateBegin.value, moment.unix(dateBegin.value).add(30, 'days')),
      tag: 'next30',
    },
    {
      label: 'Next 90 days',
      sublabel: getDateRange(dateBegin.value, moment.unix(dateBegin.value).add(90, 'days')),
      tag: 'next90',
    },
    {
      label: 'Next year',
      sublabel: getDateRange(dateBegin.value, moment.unix(dateBegin.value).add(1, 'years')),
      tag: 'next365',
    },
  ];

  const stayDatesCalendarConfig = {
    isDisabled: isStayDateDisabled,
  };

  return {
    calendarConfig: stayDatesCalendarConfig,
    clearButton: true, // Clear Selection button
    defaultOptions: stayDatesOptions,
    defaultLabel: 'Stay Dates',
    tagSelected: sdTag.value,
    onDatesSelect: (data) => onStayDatesSelect({ ...data, userInput: true }), // mark user input (not updated dates)
    onDatesClear: onStayDatesClear,
  };
});
