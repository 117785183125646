import DataSectionsModuleRoutes from '@/modules/DataSections/routes';
import CompsetsModuleRoutes from '@/modules/Compsets/routes';
import DestinationsModuleRoutes from '@/modules/Destinations/routes';
import OnboardingModuleRoutes from '@/modules/Onboarding/routes';
import UserSettingsModuleRoutes from '@/modules/UserSettings/routes';
import { getUserTypeDefaultRedirect } from '@/utils/getUserTypeDefaultRedirect';

const routes = [
  ...DataSectionsModuleRoutes,
  ...CompsetsModuleRoutes,
  ...DestinationsModuleRoutes,
  ...OnboardingModuleRoutes,
  ...UserSettingsModuleRoutes,
  {
    path: '/waiting-room',
    name: 'waiting_room',
    component: () => import('@/components/WaitingRoom/Intro.vue'),
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      noUser: true,
    },
    component: () => import('@/components/Error/Error.vue'),
  },
  {
    path: '/report/:token',
    name: 'report',
    meta: {
      noUser: true,
    },
    component: () => import('@/components/Report/Report.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: getUserTypeDefaultRedirect,
  },
];

export default routes;
