import bdLogger from '@/utils/bdLogger';

import useSectionState from '@/state/useSectionState';

const { isInternalUser } = useSectionState();

const checkInternalUser = (cb, { allow } = {}) => {
  // block cases
  if ((!isInternalUser.value && allow) // external user but only internal allowed
    || (isInternalUser.value && !allow)) { // internal user but only external allowed
    bdLogger.warn(`${allow ? 'external' : 'internal'} user action blocked`);
    return;
  }
  // execute callback
  cb();
};

export default checkInternalUser;
