import useSectionState from '@/state/useSectionState';
import {
  axis,
  text,
  view,
  bigSize,
  smallSize,
  tooltip,
  strokeDash,
  colorField,
  barChartYOffset,
  barChartComparedStep,
  barChartSingleStep,
  getValueDisplayLayer,
  getFixedBarValues,
} from '@/config/charts';

const getBarChartSpec = ({
  data,
  dataFormat,
  isCtp,
  hideRule,
}) => {
  const {
    compsets,
    compsetNames,
  } = useSectionState();

  const width = 'container';

  // height of each individual bar (total heigth with spaces, not bar size)
  const step = isCtp ? barChartComparedStep : barChartSingleStep;

  const dashedBarOffset = isCtp ? 14 : 4;
  const bandPaddingOuter = isCtp ? 0.1 : 0;

  const barMark = {
    type: 'bar',
    cornerRadiusEnd: 2,
    tooltip,
  };

  const barEncoding = {
    color: colorField,
    x: {
      field: 'valueBar',
      type: 'quantitative',
      axis: false,
    },
  };

  const pastBarEncoding = {
    color: colorField,
    x: {
      field: 'pastValue',
      type: 'quantitative',
      axis: false,
    },
  };

  const highest = Math.max(
    ...data.map((i) => (i.pastValue && i.pastValue > i.value && i.pastValue) || i.value || 0),
  );

  const valuesLayer = getValueDisplayLayer('left', dataFormat, highest);

  return {
    data: {
      values: data,
    },
    transform: [
      // separate our own value in order to draw the vertical line
      {
        calculate: '(datum.isOwn && datum.value) || 0',
        as: 'calculatedOwnValue',
      },
      ...getFixedBarValues(highest),
      {
        joinaggregate: [
          {
            op: 'sum',
            field: 'calculatedOwnValue',
            as: 'ownValue',
          },
        ],
        groupby: ['ownValue'],
      },
    ],
    // Config
    width,
    autosize: { type: 'fit', contains: 'padding' },
    height: { step },
    spacing: 0,
    padding: 5,
    config: {
      view,
      axis: {
        ...axis,
        domain: false,
        labelPadding: 180,
        labelLimit: 150,
        labelAlign: 'left',
      },
      text: { ...text, tooltip },
      scale: {
        barBandPaddingInner: 0.2,
        bandPaddingOuter,
      },
    },
    encoding: {
      y: {
        sort: compsetNames.value,
        field: 'compset',
        type: 'nominal',
      },
      x: {
        scale: {
          nice: false,
          ...(hideRule) && { domain: [0, highest + ((highest * 40) / 100)] },
        },
      },
    },

    // Create layers for Bars, labels, dashed rule
    layer: [
      { // Past dates
        layer: [
          {
            transform: [{ filter: 'datum.pastValue >= 0' }],
            encoding: pastBarEncoding,
            mark: {
              ...barMark,
              size: smallSize,
              yOffset: barChartYOffset,
              opacity: 0.5,
            },
          },
          ...compsets.value.map((c) => ({
            transform: [{ filter: `datum.patternId == ${c.patternId}` }],
            encoding: { ...pastBarEncoding, fill: { value: `url(#pattern_${c.patternId})` } },
            mark: {
              ...barMark,
              size: smallSize,
              yOffset: barChartYOffset,
              opacity: 0.5,
            },
          })),
        ],
      },
      { // Visit dates
        layer: [
          { // Bars
            mark: {
              ...barMark,
              size: bigSize,
            },
            encoding: barEncoding,
          },
          { // Rule
            transform: [
              { filter: 'datum.isOwn && datum.value > 0' },
            ],
            mark: {
              type: 'rule',
              strokeDash,
              strokeWidth: 2,
              yOffset: dashedBarOffset,
              opacity: hideRule ? 0 : 1,
            },
            encoding: {
              x: {
                field: 'valueBar',
                type: 'quantitative',
              },
              y: null,
              color: colorField,
            },
          },
          { // Value labels
            encoding: {
              x: {
                field: 'valueBar',
                type: 'quantitative',
              },
            },
            layer: valuesLayer,
          },
        ],
      },
    ],
  };
};

export default getBarChartSpec;
