import { datadogRum } from '@datadog/browser-rum';

import bdLogger from '@/utils/bdLogger';

import useDebugState from '@/state/useDebugState';
import useMainState from '@/state/useMainState';
import useUserState from '@/state/useUserState';
import env from '@/config/env';

const { userData } = useUserState();
const { propertyId } = useMainState();
const { isDebugActive } = useDebugState();

const beforeSend = (event) => {
  Object.assign(event.context, {
    userId: userData.id,
    propertyId: propertyId.value,
  });
};

export const initDataDog = () => {
  if (isDebugActive.value) {
    bdLogger.warn('DataDog not initialized (debug environment)');
    return;
  }

  datadogRum.init({
    applicationId: '1ed4d887-8854-4d42-a78e-561fc5e17459',
    clientToken: 'pub43525aed0d875bb52121cc8994071255',
    site: 'datadoghq.eu',
    service: 'benchdirect-ui-prd',
    version: '1.0.0',
    env: 'prd',
    sampleRate: 100,
    trackInteractions: true,
    beforeSend,
    allowedTracingOrigins: [env.VUE_APP_BENCHDIRECT_URL],
  });
};

export const dataDogCatch = ({
  err,
  context = {},
}) => {
  bdLogger.warn(err);

  if (isDebugActive.value) {
    return;
  }

  datadogRum.addError(err, {
    ...context,
  }, 'custom');
};

export const dataDogAction = (name, context) => {
  datadogRum.addAction(name, context);
};
