<template>
  <tooltip-base>
    <div class="space-y-4">
      <div
        v-for="i in items"
        :key="i.label"
        class="text-sm text-base-900"
      >
        <span
          class="mr-2 font-bold"
          v-html="i.label"
        />
        <span
          class="font-light"
          v-html="i.desc"
        />
      </div>
    </div>
  </tooltip-base>
</template>
<script>
// Components
import { computed } from 'vue';

import TooltipBase from './TooltipBase.vue';

import i18n from '@/config/i18n';

export default {
  components: {
    TooltipBase,
  },
  props: {
    data: {
      type: Object,
      default: () => (
        {
          Sample: 'Test description',
        }
      ),
    },
  },
  setup(props) {
    const { t } = i18n.global;
    const items = computed(
      () => Object.keys(props.data).map((i) => ({ label: t(i), desc: props.data[i] })),
    );
    return {
      items,
    };
  },
};
</script>
