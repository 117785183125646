import bdLogger from '@/utils/bdLogger';
import fetchRequest from '@/utils/fetchRequest';
import updateSectionData from '@/utils/updateSectionData';

import { scoreOverTimeMonthPipe } from '@/state/sectionStateConfig';

import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';

import scoreMetricsConfig from '@/config/scoreMetricsConfig';
import i18n from '@/config/i18n';

const {
  propertyId,
} = useMainState();

const {
  setPipeStatus,
} = useSectionState();

const { t } = i18n.global;

const getOverTimeScores = async () => {
  const {
    name: pipeName,
  } = scoreOverTimeMonthPipe;

  setPipeStatus(pipeName, { loading: true });

  try {
    const { data } = await fetchRequest(pipeName, {
      isScoreCall: true,
    });

    const newData = Object.keys(data)
      .reduce((acc, key) => {
        const newKey = `${key}ScoreMonth`;

        const scoreMonths = data[key].map((monthItem, idx) => ({
          ...monthItem,
          metric: t(scoreMetricsConfig[key]),
          metricTag: key,
          predicted: idx > data[key].length - 3,
        }));
        // duplicate the second-last month with a falsy predicted key in order to correctly draw the chart lines
        const clonedMonth = scoreMonths.filter((item, idx) => idx === data[key].length - 2)[0];

        scoreMonths.push({ ...clonedMonth, predicted: false, cloned: true });

        acc[newKey] = scoreMonths;

        return acc;
      }, {});

    updateSectionData({
      newData,
      pipe: pipeName,
      pid: propertyId.value,
    });

    setPipeStatus(pipeName, { loading: false });
  } catch (err) {
    bdLogger.warn(err);
    setPipeStatus(pipeName, { hasError: true });
  }
};

export default getOverTimeScores;
