import bdLogger from '@/utils/bdLogger';

const isAbortError = (err = new Error()) => {
  const isAbort = !!err.message.match(/cancel/i);

  if (isAbort) {
    bdLogger.warn('Aborted endpoint call');
  }

  return isAbort;
};

export default isAbortError;
