import { ref, watch } from 'vue';

import { StarIcon } from '@thehotelsnetwork/icons/vue/linear';

import i18n from '@/config/i18n';

import useMainState from '@/state/useMainState';

const { language } = useMainState();

const iconSrc = StarIcon;

const { t } = i18n.global;

const ratingObj = {
  0: { placeholder: 'Other' },
  1: { placeholder: '1', iconSrc },
  2: { placeholder: '2', iconSrc },
  3: { placeholder: '3', iconSrc },
  4: { placeholder: '4', iconSrc },
  5: { placeholder: '5', iconSrc },
  6: { placeholder: 'Luxury' },
};

const ratingArray = [
  { label: '5 stars', value: 5 },
  { label: '4 stars', value: 4 },
  { label: '3 stars', value: 3 },
  { label: '2 stars', value: 2 },
  { label: '1 star', value: 1 },
  { label: t('Other'), value: 0 },
];

const categories = [
  { label: '5 stars', value: '5' },
  { label: '4 stars', value: '4' },
  { label: '3 stars', value: '3' },
  { label: '2 stars', value: '2' },
  { label: '1 star', value: '1' },
  { label: 'Other', value: '0' },
];

const categoryOptions = ref([]);

watch(language, () => {
  categoryOptions.value = categories
    .map((category) => ({ ...category, label: i18n.global.t(category.label) }));
});

export {
  ratingObj,
  ratingArray,
  categoryOptions,
};
