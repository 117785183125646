/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
// @ts-nocheck
import { createApp } from 'vue';

import { createGtm } from 'vue-gtm';

import App from '@/layout/App/App.vue';

import InfoTooltip from '@/components/Tooltip/InfoTooltip.vue';
import DescTooltip from '@/components/Tooltip/DescTooltip.vue';

import { tooltip } from '@/directives/tooltip';
import router from '@/router';

import i18n from '@/config/i18n';
import '@/css/app.css';

declare global {
  interface Window {
    Appcues: any, // 👈️ turn off type checking
    AppcuesSettings: any,
  }
}

const gtm = createGtm({
  id: 'GTM-PR875NV',
  loadScript: true,
  vueRouter: router,
});

export const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(gtm);

app.component('BmInfoTooltip', InfoTooltip);
app.component('BmDescTooltip', DescTooltip);

app.directive('tooltip', tooltip);

// This is to avoid first load with all routes pointing to "/" instead of defined path
router.isReady().then(() => app.mount('#app'));
