const getDefaultSectionDataConfig = (data) => {
  // setup initial config for each data key
  const sectionData = data.reduce((acc, item) => {
    acc[item.key] = ({
      ...item,
      values: [],
    });

    return acc;
  }, {});

  return sectionData;
};

export default getDefaultSectionDataConfig;
