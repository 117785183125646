import moment from 'moment';

export const disablePastNumDays = ({
  days,
  date,
  from,
}) => (from ? moment(from) : moment()).startOf('day').diff(moment.unix(date).startOf('day'), 'days') > days;

export const disableFutureDates = ({
  date,
  from,
}) => moment.unix(date).startOf('day').diff((from ? moment(from) : moment()).startOf('day'), 'days') > 0;

export const disablePastDays = ({
  date,
  from,
}) => moment.unix(date).startOf('day').diff((from ? moment(from) : moment()).startOf('day'), 'days') < 0;

export const disablePastFirstVisitDate = ({
  firstVisitDay,
  date,
}) => moment.unix(date).startOf('day').diff(moment.unix(firstVisitDay).startOf('day'), 'days') < 0;

export const disableFutureVisitDate = ({
  endDay,
  date,
}) => moment.unix(date).startOf('day').diff(moment.unix(endDay).startOf('day'), 'days') >= 0;
