import useTooltipStateV2 from '@/state/useTooltipStateV2';

const { initTooltip, unmountTooltip } = useTooltipStateV2();

const db = new Map();

const setup = (el, val, mod) => {
  let cb;
  // Click modifier
  if (mod.click) {
    cb = (ev) => {
      if ((el === ev.target || el.contains(ev.target)) && (!val.show || val.show.value)) {
        initTooltip(ev, val.type, val.content, val.preferred);
      } else {
        unmountTooltip();
      }
    };
    document.body.addEventListener('click', cb);
    return function cleanUp() {
      el.removeEventListener('click', cb);
    };
  }
  // Hover modifier
  cb = (ev) => {
    ev.stopPropagation();
    if (!val.show || val.show.value) {
      initTooltip(ev, val.type, val.content, val.preferred);
    }
  };
  el.addEventListener('mouseenter', cb);
  el.addEventListener('mouseleave', unmountTooltip);
  el.addEventListener('wheel', unmountTooltip, { passive: true });

  return function cleanUp() {
    el.removeEventListener('mouseenter', cb);
    el.removeEventListener('mouseleave', unmountTooltip);
    el.removeEventListener('wheel', unmountTooltip);
  };
};

export const tooltip = {
  beforeMount(el, { value = {}, modifiers = {} }) {
    if (db.get(el)) {
      db.get(el).cleanUp();
    }
    if (value) {
      db.set(el, {
        cleanUp: setup(el, value, modifiers),
      });
    }
  },
  updated(el, { value = {}, oldValue = {}, modifiers = {} }) {
    // Update listener callback
    if (value && oldValue && value.content.text !== oldValue.content.text) {
      if (db.get(el)) {
        db.get(el).cleanUp();
      }
      db.set(el, {
        cleanUp: setup(el, value, modifiers),
      });
    }
  },
  unmounted(el) {
    if (db.get(el)) {
      unmountTooltip();
      db.get(el).cleanUp();
      db.delete(el);
    }
  },
};
