import { createRouter, createWebHistory } from 'vue-router';

import { beforeGuard, afterGuard } from './routerGuard';
import projectRoutes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes: projectRoutes,
});

router.beforeEach(beforeGuard);

router.afterEach(afterGuard);

export default router;
