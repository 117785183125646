import {
  ref,
  computed,
  reactive,
  watch,
} from 'vue';

import i18n from '@/config/i18n';

import bdLogger from '@/utils/bdLogger';
import getKeyFromObject from '@/utils/getKeyFromObject';
import sectionExceptions from '@/utils/sectionExceptions';
import updateLocalStorage from '@/utils/updateLocalStorage';
import getPropertyData from '@/utils/getPropertyData';

import {
  getPipeData,
  resetPipesData,
} from '@/state';

import useMainState from '@/state/useMainState';
import useUserState from '@/state/useUserState';
import useDebugState from '@/state/useDebugState';

const { disableInternal } = useDebugState();
const { t } = i18n.global;

const {
  userProperties,
  isOverview,
  propertyId,
  featureFlags,
} = useMainState();

const { userData, isStrictlyDMOUser } = useUserState();

const sectionName = ref('');

const setSectionName = (name) => {
  sectionName.value = name;
};

// selected hotel name
const hotel = computed(() => {
  if (isOverview.value) {
    return t('My Properties');
  }
  return (userProperties.value
    .filter((p) => p.propertyId === propertyId.value)[0] || {}).name;
});

// 'dates'
// 'compsets'
// 'calendar'
// 'geofilter'
// 'destination'
// 'cluster'
const currentPermissions = computed(() => (isOverview.value
  ? userData.overviewPermissions
  : getPropertyData(propertyId.value).permissions) || {});

const compsetsRaw = ref([]);

// full compset data
const compsets = computed(() => compsetsRaw.value.map((c) => {
  if (c.type === 'default') {
    return {
      ...c,
      compset: c.isOwn ? hotel.value : t(c.compset),
    };
  }
  return c;
}));

// compsets to show based on user input
const compsetsFilter = ref([]);

const overviewTableSection = ref('overview');

// charts / tables / calendar view (current)
const sectionView = ref('charts');

watch(sectionView, () => {
  updateLocalStorage('viewMode', sectionView.value);
});

const isSummarySection = computed(() => !!sectionName.value?.match(/summary/i));
const isPropertySummarySection = computed(() => isSummarySection.value && !isOverview.value);
const isUserSummarySection = computed(() => isSummarySection.value && isOverview.value);

const isChartsView = computed(() => sectionView.value === 'charts');
const isTablesView = computed(() => sectionView.value === 'tables');
const isCalendarView = computed(() => !isSummarySection.value && sectionView.value === 'calendar');
const isDefaultOverviewSection = computed(() => isOverview.value
  && !isStrictlyDMOUser
  && isTablesView.value
  && overviewTableSection.value === 'overview');
const isBreakdownOverviewSection = computed(() => isOverview.value
  && isTablesView.value
  && overviewTableSection.value === 'breakdown');

const shouldShowCompsetInFilter = (c) => !isOverview.value
  || !c.compsetTag.match(/proximity/);

const isCompsetValid = (c) => (c.status !== 'rates_only' || sectionName.value === 'rates')
&& (c.type !== 'destination' // Destination type compset
  || (featureFlags.value.compsets
    && currentPermissions.value.destination
    && (!isOverview.value || isStrictlyDMOUser.value)))
// Custom type compset
&& (c.type !== 'custom'
  || sectionName.value === 'rates' || (featureFlags.value.compsets && currentPermissions.value.compsets))
// Cluster/brand type compset
&& (!c.type.match(/cluster|brand/) || currentPermissions.value.cluster)
// Brand type compset
&& (c.type !== 'brand' || isOverview.value);

// compset names only
const compsetNames = computed(() => compsets.value
  .filter((c) => compsetsFilter.value.indexOf(c.compsetTag) !== -1
    && isCompsetValid(c))
  .map((c) => c.compset));

// get compset properties
const getCompsetData = (inputCompset) => compsets.value
  .filter((c) => isCompsetValid(c) && c.compsetTag === inputCompset)[0] || {};

const setCompsetsFilter = (filter = []) => {
  const newFilterValue = compsets.value
    .filter((c) => c.ready && filter.indexOf(c.compsetTag) !== -1);

  compsetsFilter.value = newFilterValue
    .filter(isCompsetValid)
    .filter(shouldShowCompsetInFilter)
    .map((c) => c.compsetTag);
};

watch(sectionName, () => {
  const updatedFilterValue = [
    ...compsetsFilter.value,
  ];

  if (sectionName.value === 'rates') {
    updatedFilterValue.push(
      ...compsets.value.filter((c) => c.type === 'custom' || c.status === 'rates_only').map((c) => c.compsetTag),
    );
  }

  setCompsetsFilter(updatedFilterValue);
});

const resetCompsetsFilter = () => {
  setCompsetsFilter(compsets.value.map((c) => c.compsetTag));
};

const setCompsetsRaw = (value) => {
  compsetsRaw.value = value;
  resetCompsetsFilter();
};

const userHasCustomCompsets = computed(() => compsets.value
  .some((c) => (c.type === 'custom')));

const userHasDestinations = computed(() => compsets.value
  .some((c) => (c.type === 'destination')));

const userHasClusters = computed(() => compsets.value
  .some((c) => (c.type === 'cluster')));

const userHasBrands = computed(() => compsets.value
  .some((c) => (c.type === 'brand')));

const intervals = [
  {
    name: 'date',
    placeholder: 'Daily',
  },
  {
    name: 'week',
    placeholder: 'Weekly',
  },
  {
    name: 'month',
    placeholder: 'Monthly',
  },
];

// main section data where section-specific metric refs are inserted
const sectionData = {};

const isSectionDataLoading = ref(false);

const setIsSectionDataLoading = (val) => {
  isSectionDataLoading.value = val;
};

const sectionTitle = ref('');
const setSectionTitle = (title = '') => {
  sectionTitle.value = t(title);
};

const mainSectionElementRef = ref(null);

// section content
const sectionTables = ref([]);
const sectionCharts = ref([]);
const sectionChartTypes = ref([]);
const sectionMetricsMain = ref([]);
const sectionMetrics = computed(() => sectionMetricsMain.value.map((metric) => ({
  ...metric,
  desc: t(metric.desc || ''),
  placeholder: t(metric.placeholder || ''),
})));

// mounting measures for specific components
const sectionWidth = ref(null);
const scrollbarOffsetHeight = ref(0);

// endpoint current call
const runningDataCalls = ref(null);
const runningScoreCalls = ref(null);

const setRunningCalls = (type) => {
  let tmpRunningCalls;

  switch (type) {
    default:
      setRunningCalls('data');
      setRunningCalls('score');
      return;
    case 'data':
      tmpRunningCalls = runningDataCalls;
      break;
    case 'score':
      tmpRunningCalls = runningScoreCalls;
      break;
  }

  if (tmpRunningCalls.value) {
    tmpRunningCalls.value.abort();
  }
  tmpRunningCalls.value = new AbortController();
};

const dataId = ref(0);

const setDataId = () => {
  dataId.value += 1;
};

const isValidDataId = (id) => id === undefined || id === dataId.value;

const setPipeStatus = (pipe, {
  hasError,
  loading,
  isCtp,
  currentDataId,
}) => {
  if (!isValidDataId(currentDataId)) {
    return;
  }
  const pipeData = getPipeData(pipe);

  if (hasError !== undefined) {
    // reset loading status
    if (pipeData.loading) {
      pipeData.loading = false;
    }

    // set errors
    pipeData.error = hasError;
  }

  if (loading !== undefined) {
    // set loading status
    pipeData.loading = loading;
    pipeData.error = false;
  }

  if (isCtp !== undefined) {
    pipeData.isCtp = isCtp;
  }

  // update data ID when loading finishes
  if (pipeData.loading === false) {
    pipeData.dataId = currentDataId;
  }
  pipeData.initialized = pipeData.loading;
};

const notEnoughProperties = ref(false);

const setNotEnoughProperties = (val) => {
  notEnoughProperties.value = val;
};

const sectionHasDisabledViews = computed(
  () => getKeyFromObject(sectionExceptions, sectionName.value).disabledViews,
);

const sectionHasScore = computed(
  () => !getKeyFromObject(sectionExceptions, sectionName.value).hideScore,
);
const sectionHasCalendarView = computed(
  () => !(sectionHasDisabledViews.value && sectionHasDisabledViews.value.calendar),
);
const sectionHasCalendarStayDates = computed(
  () => !getKeyFromObject(sectionExceptions, sectionName.value).disableCalendarStayDates,
);

const setSectionView = (view) => {
  const exceptions = getKeyFromObject(sectionExceptions, sectionName.value);
  if (exceptions.disabledViews && exceptions.disabledViews[view]) {
    sectionView.value = exceptions.defaultView;
  } else {
    sectionView.value = view;
  }
};

watch(overviewTableSection, () => {
  updateLocalStorage('breakdownMode', overviewTableSection.value);
});

const clearSectionData = () => {
  // clear metric data
  Object.keys(sectionData)
    .forEach((pid) => Object.keys(sectionData[pid])
      .forEach((key) => {
        // keep score data
        if (key.match(/score|geolocationvisits/i)) {
          return;
        }

        const currentMetric = sectionData[pid][key];

        currentMetric.values = [];
      }));
  resetPipesData();
  // clear not enough properties info message
  setNotEnoughProperties(false);
};

const getReactiveMetricData = (data) => {
  const tmpData = {};

  Object.keys(data).forEach((metric) => {
    tmpData[metric] = reactive({ ...data[metric] });
  });

  return tmpData;
};

const setSectionMetricData = (data) => {
  [
    ...userProperties.value,
    { propertyId: 0 },
  ].forEach((property) => {
    sectionData[property.propertyId] = getReactiveMetricData(data);
  });
};

const setSectionWidth = (width) => {
  sectionWidth.value = width;
};

const setSectionTables = (tables) => {
  sectionTables.value = tables;
};

const setSectionCharts = (charts) => {
  sectionCharts.value = charts;
};

const setSectionChartTypes = (chartTypes) => {
  sectionChartTypes.value = chartTypes.reduce((acc, curr, _, arr) => {
    // enters only one time for every chart type
    if (!acc.some((item) => item.type === curr.type)) {
      // collects every different chart subtype among every chart type
      const subTypes = arr.reduce((subTypesAcc, currType) => {
        if (curr.type === currType.type
            && currType.subType
            && !subTypesAcc.includes(currType.subType)) {
          subTypesAcc.push(currType.subType);
        }

        return subTypesAcc;
      }, []);

      // unifies all subtypes under the same type
      acc.push({
        type: curr.type,
        subTypes,
      });
    }

    return acc;
  }, []);
};

const getChartById = (id) => sectionCharts.value.filter((chart) => chart.id === id)[0] || {};

const getTableById = (id) => sectionTables.value.filter((table) => table.id === id)[0] || {};

const setSectionMetrics = (metrics) => {
  sectionMetricsMain.value = metrics;
};

const setScrollbarOffsetHeight = (height) => {
  scrollbarOffsetHeight.value = height;
};

const setOverviewTableSection = (section) => {
  overviewTableSection.value = section;
};

const getSectionData = (pid) => sectionData[pid];

const sectionScore = computed(() => {
  const { scores } = getPropertyData(propertyId.value);
  // extract pipe status from either case
  const { loading, error } = getPipeData('score');

  // get total scrore from section
  let tmpScore = null;

  if (scores && !error) {
    if (isSummarySection.value) {
      tmpScore = scores.dbi;
    } else {
      tmpScore = scores[sectionName.value];
    }

    tmpScore = Math.round(Math.min(tmpScore, 100) || 0);
  }

  return {
    loading,
    score: tmpScore,
    error,
  };
});

const sectionBreakdownPipe = ref(null);

const setSectionBreakdownPipe = (val) => {
  sectionBreakdownPipe.value = val;
};

const breakdownPropertyCallList = ref([]);

const setBreakdownPropertyCallList = (list) => {
  breakdownPropertyCallList.value = list;
};

const loadingProperties = ref([]);

const failedProperties = ref([]);

const isBreakdownLoading = computed(() => loadingProperties.value.length);

const isLoadingProperty = (pid) => loadingProperties.value.indexOf(pid) !== -1;

const isFailedProperty = (pid) => failedProperties.value.indexOf(pid) !== -1;

const setLoadingProperty = (pid, loading) => {
  if (loading) {
    // add loading property
    loadingProperties.value = [...loadingProperties.value, pid];
  } else {
    // remove loading property
    loadingProperties.value = loadingProperties.value.filter((p) => p !== pid);
  }
};

const clearLoadingProperties = () => {
  loadingProperties.value = [];
};

const recentCalls = ref([]);

const isInternalUser = ref(false);

const setIsInternalUser = (val) => {
  let value = val;

  if (disableInternal.value) {
    value = false;
  }

  bdLogger.log(`Internal User: ${value}${disableInternal.value ? ' (FORCED)' : ''}`);

  isInternalUser.value = value;
};

const shouldGetOverviewStats = ref(false);

const setShouldGetOverviewStats = (val) => {
  shouldGetOverviewStats.value = val;
};

const setChartImageGetter = (id, getter) => {
  getChartById(id).getImg = getter;
};

const isBenchDirectLoading = ref(true);

const setIsBenchDirectLoading = (val) => {
  isBenchDirectLoading.value = val;
};

const filtersQuery = ref({});

const setFiltersQuery = (query) => {
  filtersQuery.value = query;
};

const isFreeCalendarView = computed(() => isCalendarView.value
  && !currentPermissions.value.calendar);

const maxFreeCompsets = computed(() => (isStrictlyDMOUser.value
  ? 5
  : 2 * userProperties.value.length));

export default function useSectionState() {
  return {
    // Data
    sectionData,
    sectionView,
    sectionCharts,
    sectionChartTypes,
    sectionTables,
    sectionMetrics,
    sectionWidth,
    scrollbarOffsetHeight,
    compsets,
    compsetNames,
    compsetsFilter,
    userHasCustomCompsets,
    userHasDestinations,
    userHasClusters,
    userHasBrands,
    hotel,
    intervals,
    overviewTableSection,
    isDefaultOverviewSection,
    isBreakdownOverviewSection,
    sectionBreakdownPipe,
    isBreakdownLoading,
    breakdownPropertyCallList,
    loadingProperties,
    failedProperties,
    dataId,
    sectionName,
    sectionScore,
    sectionHasScore,
    sectionHasCalendarView,
    sectionHasCalendarStayDates,
    notEnoughProperties,
    isSummarySection,
    isPropertySummarySection,
    isUserSummarySection,
    isChartsView,
    isTablesView,
    isCalendarView,
    sectionTitle,
    isSectionDataLoading,
    recentCalls,
    currentPermissions,
    isInternalUser,
    shouldGetOverviewStats,
    runningDataCalls,
    runningScoreCalls,
    mainSectionElementRef,
    isBenchDirectLoading,
    filtersQuery,
    isFreeCalendarView,
    maxFreeCompsets,
    // Methods
    isCompsetValid,
    setSectionTitle,
    setSectionMetricData,
    clearSectionData,
    setSectionCharts,
    setSectionChartTypes,
    getChartById,
    getTableById,
    setSectionTables,
    setSectionMetrics,
    setSectionWidth,
    setScrollbarOffsetHeight,
    getCompsetData,
    setCompsetsFilter,
    resetCompsetsFilter,
    setPipeStatus,
    setRunningCalls,
    setOverviewTableSection,
    getSectionData,
    setSectionBreakdownPipe,
    setBreakdownPropertyCallList,
    setLoadingProperty,
    clearLoadingProperties,
    isLoadingProperty,
    isFailedProperty,
    setDataId,
    setSectionName,
    setSectionView,
    setNotEnoughProperties,
    isValidDataId,
    setIsSectionDataLoading,
    setIsInternalUser,
    setShouldGetOverviewStats,
    setChartImageGetter,
    setCompsetsRaw,
    setIsBenchDirectLoading,
    setFiltersQuery,
    shouldShowCompsetInFilter,
  };
}
