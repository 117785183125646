/* eslint-disable prefer-destructuring */
import i18n from '@/config/i18n';

import getPropertyData from '@/utils/getPropertyData';

import useUserState from '@/state/useUserState';
import useMainState from '@/state/useMainState';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const translateSubString = (s, start, end) => i18n.global.t(s)
  .substr(start || 0, end || s.length - 1);

const getTimeFormatConfig = (l) => {
  const timeFormatConfig = {
    en: {
      dateTime: '%x, %X',
      date: '%-m/%-d/%Y',
      time: '%-I:%M:%S %p',
      periods: ['AM', 'PM'],
    },
    es: {
      dateTime: '%A, %e de %B de %Y, %X',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
    },
  };

  const timeConfig = timeFormatConfig[l] || timeFormatConfig.en;

  return {
    ...timeConfig,
    days: days.map((d) => translateSubString(d)),
    shortDays: days.map((d) => translateSubString(d, 0, 3)),
    months: months.map((m) => translateSubString(m)),
    shortMonths: months.map((m) => translateSubString(m, 0, 3)),
  };
};

// values: 1: AUTO, 2: dot(thousand)/comma(decimal), 3: comma(thousand)/dot(decimal)
const currencyFormatConfig = {
  AED: {
    auto: 3,
    currency: ['', 'د.إ'],
  },
  ARS: {
    auto: 2,
  },
  AUD: {
    auto: 3,
  },
  BRL: {
    auto: 2,
  },
  CAD: {
    auto: 2,
  },
  CHF: {
    auto: 2,
    currency: ['Fr', ''],
  },
  CLP: {
    auto: 3,
  },
  CNY: {
    auto: 3,
    currency: ['¥', ''],
  },
  COP: {
    auto: 3,
  },
  CRC: {
    currency: ['₡', ''],
  },
  CUP: {
    currency: ['$MN', ''],
  },
  CZK: {
    auto: 2,
    currency: ['', 'Kč'],
  },
  DKK: {
    auto: 2,
    currency: ['Kr', ''],
  },
  EEK: {
    auto: 2,
    currency: ['kr', ''],
  },
  EUR: {
    auto: 2,
    currency: ['', '€'],
  },
  FOK: {
    auto: 3,
    currency: ['kr', ''],
  },
  GBP: {
    auto: 3,
    currency: ['£', ''],
  },
  HKD: {
    auto: 3,
  },
  HUF: {
    auto: 2,
    currency: ['', 'Ft'],
  },
  IDR: {
    auto: 3,
    currency: ['Rp', ''],
  },
  ILS: {
    auto: 2,
    currency: ['₪', ''],
  },
  ISK: {
    auto: 3,
    currency: ['kr', ''],
  },
  INR: {
    currency: ['₹', ''],
  },
  JPY: {
    auto: 3,
    currency: ['¥', ''],
  },
  KRW: {
    auto: 3,
    currency: ['₩', ''],
  },
  MAD: {
    auto: 3,
    currency: ['', '.د.م.'],
  },
  MXN: {
    auto: 3,
  },
  MYR: {
    auto: 3,
    currency: ['RM', ''],
  },
  NOK: {
    auto: 3,
    currency: ['kr', ''],
  },
  NZD: {
    auto: 3,
  },
  PEN: {
    currency: ['S/', ''],
  },
  PHP: {
    auto: 3,
    currency: ['₱', ''],
  },
  PLN: {
    auto: 2,
    currency: ['', 'zł'],
  },
  RUB: {
    auto: 2,
    currency: ['', '₽'],
  },
  SAR: {
    auto: 3,
    currency: ['', '﷼'],
  },
  SEK: {
    auto: 2,
    currency: ['', 'kr'],
  },
  SGD: {
    auto: 3,
  },
  THB: {
    auto: 3,
    currency: ['', '฿'],
  },
  TRY: {
    auto: 3,
    currency: ['', '₺'],
  },
  TWD: {
    auto: 3,
    currency: ['元', ''],
  },
  USD: {
    auto: 3,
    currency: ['$', ''],
  },
  VND: {
    auto: 2,
    currency: ['', '₫'],
  },
  ZAR: {
    auto: 3,
    currency: ['R', ''],
  },
};

const getLocaleConfig = (overrides = {}) => {
  const { userData } = useUserState();
  const { propertyId, isOverview } = useMainState();

  let currency;
  let currencyFormat = 1;

  if (!overrides.currency) {
    /*
      for overview, use user's default currency, otherwise, use the
      selected property's selected currency
    */

    if (isOverview.value) {
      currency = userData.currency;
      currencyFormat = 1;
    } else {
      const propertyData = getPropertyData(propertyId.value);
      currency = propertyData.currency;
      currencyFormat = propertyData.currencyFormat;
    }
  } else {
    currency = overrides.currency;
  }

  // still no currency? set default
  if (currency === null) {
    currency = 'USD';
  }

  const currencyConfig = currencyFormatConfig[currency] || {};

  if (!currencyConfig.currency) {
    currencyConfig.currency = ['', ` ${currency}`];
  }

  // use automatic formatting?
  if (currencyFormat === 1) {
    // use currency default formatting
    currencyFormat = currencyConfig.auto || 2;
  }

  // apply formats
  if (currencyFormat === 2) {
    currencyConfig.thousands = '.';
    currencyConfig.decimal = ',';
  } else if (currencyFormat === 3) {
    currencyConfig.thousands = ',';
    currencyConfig.decimal = '.';
  }

  currencyConfig.grouping = [3];

  const timeConfig = getTimeFormatConfig(userData.language || 'en');

  return {
    currency,
    currencyConfig,
    timeConfig,
  };
};

export default getLocaleConfig;
