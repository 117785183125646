import useSectionState from '@/state/useSectionState';
import {
  axis,
  text,
  view,
  fontColor,
  bigSize,
  smallSize,
  tooltip,
  strokeDash,
  colorField,
} from '@/config/charts';

const getBoxplotChartSpec = ({
  dataFormat,
  data,
  isCtp,
}) => {
  const {
    compsets,
    compsetNames,
  } = useSectionState();

  const width = 'container';

  // axis X's Y offset (separation from bottom bar)
  const offsetLarge = 36;
  let offset;
  // height of each individual bar (total heigth with spaces, not bar size)
  let heightStep;
  // offset for the small bars (compare to past)
  let yOffset;

  if (isCtp) {
    offset = offsetLarge;
    heightStep = 66;
    yOffset = bigSize;
  } else {
    offset = 22;
    heightStep = 40;
    yOffset = 0;
  }

  // own hotel indicator line config
  const hotelLineHeight = (heightStep * data.length) - offsetLarge;
  const hotelCircleIndicatorPosition = -20;

  const isOwnPastMedianFilter = '!datum.isOwn && datum.pastMedian';

  const boxplotRule = {
    type: 'rule',
    invalid: null,
    style: 'boxplot-rule',
    aria: false,
    color: fontColor,
  };

  const boxplotBox = {
    type: 'bar',
    orient: 'horizontal',
    invalid: null,
    style: 'boxplot-box',
    aria: false,
    cornerRadius: 2,
    tooltip,
  };

  const boxplotTick = {
    type: 'tick',
    invalid: null,
    orient: 'vertical',
    ariaRoleDescription: 'box',
    style: 'boxplot-median',
  };

  const hotelRule = {
    type: 'rule',
    strokeWidth: 2,
    tooltip,
  };

  const boxPlotMedianTickMark = {
    ...boxplotTick,
    color: 'white',
  };

  const getBarWhiskersLayer = (minField, maxField, whiskerOffset) => [
    { // visit bar whiskers
      transform: [
        { filter: `!datum.isOwn && datum.${minField} >= 0` },
      ],
      encoding: {
        x: {
          field: minField,
          type: 'quantitative',
        },
      },
      mark: {
        ...boxplotTick,
        size: smallSize,
        color: fontColor,
        yOffset: whiskerOffset,
      },
    },
    {
      transform: [
        { filter: `!datum.isOwn && datum.${maxField} >= 0` },
      ],
      encoding: {
        x: {
          field: maxField,
          type: 'quantitative',
        },
      },
      mark: {
        ...boxplotTick,
        size: smallSize,
        color: fontColor,
        yOffset: whiskerOffset,
      },
    },
  ];

  return {
    data: {
      values: data,
    },

    // Config
    width,
    autosize: { type: 'fit', contains: 'padding' },
    height: { step: heightStep },
    padding: 5,
    config: {
      view,
      axis: {
        ...axis,
        tickCount: 5,
        format: dataFormat,
        offset,
      },
      text,
    },
    // Main Y axis
    encoding: {
      y: {
        field: 'compset',
        type: 'nominal',
        axis: false,
        sort: compsetNames.value,
      },
      x: {
        scale: {
          clamp: false,
          nice: true,
        },
      },
    },

    // X axis layers
    layer: [
      {
        layer: [
          {
            layer: [
              {
                layer: [
                  { // visit
                    transform: [{ filter: '!datum.isOwn' }],
                    mark: {
                      type: 'rule',
                      invalid: null,
                      style: 'boxplot-rule',
                      aria: false,
                    },
                    encoding: {
                      x: {
                        field: 'q1',
                        type: 'quantitative',
                      },
                      x2: { field: 'q1' },
                    },
                  },
                  { // past
                    transform: [{ filter: isOwnPastMedianFilter }],
                    mark: {
                      type: 'rule',
                      invalid: null,
                      style: 'boxplot-rule',
                      aria: false,
                    },
                    encoding: {
                      x: {
                        field: 'pastQ1',
                        type: 'quantitative',
                      },
                      x2: { field: 'pastQ1' },
                    },
                  },

                  { // visit
                    transform: [
                      { filter: '!datum.isOwn' },
                    ],
                    encoding: {
                      x: {
                        field: 'max',
                        type: 'quantitative',
                      },
                      x2: { field: 'min' },
                    },
                    mark: boxplotRule,
                  },
                  { // past
                    transform: [
                      { filter: isOwnPastMedianFilter },
                    ],
                    encoding: {
                      x: {
                        field: 'pastMax',
                        type: 'quantitative',
                      },
                      x2: { field: 'pastMin' },
                    },
                    mark: { ...boxplotRule, yOffset },
                  },
                ],
              },
            ],
          },
          {
            layer: [
              { // visit
                transform: [
                  { filter: '!datum.isOwn' },
                ],
                encoding: {
                  color: colorField,
                  x: {
                    field: 'q1',
                    type: 'quantitative',
                  },
                  x2: { field: 'q3' },
                },
                mark: { ...boxplotBox, size: bigSize },

              },
              { // past
                transform: [
                  { filter: isOwnPastMedianFilter },
                ],
                encoding: {
                  color: colorField,
                  x: {
                    field: 'pastQ1',
                    type: 'quantitative',
                  },
                  x2: { field: 'pastQ3' },
                },
                layer: [
                  {
                    mark: {
                      ...boxplotBox,
                      yOffset,
                      size: smallSize,
                      opacity: 0.5,
                    },
                  },
                  ...compsets.value.map((c) => ({
                    transform: [{ filter: `datum.patternId == ${c.patternId}` }],
                    mark: {
                      ...boxplotBox,
                      yOffset,
                      size:
                      smallSize,
                    },
                    encoding: {
                      fill: { value: `url(#pattern_${c.patternId})` },
                    },
                  })),
                ],
              },

              {
                transform: [
                  { filter: '!datum.isOwn' },
                ],
                encoding: {
                  x: {
                    field: 'max',
                    type: 'quantitative',
                  },
                  text: {
                    field: 'compset',
                    type: 'nominal',
                  },
                },
                mark: {
                  type: 'text',
                  align: 'left',
                  dx: 10,
                  color: fontColor,
                },
              },
              ...getBarWhiskersLayer('min', 'max', 0),
              ...getBarWhiskersLayer('pastMin', 'pastMax', bigSize),
              { // visit
                transform: [
                  { filter: '!datum.isOwn' },
                ],
                encoding: {
                  x: {
                    field: 'median',
                    type: 'quantitative',
                  },
                },
                mark: { ...boxPlotMedianTickMark, size: bigSize },

              },
              { // past
                transform: [
                  { filter: isOwnPastMedianFilter },
                ],
                encoding: {
                  x: {
                    field: 'pastMedian',
                    type: 'quantitative',
                  },
                },
                mark: { ...boxPlotMedianTickMark, size: smallSize, yOffset: bigSize },
              },
            ],
          },
        ],
      },
      // Hotel vertical bar
      { // common config
        transform: [
          { filter: 'datum.isOwn' },
        ],
        // divide layers
        layer: [
          { // visit
            encoding: {
              color: colorField,
              x: {
                field: 'median',
                type: 'quantitative',
              },
            },
            layer: [
              { // Bar
                encoding: {
                  y: { value: 0 },
                  y2: { value: hotelLineHeight },
                },
                mark: hotelRule,
              },
              { // Circle on top
                mark: {
                  type: 'circle',
                  size: 600,
                  opacity: 1,
                  tooltip,
                },
                encoding: {
                  y: { value: hotelCircleIndicatorPosition },
                },
              },
              { // Text inside circle
                mark: {
                  type: 'text',
                  text: 'H',
                  tooltip,
                },
                encoding: {
                  y: { value: hotelCircleIndicatorPosition },
                  color: {
                    value: 'white',
                  },
                },
              },
            ],
          },

          { // past
            encoding: {
              color: colorField,
              x: {
                field: 'pastMedian',
                type: 'quantitative',
              },
            },
            layer: [
              { // Bar
                encoding: {
                  y: { value: 0 },
                  y2: { value: hotelLineHeight },
                },
                mark: { ...hotelRule, strokeDash, opacity: 0.5 },

              },
            ],
          },
        ],
      },
    ],
  };
};

export default getBoxplotChartSpec;
