import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from 'tailwind-config';

import apiHelper from '@/utils/apiHelper';
import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';
import useUserState from '@/state/useUserState';

import { CompsetRaw, UserCompset } from '@/declarations/customCompsets';

const { theme }: any = resolveConfig(tailwindConfig);

const { setCompsetsRaw } = useSectionState();
const { propertyId, featureFlags, isOverview }: any = useMainState();
const { isStrictlyDMOUser } = useUserState();

let abortController: AbortController | null;
let customCompsetPatternIdCount = 4;

const getUserCompsets = async () => {
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();

  setCompsetsRaw([]);

  const { data } = await apiHelper(
    'GET',
    `/compsets/${propertyId.value || ''}`,
    null,
    {
      signal: abortController.signal,
      useCache: true,
    },
  ) as { data: { data: CompsetRaw[] }};

  const compsets = data.data.reduce((acc: UserCompset[], compset) => {
    const isBrandOrCluster = compset.type.match(/cluster|brand/);

    const newCompset: UserCompset = {
      compset: compset.name,
      compsetTag: compset.id,
      type: compset.type,
      ready: compset.status === 'rates_only' || compset.ready,
      status: compset.status,
      color: '',
      patternId: 0,
    };

    if (compset.type === 'default') {
      newCompset.ready = true;

      switch (compset.id) {
        case 'own':
          newCompset.patternId = 1;
          newCompset.isOwn = true;
          newCompset.subTag = 'Hotel';
          newCompset.color = theme.colors.hotel['500'];
          break;
        case 'proximity':
          newCompset.patternId = 3;
          newCompset.color = theme.colors.proximity['500'];
          break;
        case 'thn':
          newCompset.patternId = 4;
          newCompset.color = theme.colors.network['500'];
          break;
        default:
          // ignore individual brand
          if (compset.propertyCount === 1) {
            return acc;
          }
          newCompset.patternId = 2;
          newCompset.subTag = 'Brand';
          newCompset.color = compset.color;
          break;
      }
    } else {
      if (isBrandOrCluster) {
        newCompset.ready = true;
      }

      customCompsetPatternIdCount += 1;
      newCompset.patternId = customCompsetPatternIdCount;

      let nextColor = compset.color ?? '';

      do {
        // eslint-disable-next-line no-loop-func
        if (acc.every((c) => c.compsetTag === compset.id || c.color !== nextColor)) {
          newCompset.color = nextColor;
        } else {
          let newColor = (parseInt(nextColor.replace(/#/, ''), 16) + 1).toString(16);

          if (newColor.length < 6) {
            newColor = `${Array(7 - newColor.length).join('0')}${newColor}`;
          }

          nextColor = `#${newColor}`.toUpperCase();
        }
      } while (!newCompset.color);
    }

    acc.push(newCompset);

    return acc;
  }, []);

  setCompsetsRaw(compsets.sort((a, b) => a.patternId - b.patternId));
};

export default getUserCompsets;
