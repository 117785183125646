<script setup lang="ts">
import { reactive, watch, onMounted } from 'vue';

import { LpSetup } from '@thehotelsnetwork/lampo';
import { RouterView, useRouter } from 'vue-router';

import useMainState from '@/state/useMainState';

import { getUserTypeDefaultRedirect } from '@/utils/getUserTypeDefaultRedirect';

import browserStorage from '@/modules/browserStorage';

const {
  language,
  getCurrentMessages,
  isOverview,
  featureFlags,
} = useMainState();

const router = useRouter();

const lampoLocaleConfig = reactive({
  locale: language.value,
  messages: {} as Record<string, Record<string, string>>,
});

watch(language, async () => {
  lampoLocaleConfig.locale = language.value;
  lampoLocaleConfig.messages[language.value] = getCurrentMessages();
}, { immediate: true });

watch(isOverview, () => {
  if (isOverview.value
    && window.location.pathname.match(/\/property\/settings|preferences|setup/)) { // is not a valid section for overview
    router.push(getUserTypeDefaultRedirect());
  }
});

const beforeUnloadListener = async () => {
  const apcUser = await window.Appcues.user();
  browserStorage.setItem('flow_sequence', Number(apcUser.flow_sequence));
};

onMounted(async () => {
  window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
  if (featureFlags.value.chat) {
    const { useChatBot } = await import('@thehotelsnetwork/vue-compo');
    const { initChatBot } = useChatBot();
    initChatBot();
  }
});
</script>

<template>
  <LpSetup
    :config="lampoLocaleConfig"
  >
    <RouterView v-slot="{ Component }">
      <transition
        name="fade"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </RouterView>
  </LpSetup>
</template>
