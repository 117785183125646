<template>
  <tooltip-base>
    <h1
      v-if="data.title"
      class="mb-1.5 text-base-900 text-base font-bold leading-snug"
      v-html="data.title"
    />
    <p
      v-if="data.text"
      class="text-sm font-light leading-2 text-base-900"
      v-html="data.text"
    />
  </tooltip-base>
</template>
<script>
// Components
import TooltipBase from './TooltipBase.vue';

export default {
  components: {
    TooltipBase,
  },
  props: {
    data: {
      type: Object,
      default: () => (
        {
          text: 'Tooltip Info Text',
        }
      ),
    },
  },
};
</script>
