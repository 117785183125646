/* eslint-disable no-param-reassign */
import {
  capitalize,
} from '@/utils/stringHelpers';

import countriesData from '@/utils/countriesData';

import useSectionState from '@/state/useSectionState';

const { getCompsetData } = useSectionState();

const replaceCompset = {
  destination: 'proximity',
};

const mergeCompsetData = (obj) => {
  const { compset } = obj;

  const compsetData = getCompsetData(replaceCompset[compset] || compset);

  if (compset && !compsetData.compsetTag) {
    return null;
  }

  if (typeof obj.source === 'string') {
    // set country

    if (obj.source.match(/^\w{2}$/)) {
      const matchCountry = countriesData.filter((c) => c.country_code === obj.source)[0];

      if (matchCountry) {
        obj.source = matchCountry.country;
      }
    } else {
      obj.source = capitalize(obj.source);
    }
  }

  return {
    ...obj,
    ...compsetData,
  };
};

export default mergeCompsetData;
