const updateLocalStorage = (key, value) => {
  const bmData = JSON.parse(localStorage.getItem('bmData'));
  // Check if it exists
  if (bmData) {
    bmData[key] = value;

    // Update and store it again
    localStorage.setItem('bmData', JSON.stringify(bmData));
  }
};

export default updateLocalStorage;
