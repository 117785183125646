import protectRoutes from '@/utils/protectRoutes';

const compsetsSwitchImport = () => import('./components/CompsetsSwitch/CompsetsSwitch.vue');

const compsetsRoutes = protectRoutes([
  {
    path: '/property/compsets',
    redirect: '/property/compsets/custom',
  },
  {
    path: '/property',
    children: [
      {
        path: 'compsets',
        name: 'compsets',
        components: {
          default: () => import('./layout/Compsets/Compsets.vue'),
        },
        children: [
          {
            path: 'brand',
            name: 'brand_compset',
            meta: {
              compsetsSwitchDefault: 'brand',
            },
            components: {
              default: () => import('./views/Brand/Brand.vue'),
              navigation: compsetsSwitchImport,
            },
          },
          {
            path: 'proximity',
            name: 'proximity_compset',
            meta: {
              compsetsSwitchDefault: 'proximity',
            },
            components: {
              default: () => import('./views/Proximity/Proximity.vue'),
              navigation: compsetsSwitchImport,
            },
          },
          {
            path: 'proximity/:compsetPropertyId',
            name: 'proximity_map',
            components: {
              default: () => import('./views/Proximity/ProximityMap.vue'),
            },
          },
          {
            path: 'thn',
            name: 'thn_compset',
            meta: {
              compsetsSwitchDefault: 'thn',
            },
            components: {
              default: () => import('./views/Thn/Thn.vue'),
              navigation: compsetsSwitchImport,
            },
          },
          {
            path: 'custom',
            name: 'custom_compset',
            meta: {
              compsetsSwitchDefault: 'custom',
            },
            components: {
              default: () => import('./views/CustomCompsets/CustomCompsets.vue'),
              navigation: compsetsSwitchImport,
            },
          },
          {
            path: 'custom/:type/:id?',
            name: 'edit_custom_compset',
            components: {
              default: () => import('./views/CustomCompsets/EditCustomCompset.vue'),
            },
          },
          {
            path: 'destinations',
            name: 'destinations_compset',
            meta: {
              compsetsSwitchDefault: 'destinations',
            },
            components: {
              default: () => import('./views/Destinations/Destinations.vue'),
              navigation: compsetsSwitchImport,
            },
          },
          {
            path: 'destinations',
            props: true,
            name: 'destinations_compset',
            meta: {
              compsetsSwitchDefault: 'destinations',
            },
            components: {
              default: () => import('@/modules/Destinations/components/DestinationsTable/DestinationsTable.vue'),
              navigation: compsetsSwitchImport,
            },
          },
          {
            path: 'destinations/edit/:id?',
            props: true,
            name: 'destinations_compset_edit',
            components: {
              default: () => import('@/modules/Destinations/components/CreateDestination/CreateDestination.vue'),
            },
          },
        ],
      },
    ],
  },
].map((route) => ({
  ...route,
  component: () => import('@/layout/Benchmark/Benchmark.vue'),
})), {
  requiresPropertyUser: true,
  showHeader: true,
  showSideNav: false,
});

export default compsetsRoutes;
