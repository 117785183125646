import { computed } from 'vue';

import i18n from '@/config/i18n';

const { t } = i18n.global;

/* eslint-disable max-len */
const tpConfigGenerator = (content, type = 'info', preferred = ['right']) => ({
  type,
  content: Object.keys(content).reduce((acc, curr) => {
    acc[curr] = computed(() => t(content[curr]));
    return acc;
  }, {}),
  preferred,
});

/* COMMON SHARED DESCRIPTIONS */
const commonDescriptions = {
  visitors: 'All visits made on the hotel(s) website, regardless of the number of unique visitors.',
  bookings: 'Average number of reservations made during the selected browsing dates, regardless of when those bookings are due',
  searches: 'All searches made on your Booking Engine, regardless of the number of unique visitors',
  conversion: '% of total unique visitors who landed in your website and ended up booking',
  beConversion: '% of those users who made a search on your Booking Engine and ended up booking',
  frequency: '% of times the OTAs\' rate was displayed below your hotel\'s rate. Based on all searches',
};

const commonTpConfig = {
  conversion: tpConfigGenerator({ text: 'Average conversion rate. Takes into account all the unique visitors who landed in your hotel\'s website and ended up booking.' }),
  searchConversion: tpConfigGenerator({ text: 'Average conversion rate from those users who landed in your website to those who made a search on your Booking Engine' }),
  beConversion: tpConfigGenerator({ text: 'Average conversion rate. Takes only into account those users who made a search on your Booking Engine and ended booking a stay at your hotel' }),
  rate: tpConfigGenerator({ text: 'Best Available Rate is the lowest rate of the day available for guests to book.' }),
  frequency: tpConfigGenerator({ text: commonDescriptions.frequency }),
};

const getBoxplotDescription = (key) => `The boxplot graph shows the distribution of your hotel's average ${key} based on quartiles. You can see in which quartile your hotel is under or overperforming your competitors, being your hotel data represented as a vertical line`;
const getOverTimeDescription = (key) => `Track the evolution of the ${key} for the selected period of time, on a daily, weekly or monthly basis`;
const getStayDateDescription = (key) => `Average ${key} for the selected period of time and its distribution on guest's stay dates`;

/* SECTIONS */
export const summaryDescriptions = {
  charts: {
    dbi: tpConfigGenerator({ text: 'DBI™ is a THN developed monthly percentile score that allows you to track the overall performance of your website by weighing algorithmically each section involved in your direct channel strategy. Track your global score overtime.' }),
    conversion: commonTpConfig.conversion,
    searchConversion: commonTpConfig.searchConversion,
    beConversion: commonTpConfig.beConversion,
    bookings: tpConfigGenerator({ text: commonDescriptions.bookings }),
    rate: commonTpConfig.rate,
    frequency: commonTpConfig.frequency,
  },
};

export const conversionDescriptions = {
  metrics: {
    conversion: {
      desc: commonDescriptions.conversion,
      tpConfig: commonTpConfig.conversion,
    },
    beConversion: {
      desc: commonDescriptions.beConversion,
      tpConfig: commonTpConfig.beConversion,
    },
    searchConversion: {
      desc: '% of those users who landed in your website and made a search on your Booking Engine',
      tpConfig: commonTpConfig.searchConversion,
    },
  },
  main: {
    overTime: tpConfigGenerator({ text: getOverTimeDescription('conversion rate') }),
    stayDate: tpConfigGenerator({ text: getStayDateDescription('conversion rate') }),
  },
  charts: {
    boxplot: tpConfigGenerator({ text: getBoxplotDescription('conversion rate') }),
  },
};

const getRevenueOverTimeDescription = (key) => `Track the evolution of your avg. ${key} for the selected period of time, on a daily, weekly or monthly basis`;
const getRevenueStayDateDescription = (key) => `Avg. ${key} for the selected period of time and its distribution on guest's stay dates. Calculation on a daily basis`;

export const revenueDescriptions = {
  metrics: {
    avgBookingValue: {
      desc: 'Average revenue generated per booking',
      tpConfig: tpConfigGenerator({ text: 'Average revenue generated per booking for the selected period of time. Total revenue / total number of bookings.' }),
    },
    revenuePerVisitor: {
      desc: 'Average revenue generated per visitor',
      tpConfig: tpConfigGenerator({ text: 'Average revenue generated per visitor. Total revenue / total number of visitors who landed on your hotel\'s website.' }),
    },
    revenuePerBEVisitor: {
      desc: 'Avg. revenue generated per visitor who made a search on your Booking Engine',
      tpConfig: tpConfigGenerator({ text: 'Average revenue generated per BE visitor. Total revenue / total number of visitors who made a search on your Booking Engine' }),
    },
    adrDailyRate: {
      desc: 'Avg. revenue generated per room night',
      tpConfig: tpConfigGenerator({ text: 'Average revenue generated per room night for the selected period of time. Total revenue / total room nights.' }),
    },
  },
  main: {
    revDirect: tpConfigGenerator({ text: 'Direct channel revenue per available room. Taking into account the total number of rooms within a certain period of time. Total revenue / total available rooms' }),
    overTime: {
      avgBookingValue: tpConfigGenerator({ text: getRevenueOverTimeDescription('revenue per booking') }),
      revenuePerVisitor: tpConfigGenerator({ text: getRevenueOverTimeDescription('revenue per visitor') }),
      revenuePerBEVisitor: tpConfigGenerator({ text: getRevenueOverTimeDescription('revenue per BE visitor') }),
      adrDailyRate: tpConfigGenerator({ text: 'Track the evolution of the average revenue for the selected period of time, on a daily, weekly or monthly basis' }),
    },
    stayDate: {
      avgBookingValue: tpConfigGenerator({ text: getRevenueStayDateDescription('revenue per booking') }),
      revenuePerVisitor: tpConfigGenerator({ text: getRevenueStayDateDescription('revenue per visitor') }),
      revenuePerBEVisitor: tpConfigGenerator({ text: getRevenueStayDateDescription('revenue per BE visitor') }),
      adrDailyRate: tpConfigGenerator({ text: getRevenueStayDateDescription('revenue') }),
    },
  },
  charts: {
    boxplot: {
      avgBookingValue: tpConfigGenerator({ text: getBoxplotDescription('revenue per booking') }),
      revenuePerVisitor: tpConfigGenerator({ text: getBoxplotDescription('revenue per visitor') }),
      revenuePerBEVisitor: tpConfigGenerator({ text: getBoxplotDescription('revenue per BE visitor') }),
      adrDailyRate: tpConfigGenerator({ text: getBoxplotDescription('revenue') }),
    },
  },
};

export const bookingsDescriptions = {
  main: {
    occupancyRate: tpConfigGenerator({ text: 'Average occupancy rate of your hotel based on the total rooms booked on the selected period of time divided by the total available rooms per day, week or month.' }),
    overTime: tpConfigGenerator({ text: getOverTimeDescription('pickup') }),
    stayDate: tpConfigGenerator({ text: getStayDateDescription('number of bookings') }),
  },
  charts: {
    bar: tpConfigGenerator({ text: 'Average number of reservations made during the selected browsing dates, regardless of when those bookings are due.' }),
    boxplot: tpConfigGenerator({ text: getBoxplotDescription('pickup') }),
  },
};

const getDemandBoxPlotDescriptions = (key) => `The boxplot graph shows the distribution of all ${key} on your Booking Engine based on quartiles. You can see in which quartile your hotel is under or overperforming your competitors, being your hotel data represented as a vertical line`;
const getDemandOverTimeDescriptions = (key) => `Track the evolution of ${key} on your Booking Engine for the selected period of time, by day, week or month`;
const getDemandStayDateDescriptions = (key) => `Number of ${key} on the selected period of time and its distribution on guest's stay dates.`;

export const demandDescriptions = {
  metrics: {
    visitors: {
      desc: commonDescriptions.visitors,
      tpConfig: tpConfigGenerator({ text: commonDescriptions.visitors }),
    },
    searches: {
      desc: commonDescriptions.searches,
      tpConfig: tpConfigGenerator({ text: commonDescriptions.searches }),
    },
    visitorsWhoSearch: {
      desc: 'Number of unique visitors who landed in your website and made a search on your Booking Engine, regardless of the number of searches they may have made',
      tpConfig: tpConfigGenerator({ text: 'Unique visitors who landed on your hotel\'s website and made a search in your Booking Engine. Unique visitors meaning those who initiated at least one session during the selected period of time, regardless of the number of searches they may have made' }),
    },
  },
  main: {
    overTime: {
      visitors: tpConfigGenerator({ text: 'Track the evolution of all visits made on the hotel(s) website for the selected period of time, by day, week or month.' }),
      searches: tpConfigGenerator({ text: getDemandOverTimeDescriptions('all searches made') }),
      visitorsWhoSearch: tpConfigGenerator({ text: getDemandOverTimeDescriptions('the number of unique visitors who made a search') }),
    },
    stayDate: {
      searches: tpConfigGenerator({ text: getDemandStayDateDescriptions('searches made') }),
      visitorsWhoSearch: tpConfigGenerator({ text: getDemandStayDateDescriptions('unique visitors who made a search on your Booking Engine') }),
    },
  },
  charts: {
    boxplot: {
      visitors: tpConfigGenerator({ text: 'The boxplot graph shows the distribution of your hotel\'s average visitors based on quartiles. You can see in which quartile your hotel is under or overperforming your competitors, being your hotel data represented as a vertical line' }),
      searches: tpConfigGenerator({ text: getDemandBoxPlotDescriptions('searches made') }),
      visitorsWhoSearch: tpConfigGenerator({ text: getDemandBoxPlotDescriptions('unique visitors that made a search') }),
    },
  },
};

export const bookingWindowDescriptions = {
  metrics: {
    bookingWindow: {
      desc: commonDescriptions.bookings,
      tpConfig: tpConfigGenerator({ text: 'Average days between the date of booking and the check in date' }),
    },
    searchWindow: {
      desc: commonDescriptions.searches,
      tpConfig: tpConfigGenerator({ text: 'bookingWindow.metric.searches' }),
    },
  },
  main: {
    overTime: tpConfigGenerator({ text: 'Track the evolution of the average booking window for the selected period of time, by day, week or month' }),
    stayDate: tpConfigGenerator({ text: getStayDateDescription('booking window') }),
  },
  charts: {
    searchesVsBookings: tpConfigGenerator({
      Bookings: 'Average days between the date of booking and the check in date',
      Searches: 'Average days between the date the search is made in your BE and the actual check in date',
    }, 'desc'),
    curves: {
      bookingWindow: tpConfigGenerator({ text: 'This graph shows the percentage of bookings with their timelapse from when the booking was made until the actual stay date of those bookings' }),
      searchWindow: tpConfigGenerator({ text: 'This graph shows the percentage of searches with their timelapse from when the search was made on the Booking Engine until the actual stay date of those searches' }),
    },
    boxplot: tpConfigGenerator({ text: getBoxplotDescription('booking window') }),
  },
  tables: {
    curves: {
      bookingWindow: tpConfigGenerator({ text: 'This table shows the percentage of bookings with their timelapse from when the booking was made until the actual stay date of those bookings' }),
      searchWindow: tpConfigGenerator({ text: 'This table shows the percentage of searches with their timelapse from when the search was made on the Booking Engine until the actual stay date of those searches' }),
    },
  },
};

export const lengthOfStayDescriptions = {
  metrics: {
    bookingStay: {
      desc: commonDescriptions.bookings,
      tpConfig: tpConfigGenerator({ text: 'Duration of guest\'s stay at your hotel. Average amount of nights a booking was made for' }),
    },
    searchStay: {
      desc: commonDescriptions.searches,
      tpConfig: tpConfigGenerator({ text: 'Duration of guest\'s stay at your hotel. Average amount of nights a search was made for' }),
    },
  },
  main: {
    overTime: tpConfigGenerator({ text: getOverTimeDescription('length of stay') }),
    stayDate: tpConfigGenerator({ text: getStayDateDescription('length of stay') }),
  },
  charts: {
    searchesVsBookings: tpConfigGenerator({
      Bookings: 'Average number of nights a booking was made for',
      Searches: 'Average number of nights a search was made for',
    }, 'desc'),
    curves: {
      bookingStay: tpConfigGenerator({ text: 'This graph shows the percentage of bookings made on the selected period of time with their stay duration.' }),
      searchStay: tpConfigGenerator({ text: 'This graph shows the percentage of searches made on the selected period of time with their potential stay duration.' }),
    },
    boxplot: tpConfigGenerator({ text: getBoxplotDescription('length of stay') }),
  },
  tables: {
    curves: {
      bookingStay: tpConfigGenerator({ text: 'This table shows the percentage of bookings made on the selected period of time with their stay duration.' }),
      searchStay: tpConfigGenerator({ text: 'This table shows the percentage of searches made on the selected period of time with their potential stay duration.' }),
    },
  },
};

export const ratesDescriptions = {
  main: {
    rate: tpConfigGenerator({ text: 'Best Available Rate is the average lowest room rate available for the direct channel at the time of search.' }),
    overTime: tpConfigGenerator({ text: getOverTimeDescription('average Best Available Rates') }),
    stayDate: tpConfigGenerator({ text: getStayDateDescription('Best Available Rate') }),
  },
  charts: {
    boxplot: tpConfigGenerator({ text: getBoxplotDescription('Best Available Rate') }),
  },
};

export const disparitiesDescriptions = {
  metrics: {
    frequency: {
      desc: '% of times the OTAs\' rate was displayed below your hotel\'s rate. Based on all searches',
    },
    amount: {
      desc: '% of price difference between your hotel\'s rate and the OTAs rate. Based on all searches',
    },
  },
  main: {
    overTime: tpConfigGenerator({ text: getOverTimeDescription('disparity rate') }),
    stayDate: tpConfigGenerator({ text: getStayDateDescription('disparity rate') }),
  },
  charts: {
    amountAndFrequency: tpConfigGenerator({
      Frequency: 'Average percentage of times the selected OTAs\' rate was displayed below your hotel\'s rate.',
      Amount: 'Average percentage of price difference between your hotel rate and the one displayed by the selected OTAs for searches in the selected period of time.',
    }, 'desc'),
    otaFrequency: tpConfigGenerator({ text: 'This graph shows the disparity frequency of each OTA in detail' }),
    otaAmount: tpConfigGenerator({ text: 'This graph shows the disparity amount of each OTA in detail' }),
    boxplot: tpConfigGenerator({ text: getBoxplotDescription('percentage of disparities') }),
  },
};

export const visitorProfileDescriptions = {
  metrics: {
    visitors: {
      desc: 'All users that landed on your hotel\'s website',
    },
    visitorsWhoSearch: {
      desc: 'Users who landed on your hotel\'s website and made a search on your Booking Engine',
    },
    bookings: {
      desc: commonDescriptions.bookings,
    },
    conversion: {
      desc: commonDescriptions.conversion,
    },
    beConversion: {
      desc: commonDescriptions.beConversion,
    },
  },
  charts: {
    conversionDevice: tpConfigGenerator({ text: 'This graph shows the percentage conversion depending on the type of technology the user used to interact with your hotel\'s website. Compare your performance to the selected competitive set.' }),
    device: tpConfigGenerator({ text: 'This graph shows the percentage distribution depending on the type of technology the user used to interact with your hotel\'s website, each portrayed in different shades of color. Desktop (D), Tablet (T) and Mobile (M).' }),
    country: tpConfigGenerator({ text: 'This graph shows the percentage distribution by each relevant country for the selected period of time. Based on the user\'s country detected by the IP adress of their interaction with your hotel\'s website. Compare your performance to the selected competitive set.' }),
    source: tpConfigGenerator({ text: 'This graph shows the percentage distribution by source, meaning the previous site the user visited before landing on your hotel\'s website. Compare your performance to the selected competitive set.' }),
  },
};

export const builderDescriptions = {
  default: {
    desc: 'Cross-filter your direct channel performance to see how different metrics impact one another. View a series of new default graphs or mix and match metrics to create your own!',
  },
};
