import { createI18n } from 'vue-i18n';

import 'moment/dist/locale/es';
import 'moment/dist/locale/it';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/de';
import 'moment/dist/locale/th';
import 'moment/dist/locale/sv';

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
});

export default i18n;
