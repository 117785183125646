import { computed, unref } from 'vue';
import moment from 'moment';

import {
  disablePastNumDays,
  disableFutureDates,
} from '@/utils/disabledCalendarFn';

import {
  onCTPSelect,
} from '@/utils/ctpDatesUtils';

import {
  onStayDatesSelect,
} from '@/utils/stayDatesUtils';

import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';
import getDateRange from '@/utils/getDateRange';

const {
  currentPermissions,
} = useSectionState();

const {
  setDates,
  setSelectedTag,
  sdTag,
  ctpTag,
  unsetDates,
  vdTag,
  summVdTag,
  ctpDateBegin,
  ctpDateEnd,
  getCtpDateBegin,
  getCtpDateEnd,
} = useFilterState();

// VISIT DATES
export const minVisitDatesMonth = computed(() => {
  if (!currentPermissions.value.dates) {
    return moment().subtract(60, 'days').startOf('month').unix();
  }
  return null;
});

export const maxVisitDatesMonth = moment().startOf('month').unix();

// When selecting Visit Dates (custom or predefined option)
export const onVisitDatesSelect = ({
  db,
  de,
  tag,
  label,
  userInput = true,
}) => {
  // Early exit
  if (!tag) {
    return;
  }

  let dateTag = tag;
  if (tag === 'custom' && (!db || !de)) {
    dateTag = 'last30';
  }

  let tmpDateBegin = db;
  if (dateTag === 'custom') {
    setDates({
      type: 'vd',
      db,
      de,
      isTimestamp: true,
      userInput,
      label,
    });
  } else {
    const days = dateTag.match(/\d+/)[0];

    if (days) {
      setDates({
        type: 'vd',
        db: moment().startOf('day').subtract(days, 'days').unix(),
        de: moment().subtract(days === '1' ? 1 : 0, 'days').unix(),
        isTimestamp: true,
        userInput,
        label,
      });
      tmpDateBegin = moment().startOf('day').subtract(days, 'days').unix();
    }
  }
  setSelectedTag('vd', dateTag);

  // Update Stay Dates if sdTag is valid
  [
    {
      currentDateTag: unref(sdTag),
      name: 'sd',
    },
    {
      currentDateTag: unref(ctpTag),
      name: 'ctp',
    },
  ].forEach(({ currentDateTag, name }) => {
    if (currentDateTag) {
      if (name === 'sd') {
        if (currentDateTag === 'custom') {
          // clear custom stay dates
          unsetDates(name);
          setSelectedTag(name, '');
        } else {
          // Get number of days from Stay Dates tag
          const days = currentDateTag.match(/\d+/)[0];
          if (days) {
            onStayDatesSelect({
              dateBegin: tmpDateBegin,
              dateEnd: moment.unix(tmpDateBegin).add(days, 'days').startOf('day').unix(),
              tag: currentDateTag,
            });
          }
        }
      } else if (name === 'ctp') {
        onCTPSelect({
          tag: currentDateTag,
        });
      }
    }
  });
};
const yesterdayDateObj = moment().subtract(1, 'days').unix();
const getDatesOptionsCommonConfig = () => [
  {
    label: 'Yesterday',
    sublabel: getDateRange(yesterdayDateObj, yesterdayDateObj),
    tag: 'last1',
  },
  {
    label: 'Last 7 days',
    sublabel: getDateRange(moment().subtract(7, 'days').unix(), moment().unix()),
    tag: 'last7',
  },
  {
    label: 'Last 30 days',
    sublabel: getDateRange(moment().subtract(30, 'days').unix(), moment().unix()),
    tag: 'last30',
  },
];

export const visitDatesConfig = computed(() => {
  // Define its config and isDisabled function
  const isVisitDateDisabled = (date) => {
    const disabledFunctions = [
      disableFutureDates,
    ];

    // If user is not premium add function to disable past the last 60 days
    if (!currentPermissions.value.dates) {
      disabledFunctions.push((item) => disablePastNumDays({ days: 60, date: item.date }));
    }

    return disabledFunctions.some((fn) => {
      if (typeof fn === 'function') {
        return fn({ date });
      } return false;
    });
  };

  const visitDatesOptions = [
    ...getDatesOptionsCommonConfig(),
    {
      label: 'Last 60 days',
      sublabel: getDateRange(moment().subtract(60, 'days').unix(), moment().unix()),
      tag: 'last60',
    },
    {
      label: 'Last 90 days',
      sublabel: getDateRange(moment().subtract(90, 'days').unix(), moment().unix()),
      tag: 'last90',
      premium: !currentPermissions.value.dates,
    },
    {
      label: 'Last year',
      sublabel: getDateRange(moment().subtract(1, 'year').unix(), moment().unix()),
      tag: 'last365',
      premium: !currentPermissions.value.dates,
    },
  ];

  const visitDatesCalendarConfig = {
    datesAlign: 'right',
    isDisabled: isVisitDateDisabled,
  };

  return {
    calendarConfig: visitDatesCalendarConfig,
    clearButton: false, // Clear Selection button
    defaultOptions: visitDatesOptions,
    defaultLabel: 'Visit Dates',
    tagSelected: vdTag.value,
    onDatesSelect: onVisitDatesSelect,
  };
});

export const onSummaryVisitDatesSelect = ({
  tag,
  label,
  userInput = true,
}) => {
  // Early exit
  if (!tag) {
    return;
  }

  const days = tag.match(/\d+/)[0];

  if (days) {
    setDates({
      type: 'summVd',
      db: moment().startOf('day').subtract(days, 'days').unix(),
      de: moment().subtract(days === '1' ? 1 : 0, 'days').unix(),
      isTimestamp: true,
      userInput,
      label,
    });
  }

  setSelectedTag('summVd', tag);

  ctpDateBegin.value = getCtpDateBegin('pp').unix();
  ctpDateEnd.value = getCtpDateEnd('pp').unix();
};

export const summaryVisitDatesConfig = computed(() => {
  const visitDatesOptions = [
    ...getDatesOptionsCommonConfig(),
  ];

  return {
    customDateRangeDisabled: true,
    clearButton: false, // Clear Selection button
    defaultOptions: visitDatesOptions,
    defaultLabel: 'Visit Dates',
    tagSelected: summVdTag.value,
    side: 'right',
    onDatesSelect: onSummaryVisitDatesSelect,
  };
});
