import { computed, ref, watch } from 'vue';

import i18n from '@/config/i18n';

import sourceData from '@/utils/sourceOptions';
import hotelTypeOptions from '@/config/hotelTypeOptions';

import { getPipeData, geolocationVisitsPipe } from '@/state/sectionStateConfig';

import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';
import useUserState from '@/state/useUserState';

import { categoryOptions } from '@/config/ratingConfig';

const {
  compsets,
  sectionMetrics,
  isSummarySection,
  sectionName,
  getSectionData,
  compsetsFilter,
  shouldShowCompsetInFilter,
  isBreakdownOverviewSection,
} = useSectionState();

const {
  propertyId,
} = useMainState();

const {
  otas,
  selectedMetric,
  travelParty,
  setTravelParty,
} = useFilterState();

const { isStrictlyDMOUser } = useUserState();

export const sectionFiltersConfig = computed(() => {
  const sectionDefault = !isSummarySection.value;

  const baseFilters = {
    active: sectionDefault,
    visitDates: sectionDefault,
    stayDates: sectionDefault,
    ctpDates: sectionDefault,
    geolocations: sectionDefault,
    devices: sectionDefault,
    sources: sectionDefault,
    travelParty: sectionDefault,
    hotelType: sectionDefault,
    hotelCategory: sectionDefault,
    otas: false,
    compsets: !isBreakdownOverviewSection.value && sectionDefault,
  };

  if (sectionName.value === 'disparities') {
    baseFilters.otas = true;
  }

  if (sectionName.value === 'visitors'
    && selectedMetric.value === 'visitors') {
    baseFilters.travelParty = false;
  }

  const disabledMetricFilters = sectionMetrics.value
    .filter(({ name }) => name === selectedMetric.value)[0]?.disabledFilters;

  if (disabledMetricFilters) {
    disabledMetricFilters.forEach((filter) => {
      baseFilters[filter] = false;
    });
  }

  return baseFilters;
});

const { t } = i18n.global;

export const currentGeolocationOption = ref(isStrictlyDMOUser.value ? 'all' : 'country');

// geolocations
export const geolocationsConfig = computed(() => {
  const propertyData = getSectionData(propertyId.value);

  let options = [];

  const { values } = propertyData.geolocationVisits;

  // merge and sort geolocation data
  if (!geolocationVisitsPipe.loading && Object.keys(values).length) {
    options = values[currentGeolocationOption.value]
      .sort((a, b) => b.itemCount - a.itemCount);
  }

  return {
    options,
    placeholder: t('Geolocation'),
    searchable: true,
    searchPlaceholder: t('Search a location...'),
    loadingText: t('Loading locations...'),
  };
});

// DEVICES
export const devicesConfig = computed(() => ({
  options: [
    {
      label: t('Mobile'),
      value: 'mobile',
    },
    {
      label: t('Tablet'),
      value: 'tablet',
    },
    {
      label: t('Desktop'),
      value: 'desktop',
    },
  ],
  placeholder: t('Device'),
}));

// TRAVEL PARTY
const travelPartyData = computed(() => ({
  options: [
    {
      label: t('Solo'),
      value: 'solo',
    },
    {
      label: t('Couple'),
      value: 'couple',
    },
    {
      label: t('Family'),
      value: 'family',
    },
    {
      label: t('Group'),
      value: 'group',
    },
  ],
  placeholder: t('Travel party'),
}));

// HOTEL TYPE
export const hotelTypeConfig = computed(() => ({
  options: hotelTypeOptions.value,
  placeholder: t('Hotel Type'),
}));

// HOTEL RATING
export const hotelCategoryConfig = computed(() => ({
  options: categoryOptions.value,
  placeholder: t('Category (stars)'),
}));

// SOURCE
export const sourceConfig = computed(() => ({
  options: sourceData.map((source) => ({ label: t(source.label), value: source.value })),
  placeholder: t('Source'),
}));

// COMPSETS
export const currentCompsetsOption = ref('default');

watch(propertyId, () => { currentCompsetsOption.value = 'default'; });

watch(isStrictlyDMOUser, () => { if (isStrictlyDMOUser.value) currentCompsetsOption.value = 'destination'; });

export const compsetsFilterConfig = computed(() => {
  if (compsetsFilter.value.length) {
    const placeholder = isStrictlyDMOUser.value ? 'Destinations' : 'Compsets';

    return {
      options: compsets.value
        .filter((c) => currentCompsetsOption.value.match(new RegExp(c.type, 'i')))
        .filter(shouldShowCompsetInFilter)
        .map((c) => ({
          label: c.compset,
          value: c.compsetTag,
          color: c.color,
          ready: c.ready,
          type: c.type,
          status: c.status,
        })),
      footer: { clearBtn: false, link: { text: 'Learn more', url: '/property/compsets' } },
      placeholder: t(placeholder),
      searchPlaceholder: t('Search...'),
    };
  }
  return null;
});

export const selectedMetricConfig = computed(() => ({
  arrowSrc: 'img/icons/common/chevron-green-down.svg',
  data: sectionMetrics.value
    .map((m) => ({ label: m.placeholder, sublabel: m.desc, tag: m.name })),
  classes: {
    minWidth: 'min-w-dropdown-md',
  },
  labelKey: 'label',
  sublabelKey: 'sublabel',
  placeholder: (sectionMetrics.value
    .filter((m) => m.name === selectedMetric.value)[0] || {}).placeholder,
  valueKey: 'tag',
  returnString: true,
  footer: { show: false },
  fullHeight: true,
}));

export const otasConfig = computed(() => {
  const otasPipe = getPipeData('otas');
  let options = [];

  if (!otasPipe.loading) {
    options = otas.value.map((ota) => ({ label: ota, value: ota }));
  }

  return {
    options,
    loading: otasPipe.loading,
    placeholder: 'OTAs',
    searchable: true,
  };
});

export const travelPartyConfig = computed(() => {
  if (!sectionFiltersConfig.value.travelParty && travelParty.value.length) {
    setTravelParty([]);
  }
  return travelPartyData.value;
});
