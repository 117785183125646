import {
  capitalize,
} from '@/utils/stringHelpers';
import mergeDataValues from '@/utils/mergeDataValues';
import mergeOverTimeDataValues from '@/utils/mergeOverTimeDataValues';

import {
  getPipeData,
} from '@/state';

import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';

const {
  isValidDataId,
  isBreakdownOverviewSection,
  getSectionData,
} = useSectionState();

const {
  propertyId,
} = useMainState();

const updateSectionData = ({
  pipe,
  pid,
  newData,
  currentDataId,
  isCtpCall,
  isCalendarCall,
  interval,
  isStayDates,
  meta = {},
}) => {
  const shouldProceed = isValidDataId(currentDataId) // matching data ID
  && (isBreakdownOverviewSection.value // free pass for breakdown section (multiple property data)
    || pid === propertyId.value); // else check matching selected property

  const { mixConfig } = getPipeData(pipe);

  // pipe should mix metrics into one?
  if (mixConfig) {
    // eslint-disable-next-line no-param-reassign
    newData[mixConfig.mixInto] = mixConfig.metrics.reduce((acc, curr) => {
      newData[curr].forEach((v) => {
        acc.push({
          ...v,
          metric: curr,
        });
      });

      return acc;
    }, []);
  }

  if (shouldProceed) {
    // iterate over data keys
    Object.keys(newData).forEach((key) => {
      let existingDataKey = key;
      let mergeFn = mergeDataValues;

      // assign over-time interval keys (Month/Week/Date)
      if (interval) {
        mergeFn = mergeOverTimeDataValues;
        if (isStayDates) {
          // stay dates prefix (conversionSdMonth)
          existingDataKey += 'Sd';
        }
        existingDataKey += capitalize(interval);
      }

      if (isCalendarCall) {
        existingDataKey += 'Cal';
      }

      // get existing section data according to property
      const existingData = getSectionData(pid);
      // get existing metric data from inside
      const existingMetricData = existingData[existingDataKey];

      if (!existingMetricData) {
        return;
      }

      let tmpData = newData[key];

      // merge CTP data
      if (isCtpCall) {
        tmpData = mergeFn(
          [...existingMetricData.values],
          tmpData,
        );
      }

      // save new data
      existingMetricData.values = tmpData;
      existingMetricData.meta = meta;
    });
  }
};

export default updateSectionData;
