import {
  axis,
  text,
  view,
  tooltip,
  colorField,
  getValueDisplayLayer,
  getFixedBarValues,
} from '@/config/charts';

const getFunnelChartSpec = ({
  data,
  dataFormat,
  size = 'md',
  legendValues,
}) => {
  let step;
  let barSize;

  switch (size) {
    case 'sm':
    case 'md':
    default:
      step = 40;
      barSize = 24;
      break;
  }

  const barMark = {
    type: 'bar',
    cornerRadius: 2,
    tooltip,
  };

  const barEncoding = {
    color: colorField,
    x: {
      field: 'valueBar',
      type: 'quantitative',
      axis: false,
    },
  };

  const highest = Math.max(
    ...data.map((i) => (i.pastValue && i.pastValue > i.value && i.pastValue) || i.value || 0),
  );

  const valuesLayer = getValueDisplayLayer('left', dataFormat, highest);

  return {
    data: {
      values: data,
    },
    transform: [
      ...getFixedBarValues(highest),
    ],
    // Config
    width: 'container',
    autosize: { type: 'fit' },
    height: { step },
    spacing: 0,
    padding: 0,
    config: {
      view,
      axis: {
        ...axis,
        domain: false,
        labelPadding: 150,
        labelLimit: 150,
        labelAlign: 'left',
      },
      text: { ...text, tooltip },
      scale: {
        barBandPaddingInner: 0.2,
        bandPaddingOuter: 0,
      },
    },
    encoding: {
      y: {
        sort: legendValues,
        field: 'compset',
        type: 'nominal',
      },
      x: {
        scale: {
          nice: false,
          domain: [0, highest + ((highest * 50) / 100)],
        },
      },
    },

    // Visit dates
    layer: [
      { // Bars
        mark: {
          ...barMark,
          size: barSize,
        },
        encoding: barEncoding,
      },
      { // Value labels
        encoding: {
          x: {
            field: 'valueBar',
            type: 'quantitative',
          },
        },
        layer: valuesLayer,
      },
    ],
  };
};

export default getFunnelChartSpec;
