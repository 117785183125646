import { CompsetStatusConfig, CompsetAvailableStatusNames } from '@/declarations/customCompsets';

const customCompsetStatus: Record<CompsetAvailableStatusNames, CompsetStatusConfig> = {
  draft: {
    text: 'Draft',
    color: 'gray',
    tooltipText: 'cc.tooltip.draft',
  },
  rates_only: {
    text: 'Rates-only',
    color: 'warning',
    tooltipText: 'cc.tooltip.ratesonly',
  },
  active: {
    text: 'Active',
    color: 'success',
  },
};

export default customCompsetStatus;
