import navigate from '@/utils/navigate';
import clearLocalStorage from '@/utils/clearLocalStorage';
import env from '@/config/env';

const logout = () => {
  clearLocalStorage();
  localStorage.removeItem('thnStorage');
  navigate(`${env.VUE_APP_NAKAMA_URL}/logout`, { external: true });
};

export default logout;
