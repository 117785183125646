import apiHelper from './apiHelper';

const getUserCharts = async () => {
  const { data }: any = await apiHelper(
    'GET',
    '/user/charts',
  );

  return data.data;
};

export default getUserCharts;
