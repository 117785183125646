import moment from 'moment';

const formatDate = (inputDate, interval) => {
  if (!inputDate) {
    return null;
  }

  const date = moment(inputDate);

  const dateFormat = 'MMM D, YYYY';

  switch (interval) {
    case 'month': return date.format('MMM YYYY');
    case 'week': return `${date.startOf('isoweek').format('MMM D')} - ${date.endOf('isoweek').format(dateFormat)}`;
    case 'date': return date.format(dateFormat);
    default: return inputDate;
  }
};

export default formatDate;
