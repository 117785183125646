/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import { ref, computed } from 'vue';

import apiClient from '@/modules/apiClient';

import bdLogger from '@/utils/bdLogger';
import updateLocalStorage from '@/utils/updateLocalStorage';

import isNewLogin from '@/utils/isNewLogin';
import setCompsetsUpdatedFlag from '@/utils/setCompsetsUpdatedFlag';

import useDebugState from '@/state/useDebugState';
import useUserState from '@/state/useUserState';

import { dataDogAction } from '@/utils/dataDog';

const { disableRequestCaching } = useDebugState();
const { userData } = useUserState();

const cache = ref(null);
const initialized = ref(false);
const usedCacheSize = ref(0);
const isCacheReady = computed(() => !disableRequestCaching.value
  && initialized.value
  && cache.value);
// updated value in MB
const usedCacheMbs = computed(() => Number((usedCacheSize.value / 1e6).toFixed(2).replace(/0$/, '')));

const getUniqueUrlId = (url) => url + userData.id;

// calculate the size in bytes of a network response
const getResponseSize = async (res) => {
  const reader = res
    .clone()
    .body
    .getReader();

  const { value = [] } = await reader.read();

  return value.length;
};

// clear cache
const clearCacheStorage = async () => {
  if (!cache.value) {
    return;
  }

  const keys = await cache.value.keys();
  keys.forEach((key) => cache.value.delete(key));

  usedCacheSize.value = 0;

  bdLogger.log(`[cache] Cleared ${keys.length} cache entries`);
};

// get stored cache size
const calcStoredCacheSize = async () => {
  const keys = await cache.value.keys();
  let totalSize = 0;

  for (const key of keys) {
    const res = await cache.value.match(key);
    const responseSize = await getResponseSize(res);

    totalSize += responseSize;
  }

  return totalSize;
};

const checkCacheValidity = async (dataCacheDate) => {
  const currentDate = new Date();
  const cacheDate = new Date(dataCacheDate);
  const isDifferentDate = currentDate.getUTCDate() > cacheDate.getUTCDate();
  // expired status
  const expired = isDifferentDate // is a new day
        && cacheDate.getUTCHours() > 1; // new data was dumped

  if ((isNewLogin() || expired) && usedCacheSize.value > 0) {
    bdLogger.log('[cache] Clearing expired data...');

    await clearCacheStorage();
    // update cache date
    updateLocalStorage('dataCacheDate', currentDate);
  }
};

const initRequestCache = async () => {
  bdLogger.log('[cache] Initializing...');

  try {
    cache.value = await caches.open('benchdirect-data');
    const cacheSize = await calcStoredCacheSize();
    usedCacheSize.value = cacheSize;

    if (disableRequestCaching.value) {
      bdLogger.warn('[cache] Force disable detected, data requests won\'t be saved');
    }

    const { dataCacheDate } = JSON.parse(localStorage.getItem('bmData'));

    await checkCacheValidity(dataCacheDate);

    bdLogger.log(`[cache] In use: ${usedCacheMbs.value}Mb`);
  } catch (err) {
    bdLogger.warn(`[cache] Error initializing: ${err.message}`);
  }

  initialized.value = true;
};

// cache fetch middleware
const useFetchCache = async (options) => {
  let existingRes;
  let res;

  if (JSON.parse(localStorage.getItem('bmData')).compsetsUpdated) {
    await clearCacheStorage();
    setCompsetsUpdatedFlag(false);
  }

  // check if caching is not disabled
  if (isCacheReady.value) {
    // try to get existing item
    existingRes = await cache.value.match(getUniqueUrlId(options.url));
  }

  if (existingRes) {
    res = {
      status: existingRes.status,
      data: await existingRes.json(),
    };
    bdLogger.log(`[cache] Response match for URL: ${options.url}, skipping network call`);

    dataDogAction('cacheDataRetrieved');
  } else {
    // fetch new data
    res = await apiClient.request(options);

    if (isCacheReady.value // cache was correctly initialized
      && !disableRequestCaching.value // is not force disabled
      && `${res.status}`.match(/^2/)) { // valid response
      const newResponse = new Response(new Blob([JSON.stringify(res.data, null, 2)], {
        type: 'application/json',
      }), res);
      const responseSize = await getResponseSize(newResponse);

      usedCacheSize.value += responseSize;

      cache.value.put(getUniqueUrlId(options.url), newResponse);

      bdLogger.log(`[cache] Response cached successfully for URL: ${options.url}`);
    }
  }

  return res;
};

export default function useRequestCache() {
  return {
    cache,
    initialized,
    usedCacheSize,
    usedCacheMbs,
    initRequestCache,
    clearCacheStorage,
    useFetchCache,
  };
}
