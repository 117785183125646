import { ref } from 'vue';

import moment from 'moment';

const logHistory = ref([]);

const setLog = (text) => {
  logHistory.value.push(`-- [${moment().format('HH:mm:ss')}] --
${text}
`);
};

export default function useLogState() {
  return {
    logHistory,
    setLog,
  };
}
