import { HistoryUser } from '@/declarations/main';
import apiHelper from '@/utils/apiHelper';

const getHistoryUser = async (id: string) => {
  try {
    const { data }: any = await apiHelper(
      'GET',
      `/user-history/user/${id}`,
      undefined,
      { useGateway: true },
    );
    return data;
  } catch (err: any) {
    if (!(err.message).match(/(404)/)) {
      throw new Error('Error getting history user.');
    }
    return {};
  }
};

export default getHistoryUser;
