import { watch } from 'vue';

import moment from 'moment';

import { checkAppcuesFlow } from './appcuesFlows';

import auth from '@/modules/Auth';

import router from '@/router';

import { track, initAnalytics } from '@/utils/analytics';

import getKeyFromObject from '@/utils/getKeyFromObject';
import sectionExceptions from '@/utils/sectionExceptions';

import clearLocalStorage from '@/utils/clearLocalStorage';
import bdLogger from '@/utils/bdLogger';
import getBrowserLanguage from '@/utils/getBrowserLanguage';

import useAppState from '@/state/useAppState';
import useMainState from '@/state/useMainState';
import useUserState from '@/state/useUserState';
import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';

import storageVersion from '@/config/storageVersion';
import { sectionFiltersConfig } from '@/config/sectionFiltersConfig';

import { onSummaryVisitDatesSelect, onVisitDatesSelect } from '@/utils/visitDatesUtils';

const {
  setDates,
  setSelectedTag,
  setDevices,
  setGeolocations,
  setTravelParty,
  setHotelType,
  setHotelCategory,
  setSource,
  setFilteredOtas,
  setOpportunitiesMetric,
  setFiltersQuery,
  setApplyCtpStayDates,
} = useFilterState();

const {
  setProperty,
  userProperties,
  setLanguage,
  setUserProperties,
  setCsrfToken,
  isOverview,
  minPropertiesValid,
} = useMainState();

const {
  isPropertySummarySection,
  sectionName,
  setSectionView,
  setOverviewTableSection,
  setIsInternalUser,
} = useSectionState();

const {
  userData,
  setUser,
} = useUserState();

const { getAppData } = useAppState();

const setLang = (lang) => setLanguage(
  userData.language
    || lang
    || (getBrowserLanguage().match(/[a-z]{2}/) || ['en']).pop(),
);

export const updateAppConfig = async () => {
  // Sync App state
  const bmData = JSON.parse(localStorage.getItem('bmData'));

  // Update language
  await setLang(bmData.language);

  const routePid = parseInt(router.currentRoute.value.params.propertyId, 10) || 0;
  // check that "overview" is not selected if the user has only one valid property
  if (routePid === 0 && userProperties.value.length === 1) {
    const validProperty = userProperties.value[0].propertyId;
    bdLogger.warn(`Single valid property (${validProperty}) - Overview is disabled`);
    // only one property
    setProperty(validProperty);
  } else {
    setProperty(routePid);
  }

  const currentQuery = router.currentRoute.value.query || {};

  // apply saved view modes
  if (currentQuery.sectionView) {
    setSectionView(currentQuery.sectionView);
  }
  setOpportunitiesMetric(bmData.opportunitiesMetric);
  setOverviewTableSection(bmData.breakdownMode);

  // apply toggle CTP/Stay dates
  setApplyCtpStayDates(bmData.applyCtpStayDates);

  // apply saved filters
  if (sectionFiltersConfig.value.active) {
    if (sectionFiltersConfig.value.devices) {
      // Update devices
      if (currentQuery.devices) {
        setDevices(currentQuery.devices.split(','));
      }
    }

    if (sectionFiltersConfig.value.geolocations) {
      // Update geolocations
      if (currentQuery.geolocations) {
        setGeolocations(currentQuery.geolocations.split(','));
      }
    }

    if (sectionFiltersConfig.value.hotelType) {
      // Update Hotel Type
      if (currentQuery.hotelType) {
        setHotelType(currentQuery.hotelType.split(','));
      }
    }

    if (sectionFiltersConfig.value.hotelCategory) {
      // Update Hotel Category
      if (currentQuery.hotelCategory) {
        setHotelCategory(currentQuery.hotelCategory.split(','));
      }
    }

    if (sectionFiltersConfig.value.sources) {
      // Update Source
      if (currentQuery.source) {
        setSource(currentQuery.source.split(','));
      }
    }

    if (sectionFiltersConfig.value.otas) {
      // Update otas
      if (currentQuery.otas) {
        setFilteredOtas(currentQuery.otas.split(','));
      }
    }

    if (sectionFiltersConfig.value.travelParty) {
      // Update Travel Party
      if (currentQuery.travelParty) {
        setTravelParty(currentQuery.travelParty.split(','));
      }
    }

    if (sectionFiltersConfig.value.visitDates) {
      // Update Visit Dates (begin/end)
      onVisitDatesSelect({
        tag: bmData.vdTag,
        db: currentQuery.dateBegin ? moment(currentQuery.dateBegin).format('X') : null,
        de: currentQuery.dateEnd ? moment(currentQuery.dateEnd).format('X') : null,
        userInput: !!currentQuery.dateBegin,
      });
    }

    if (sectionFiltersConfig.value.ctpDates
      && currentQuery.ctpDateBegin
      && currentQuery.ctpDateEnd) {
      // Update CTP Dates
      setDates({
        type: 'ctp',
        db: moment(currentQuery.ctpDateBegin).format('X'),
        de: moment(currentQuery.ctpDateEnd).format('X'),
        isTimestamp: true,
      });
      setSelectedTag('ctp', bmData.ctpTag || '');
    }

    if (sectionFiltersConfig.value.stayDates
      && currentQuery.sdDateBegin
      && currentQuery.sdDateEnd) {
      // Update Stay Dates
      setDates({
        type: 'sd',
        db: moment(currentQuery.sdDateBegin).format('X'),
        de: moment(currentQuery.sdDateEnd).format('X'),
        isTimestamp: true,
      });
      setSelectedTag('sd', bmData.sdTag || '');
    }
  }

  if (isPropertySummarySection.value) {
    onSummaryVisitDatesSelect({
      tag: bmData.summVdTag,
      userInput: false,
    });
  }

  checkAppcuesFlow();
};

export const setupInitialAppConfig = async () => {
  const appData = getAppData();

  // set current value (can be modified later using the toolbar)
  setIsInternalUser(appData.isInternalUser);

  // eslint-disable-next-line camelcase
  const user = appData.user || {};
  const properties = appData.properties || [];

  setCsrfToken(appData.csrftoken);
  setUser(user);
  setUserProperties(properties);

  // If after setting all the user properties we are is the Overview section (due the fact
  // that we had no property selected before, for example) and there's no valid property
  // to display its data, select the first one from the list so its countdown will show
  if (isOverview.value && !minPropertiesValid.value && properties.length) {
    setProperty(properties.shift().propertyId);
  }

  // try to retrieve data from storage
  let bmData = JSON.parse(localStorage.getItem('bmData'));

  // data doesn't exist
  if (!bmData) {
    // Create default config

    /*
      IMPORTANT: when adding or editing the variables used in the storage,
      make sure to also update the "storageVersion" if a force reset is needed.
      (Users may still be logged and won't receive the new changes)
    */
    bmData = {
      userId: userData.id,
      ctpTag: '',
      vdTag: 'last30',
      summVdTag: 'last30',
      selectedMetric: '',
      breakdownMode: 'overview',
      language: '',
      version: storageVersion,
      dataCacheDate: new Date(),
      filtersQuery: {},
    };

    // save new config
    localStorage.setItem('bmData', JSON.stringify(bmData));
  } else if (bmData.version !== storageVersion // different storage version
  || (!auth.isImpersonatedUser() && bmData.userId !== userData.id)) { // different user
    // clear existing data and start over
    clearLocalStorage();
    setupInitialAppConfig();
    return;
  }

  setFiltersQuery(bmData.filtersQuery);

  await setLang(bmData.language);

  initAnalytics();

  watch(sectionName, () => {
    let evt = '';

    const exceptions = getKeyFromObject(sectionExceptions, sectionName.value);

    if (!exceptions.hidePropertiesDropdown) {
      const selection = isOverview.value ? 'overview' : 'property';
      evt += `${selection} - `;
    }

    evt += sectionName.value;

    track(evt);
  });
};
