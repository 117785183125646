import apiHelper from '@/utils/apiHelper';

const getUserInfo = async () => {
  const { data } = await apiHelper(
    'GET',
    '/user-info/',
  );

  return data.data;
};

export default getUserInfo;
