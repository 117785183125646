import { formatDefaultLocale } from 'd3-format';
import { timeFormatDefaultLocale } from 'd3-time-format';

import getLocaleConfig from '@/utils/getLocaleConfig';

const setLocaleConfig = (overrides = {}) => {
  // update locale config
  const { currencyConfig, timeConfig } = getLocaleConfig(overrides);

  formatDefaultLocale(currencyConfig);
  timeFormatDefaultLocale(timeConfig);
};

export default setLocaleConfig;
