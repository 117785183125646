const languages = [
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Français', value: 'fr' },
  { label: 'Italiano', value: 'it' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'ไทย', value: 'th' },
  { label: 'Svenska', value: 'sv' },
];

export default languages;
