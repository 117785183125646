import moment from 'moment';

import useFilterState from '@/state/useFilterState';
import {
  axis,
  text,
  view,
  tooltip,
  strokeDash,
  strokeWidth,
  lineChartWidth,
  activeLineChartWidth,
  fontColor,
  fontBlack,
  font,
  fontSizeSmall,
  mainColor,
} from '@/config/charts';
import smartCeil from '@/utils/smartCeil';

const getLineChartSpec = ({
  dataFormat,
  data,
  interval,
  id,
  xTitle,
  small,
  legendValues,
}) => {
  const { dateBegin, dateEnd } = useFilterState();

  const isScoreChart = !!id.match(/score/);
  const isTemporalChart = interval !== undefined || isScoreChart;
  const width = 'container';

  let tickCount;
  let fieldX;
  let colorField;
  let fieldXmaxValue;

  let highest;

  const x = {};
  const matchesGroupByConfig = [];
  const hoverSelection = [];
  const colorEncoding = {};

  highest = Math.max(
    ...data.map((i) => (i.pastValue && i.pastValue > i.value && i.pastValue) || i.value || 0),
  );

  if (!isScoreChart) {
    highest = smartCeil(highest, !!dataFormat.match(/%/));

    colorField = 'compset';
    Object.assign(colorEncoding, {
      field: colorField,
      type: 'nominal',
      scale: { range: { field: 'color' } },
    });

    matchesGroupByConfig.push('value');
    hoverSelection.push('value');
  } else {
    highest = 100;
    colorField = 'metric';
    colorEncoding.value = mainColor;
    hoverSelection.push('metric');
  }

  const configHighest = [0, highest];

  const yAxisConfig = {
    tickRound: false,
    format: dataFormat,
    values: configHighest,
  };

  const yScaleConfig = {
    nice: false,
    domain: configHighest,
  };

  const markConfig = {
    type: 'line',
  };

  let filterTransform = 'datum.value !== null && (datum.value >= 0 || datum.pastValue > 0)';

  // Slightly different config for date-based charts vs funnel steps
  if (isTemporalChart) {
    let formattedInterval;
    let intervalFormat;
    let dateLabelExpr;

    const monthAndYear = '%b %Y';
    const monthAndDate = '%b %d';

    switch (interval) {
      case 'date':
        formattedInterval = 'yearmonthdate';

        // more than 31 days?
        if (moment(dateBegin.value).diff(moment(dateEnd.value), 'days') > 31) {
          // show month and year
          intervalFormat = monthAndYear;
        } else {
          // show dates (odd numbers only)
          dateLabelExpr = `date(datum.value) % 2 ? timeFormat(datum.value, '${monthAndDate}') : ''`;
        }
        break;
      case 'week':
        formattedInterval = 'yearweek';
        // eslint-disable-next-line no-case-declarations
        const weekFormat = moment(dateBegin.value).diff(moment(dateEnd.value), 'weeks') > 14
          ? monthAndYear
          : monthAndDate;
        dateLabelExpr = `timeFormat(time(datum.value) + 86400000, '${weekFormat}')`;
        break;
      default:
      case 'month':
        formattedInterval = 'yearmonth';
        intervalFormat = monthAndYear;
        break;
    }

    if (isScoreChart) {
      tickCount = 6;
      yAxisConfig.values = [0, 50, 100];
    } else {
      tickCount = 1;
    }

    fieldX = 'date';
    fieldXmaxValue = Math.max(
      ...data.map((i) => ((i.pastValue && i.pastDate) // pastdate is valid
        && i.pastDate > i.date && i.pastDate) // pastdate is higher than date
        || (i.value && i.date) || 0), // else, pick current date only if valid
    );

    Object.assign(x, {
      field: fieldX,
      type: 'temporal',
      timeUnit: formattedInterval,
      axis: {
        orient: 'bottom',
        labelAngle: 0,
        tickCount: interval || 'month',
        grid: false,
      },
    });

    // apply date format either via the "format" property or via a composed expression
    if (dateLabelExpr) {
      x.axis.labelExpr = dateLabelExpr;
    } else if (intervalFormat) {
      x.axis.format = intervalFormat;
    }
  } else if (id.match(/curves/)) {
    tickCount = 1;
    fieldX = 'step';
    fieldXmaxValue = Math.max(...data.map((i) => i.step));
    let maxStep;

    if (id.match(/window/)) {
      tickCount = 17;
      maxStep = 160;
    } else if (id.match(/stay/)) {
      tickCount = 6;
      maxStep = 30;
    }

    // add step filtering into main condition
    filterTransform += ` && isNumber(datum.step) && datum.step <= ${maxStep}`;

    markConfig.interpolate = 'monotone';

    Object.assign(x, {
      field: fieldX,
      type: 'quantitative',
      axis: {
        tickCount,
        orient: 'bottom',
        labelAngle: 0,
        grid: false,
      },
    });
  } else {
    tickCount = 8;
    fieldX = 'step';
    fieldXmaxValue = Math.max(...data.map((i) => i.step));

    yAxisConfig.values = [0, 0.5, 1];

    Object.assign(x, {
      field: fieldX,
      type: 'quantitative',
      axis: {
        title: null,
        orient: 'bottom',
        labelAngle: 0,
        grid: false,
        labelExpr: '(datum.value === 0 && "Total") || (datum.value === 1 && "Visitors Who Search") || (datum.value === 2 && "Bookings")',
        tickCount: 2,
      },
      sort: [0, 1, 2],
    });
  }

  yAxisConfig.tickCount = tickCount;

  Object.assign(x.axis, {
    titleFontStyle: font,
    titleFontSize: fontSizeSmall,
    titleColor: fontBlack,
    titleFontWeight: 500,
    title: small ? null : xTitle,
    titlePadding: 20,
  });

  matchesGroupByConfig.unshift(fieldX);
  hoverSelection.unshift(fieldX);

  colorEncoding.legend = legendValues ? {
    offset: 25,
    title: null,
    values: legendValues,
    orient: 'bottom',
    symbolType: 'circle',
    symbolSize: 200,
    labelFont: 'avenir',
    labelFontWeight: 500,
    labelFontSize: 14,
    labelLimit: 180,
  } : null;

  const tooltipCfg = small ? null : tooltip;

  return {
    data: {
      values: data,
    },

    // config
    width,
    autosize: { type: 'fit', contains: 'padding' },
    height: small ? 100 : 400,
    padding: 5,
    config: {
      view,
      axis: { ...axis, labels: !small },
      text,
    },

    encoding: {
      x,
      opacity: {
        condition: { value: 1, selection: 'compset' },
        value: 0,
      },
      strokeDash: { field: 'predicted', type: 'nominal', legend: null },
    },

    transform: [
      { filter: filterTransform },
      {
        joinaggregate: [
          { op: 'values', field: fieldX, as: 'matches' },
        ],
        groupby: matchesGroupByConfig,
      },
      { calculate: `${fieldXmaxValue}`, as: 'fieldXmaxValue' },
    ],

    layer: [
      { // visit
        encoding: {
          color: colorEncoding,
        },
        layer: [
          {
            selection: {
              compset: {
                type: 'multi',
                fields: [colorField],
                bind: 'legend',
                toggle: 'true',
              },
            },
            encoding: {
              y: {
                field: 'value',
                type: 'quantitative',
                axis: yAxisConfig,
                scale: yScaleConfig,
              },
              strokeWidth: {
                condition: {
                  selection: 'linehover',
                  value: activeLineChartWidth,
                },
                value: lineChartWidth,
              },
            },
            mark: {
              ...markConfig,
            },
          },
          {
            mark: { type: 'rule', strokeWidth },
            encoding: {
              color: {
                value: fontColor,
              },
              opacity: {
                condition: { value: 1, selection: 'hover' },
                value: 0,
              },
            },
          },
          {
            mark: {
              type: 'circle',
              size: 175,
              stroke: '#FFFFFF',
              strokeWidth: lineChartWidth,
              tooltip: tooltipCfg,
            },
            encoding: {
              opacity: {
                condition: { value: 1, selection: 'hover' },
                value: 0,
              },
              y: {
                field: 'value',
                type: 'quantitative',
              },
            },
            selection: {
              hover: {
                type: 'multi',
                fields: hoverSelection,
                nearest: true,
                on: 'mouseover',
                empty: 'none',
                clear: 'mouseout',
              },
              linehover: {
                type: 'single',
                on: 'mouseover',
                nearest: true,
                empty: 'none',
                fields: [colorField],
                clear: 'mouseout',
              },
            },
          },
        ],
      },

      { // past
        encoding: {
          color: {
            field: 'color',
            type: 'nominal',
            scale: null,
          },
        },
        layer: [
          {
            encoding: {
              y: {
                field: 'pastValue',
                type: 'quantitative',
                scale: yScaleConfig,
              },
            },
            mark: {
              ...markConfig,
              strokeWidth,
              strokeDash,
              opacity: 0.5,
            },

          },
          {
            mark: {
              type: 'circle',
              size: 100,
              tooltip: tooltipCfg,
              opacity: 0.5,
            },
            encoding: {
              opacity: {
                condition: { value: 1, selection: 'hover' },
                value: 0,
              },
              y: {
                field: 'value',
                type: 'quantitative',
              },
            },
          },
        ],
      },
    ],
  };
};

export default getLineChartSpec;
