import protectRoutes from '@/utils/protectRoutes';

const propertyRouteName = 'onboarding-intro-property';

const onboardingRoutes = protectRoutes([
  {
    component: () => import('@/layout/Benchmark/Benchmark.vue'),
    children: [
      {
        path: '/onboarding',
        name: propertyRouteName,
        component: () => import('./layout/Onboarding.vue'),
        children: [
          {
            path: '',
            name: 'onboarding_intro',
            components: {
              default: () => import('./views/IntroProperty/Intro.vue'),
            },
          },
          {
            path: '',
            name: 'onboarding_properties',
            components: {
              default: () => import('./views/Properties/Properties.vue'),
            },
          },
        ],
      },
    ],
  },
], {
  requiresPropertyUser: true,
  requiresKeyUser: true,
});

export default onboardingRoutes;
