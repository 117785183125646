import useLogState from '@/state/useLogState';
import useDebugState from '@/state/useDebugState';

const { setLog } = useLogState();

const { logActivity } = useDebugState();

const debugLog = (text, style) => {
  if (logActivity.value) {
    console.log(`%c [BD-Debug]: ${text}`, `${style}; color: black`);
  }
  setLog(text);
};

const bdLogger = {
  log: (text) => debugLog(text, 'background: rgb(50,205,50,0.3)'),
  warn: (text) => debugLog(text, 'background: rgb(255,0,0,0.3)'),
  saveDebug: setLog,
};

export default bdLogger;
