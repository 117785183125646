const scoreMetricsConfig = {
  dbi: 'Direct Booking Index (DBI)',
  conversion: 'Conversion',
  rates: 'Rates',
  bookings: 'Bookings',
  demand: 'Demand',
  disparities: 'Disparities',
  revenue: 'Revenue',
};

export default scoreMetricsConfig;
