import { createAuth, createAuth0Provider } from '@thehotelsnetwork/vue-core';

import browserStorage from '../browserStorage';
import env from '@/config/env';

const auth0Provider = createAuth0Provider({
  domain: env.VUE_APP_AUTH_DOMAIN,
  client_id: env.VUE_APP_AUTH_CLIENT_ID,
});
const auth = createAuth({
  provider: auth0Provider,
  storage: browserStorage,
});

export default auth;
