const getPercentBetween = ({
  min,
  max,
  current,
  round,
}) => {
  let percent = 100 - (((max + (current * -1)) * 100) / (max - min));

  if (Number.isNaN(percent)) {
    percent = 0;
  }

  if (current < 0) {
    percent = 100 - percent;
  }

  return round
    ? Math.round(percent)
    : percent;
};

export default getPercentBetween;
