import apiClient from '@/modules/apiClient';
import httpClient from '@/modules/httpClient';

import useRequestCache from '@/utils/useRequestCache';
import logout from '@/utils/logout';
import getFilenameFromHeaders from '@/utils/getFilenameFromHeaders';
import env from '@/config/env';

const { useFetchCache } = useRequestCache();

const validCodes = [200, 201, 202];

const apiHandler = async ({
  method,
  config,
  data,
  url,
}) => {
  let response;

  if (config.type !== 'download') {
    const options = {
      url,
      method,
      data,
      signal: config.signal,
    };

    const httpRequestFn = config.noSecure ? httpClient.request : apiClient.request;
    const fetchFn = config.useCache ? useFetchCache : httpRequestFn;

    response = await fetchFn(options);
  } else {
    const httpRequestFn = config.noSecure ? httpClient.request : apiClient.request;
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    response = await httpRequestFn({
      url,
      method,
      data: formData,
      responseType: 'blob',
    });

    if (response.status !== 200) throw new Error('Download failed');

    const responseUrl = window.URL.createObjectURL(response.data);

    const a = document.createElement('a');

    a.href = responseUrl;

    a.download = config.fileName || getFilenameFromHeaders(response.headers);

    a.click();
  }

  return response;
};

const apiHelper = async (method, path, data, config = {}) => {
  let url;

  if (config.useGateway) {
    url = `${env.VUE_APP_GATEWAY_API_URL}${path}`;
  } else if (config.usePublicGateway) {
    url = `${env.VUE_APP_PUBLIC_API_URL}/v2${path}`;
  } else {
    url = `${env.VUE_APP_BENCHDIRECT_API_URL}/api/v2${path}`;
  }

  // remove bar at the end of the url (could be copied by mistake)
  url = url.replace(/\/$/, '');

  const response = await apiHandler({
    method,
    url,
    data,
    config,
  });

  if (response.status === 401) {
    return logout();
  }

  if (config.type === 'download' || validCodes.indexOf(response.status) >= 0) {
    return response;
  }

  throw new Error(`An error has occured: ${response.status}`);
};

export default apiHelper;
