import useSectionState from '@/state/useSectionState';

import {
  text,
  view,
  bigSize,
  smallSize,
  tooltip,
  colorField,
  barChartYOffset,
  barChartComparedStep,
  barChartSingleStep,
  getValueDisplayLayer,
} from '@/config/charts';

const getMultiDivergingBarChartSpec = ({
  data,
  compareSecondCompset,
  defaultSort = true,
  dataFormat,
  isCtp,
  compareFirstCompset,
  showAll,
}) => {
  const {
    compsets,
    getCompsetData,
    sectionWidth,
  } = useSectionState();

  const width = sectionWidth.value;

  // slightly narrower width for country display
  const sourcesWidth = 200;

  const barChartWidth = (width - sourcesWidth) / 2.5;

  const step = isCtp ? barChartComparedStep : barChartSingleStep;

  const yOffset = isCtp ? barChartYOffset : 0;

  const leftPatternId = getCompsetData(compareFirstCompset).patternId;
  const rightPatternId = getCompsetData(compareSecondCompset).patternId;

  const sortId = defaultSort ? leftPatternId : rightPatternId;

  let highest = 0;

  let tmpData = data;

  if (!showAll) {
    const firstCompsetData = tmpData
      .filter(({ compsetTag }) => compsetTag === compareFirstCompset)
      .splice(0, 20);
    const secondCompsetData = tmpData
      .filter(({ compsetTag, source }) => compsetTag === compareSecondCompset
        && firstCompsetData.filter((i) => i.source === source).length);

    tmpData = [
      ...firstCompsetData,
      ...secondCompsetData,
    ];
  }

  // catch the highest value only from valid entries
  highest = Math.max(...tmpData.map((i) => {
    let { value } = i;

    if (isCtp && i.pastValue > value) {
      value = i.pastValue;
    }

    return i.valid ? value : 0;
  }));

  // reduce the bar size making the axis domain higher to let the "diff" text have space
  highest += ((highest * 50) / 100);

  const leftValuesLayer = getValueDisplayLayer('right', dataFormat, highest);
  const rightValuesLayer = getValueDisplayLayer('left', dataFormat, highest);

  const sortOrder = {
    field: 'finalValue',
    op: 'max',
    order: 'descending',
  };

  const fieldX = {
    type: 'quantitative',
    axis: null,
    scale: { domain: [0, highest] },
  };

  const fieldY = {
    field: 'source',
    type: 'nominal',
    title: null,
    axis: null,
  };

  const getSmallBarsLayer = (side) => [{
    mark: {
      type: 'bar',
      size: bigSize,
      tooltip,
      cornerRadiusTopLeft: side === 'left' ? 0 : 2,
      cornerRadiusTopRight: side === 'left' ? 2 : 0,
      cornerRadiusBottomLeft: side === 'left' ? 0 : 2,
      cornerRadiusBottomRight: side === 'left' ? 2 : 0,
    },
    encoding: {
      color: colorField,
    },
  },
  {
    layer: [
      {
        mark: {
          type: 'bar',
          size: smallSize,
          yOffset,
          tooltip,
          opacity: 0.5,
          cornerRadiusTopLeft: side === 'left' ? 0 : 2,
          cornerRadiusTopRight: side === 'left' ? 2 : 0,
          cornerRadiusBottomLeft: side === 'left' ? 0 : 2,
          cornerRadiusBottomRight: side === 'left' ? 2 : 0,
        },
        encoding: {
          x: { ...fieldX, field: 'pastValue', sort: side === 'right' ? 'descending' : 'ascending' },
          color: colorField,
        },
      },
      ...compsets.value.map((c) => ({
        transform: [{ filter: `datum.patternId == ${c.patternId}` }],
        mark: {
          type: 'bar',
          size: smallSize,
          yOffset,
          tooltip,
          cornerRadiusTopLeft: side === 'left' ? 0 : 2,
          cornerRadiusTopRight: side === 'left' ? 2 : 0,
          cornerRadiusBottomLeft: side === 'left' ? 0 : 2,
          cornerRadiusBottomRight: side === 'left' ? 2 : 0,
        },
        encoding: {
          x: { ...fieldX, field: 'pastValue' },
          fill: { value: `url(#pattern_${c.patternId})` },
        },
      })),
    ],
  }];

  return {
    data: {
      values: tmpData,
    },
    spacing: 0,
    padding: 0,
    config: {
      view,
      axis: null,
      text,
    },
    transform: [
      { filter: 'datum.source' },
      { calculate: `datum.patternId == ${sortId} ? datum.value : 0`, as: 'filteredValue' },
      {
        joinaggregate: [
          { op: 'sum', field: 'filteredValue', as: 'finalValue' },
        ],
        groupby: ['source'],
      },
    ],
    hconcat: [
      {
        transform: [
          { filter: `datum.patternId == ${leftPatternId}` },
        ],
        width: barChartWidth,
        height: { step },
        encoding: {
          x: { ...fieldX, field: 'value', sort: 'descending' },
          y: {
            ...fieldY,
            sort: sortOrder,
          },
        },
        layer: [
          ...getSmallBarsLayer('right'),
          ...leftValuesLayer,
        ],
      },

      {
        transform: [
          { filter: `datum.patternId == ${sortId}` },
        ],
        width: sourcesWidth,
        height: { step },
        view: { stroke: null },
        mark: {
          type: 'text',
          align: 'center',
          yOffset: isCtp ? 5 : 0,
          limit: 150,
        },
        encoding: {
          y: {
            ...fieldY,
            sort: sortOrder,
          },
          text: {
            field: 'source',
            type: 'nominal',
          },
        },
      },
      {
        transform: [
          { filter: `datum.patternId == ${rightPatternId}` },
        ],
        width: barChartWidth,
        height: { step },
        encoding: {
          y: {
            ...fieldY,
            sort: sortOrder,
          },
          x: { ...fieldX, field: 'value' },
        },
        layer: [
          ...getSmallBarsLayer('left'),
          ...rightValuesLayer,
        ],
      },
    ],
  };
};

export default getMultiDivergingBarChartSpec;
