import i18n from '@/config/i18n';

import useSectionState from '@/state/useSectionState';

import {
  text,
  view,
  bigSize,
  font,
  fontColor,
  smallSize,
  colorField,
  barChartYOffset,
  axis,
  barChartComparedStep,
  barChartSingleStep,
} from '@/config/charts';

const getStackedBarChartSpec = ({
  data,
  dataFormat,
  isCtp,
}) => {
  const { compsets, compsetNames } = useSectionState();

  const width = 'container';

  // height of each individual bar (total height with spaces, not bar size)
  const step = isCtp ? barChartComparedStep : barChartSingleStep;

  const barMark = {
    type: 'bar',
    tooltip: { content: 'data' },
  };

  const textLayerConfig = {
    mark: {
      type: 'text',
      align: 'right',
      fontWeight: 500,
      dx: -10,
    },
    encoding: {
      x: { field: 'max' },
      color: { value: 'white' },
      text: {
        field: 'formattedValue',
        type: 'nominal',
      },
    },
  };

  // threshhold at which values should be shown or hidden from the bar
  const minThreshold = 0.06;

  const devicesTemplateString = ['Desktop', 'Tablet', 'Mobile']
    .map((d) => `${i18n.global.t(d).charAt(0)} - ${i18n.global.t(d)}`)
    .join(' / ');

  const pastValueRule = 'datum.pastValue >= 0';
  const totalDesktopRule = 'datum.totalDesktop || 0.33';
  const pastDesktopRule = 'datum.totalPastDesktop || 0.33';
  const pastDesktopAndTabletRule = 'datum.totalPastDesktop + datum.totalPastTablet';

  return {
    data: {
      values: data,
    },
    width,
    autosize: { type: 'fit', contains: 'padding' },
    height: { step },
    padding: 5,
    config: {
      view,
      text,
      scale: { barBandPaddingInner: 0, bandPaddingOuter: 0 },
      axis: {
        ...axis,
        labelPadding: 15,
        labelAlign: 'right',
        domain: null,
      },
    },
    title: {
      text: devicesTemplateString,
      color: fontColor,
      anchor: 'start',
      dx: 130,
      dy: 20,
      font,
      fontSize: 16,
      fontWeight: 500,
      orient: 'bottom',
    },

    transform: [
      // filter out null entries
      { filter: 'datum.source' },
      // calculate total of all the percents
      { joinaggregate: [{ op: 'sum', field: 'value', as: 'total' }], groupby: ['compset'] },

      { joinaggregate: [{ op: 'sum', field: 'pastValue', as: 'pastTotal' }], groupby: ['compset'] },
      // save source totals
      { calculate: 'datum.source === "Desktop" ? datum.value / datum.total : 0', as: 'totalDesktop' },
      { calculate: 'datum.source === "Tablet" ? datum.value / datum.total : 0', as: 'totalTablet' },
      { calculate: 'datum.source === "Mobile" ? datum.value / datum.total : 0', as: 'totalMobile' },

      { calculate: 'datum.source === "Desktop" ? datum.pastValue / datum.pastTotal : 0', as: 'totalPastDesktop' },
      { calculate: 'datum.source === "Tablet" ? datum.pastValue / datum.pastTotal : 0', as: 'totalPastTablet' },
      { calculate: 'datum.source === "Mobile" ? datum.pastValue / datum.pastTotal : 0', as: 'totalPastMobile' },
      // save new values
      { joinaggregate: [{ op: 'sum', field: 'totalDesktop', as: 'totalDesktop' }], groupby: ['compset'] },
      { joinaggregate: [{ op: 'sum', field: 'totalTablet', as: 'totalTablet' }], groupby: ['compset'] },
      { joinaggregate: [{ op: 'sum', field: 'totalMobile', as: 'totalMobile' }], groupby: ['compset'] },

      { joinaggregate: [{ op: 'sum', field: 'totalPastDesktop', as: 'totalPastDesktop' }], groupby: ['compset'] },
      { joinaggregate: [{ op: 'sum', field: 'totalPastTablet', as: 'totalPastTablet' }], groupby: ['compset'] },
      { joinaggregate: [{ op: 'sum', field: 'totalPastMobile', as: 'totalPastMobile' }], groupby: ['compset'] },
    ],

    encoding: {
      y: {
        sort: compsetNames.value,
        field: 'compset',
        type: 'nominal',
      },
      color: colorField,
    },

    layer: [
      {
        encoding: {
          x: {
            field: 'min',
            type: 'quantitative',
            axis: null,
          },
          x2: {
            field: 'max',
            type: 'quantitative',
          },
        },
        layer: [

          { // mobile bar
            transform: [
              { filter: 'datum.source === "Mobile"' },
              { calculate: 'datum.totalDesktop + datum.totalTablet', as: 'min' },
              { calculate: '1', as: 'max' },
            ],
            layer: [
              {
                mark: {
                  ...barMark,
                  size: bigSize,
                  opacity: 0.5,
                },
              },
              {
                transform: [
                  { filter: pastValueRule },
                  { calculate: pastDesktopAndTabletRule, as: 'min' },
                  { calculate: '1', as: 'max' },
                ],
                mark: {
                  ...barMark,
                  size: smallSize,
                  opacity: 0.1,
                  yOffset: barChartYOffset,
                },
                encoding: {
                  color: colorField,
                },
              },
              ...compsets.value.map((c) => ({
                transform: [
                  { filter: `datum.pastValue && datum.patternId == ${c.patternId}` },
                  { calculate: pastDesktopAndTabletRule, as: 'min' },
                  { calculate: '1', as: 'max' },
                ],
                mark: {
                  ...barMark,
                  size: smallSize,
                  opacity: 0.1,
                  yOffset: barChartYOffset,
                },
                encoding: {
                  color: colorField,
                  fill: { value: `url(#pattern_${c.patternId})` },
                },
              })),
              {
                transform: [
                  {
                    joinaggregate: [
                      { op: 'argmax', field: 'max', as: 'highest' },
                    ],
                  },
                  { filter: `datum.max - datum.min > ${minThreshold}` },
                  { calculate: `'${i18n.global.t('Mobile').charAt(0)} ' + format(datum.value, '${dataFormat}')`, as: 'formattedValue' },
                ],
                ...textLayerConfig,
              },
            ],
          },

          { // tablet bar
            transform: [
              { filter: 'datum.source === "Tablet"' },
              { calculate: totalDesktopRule, as: 'min' },
              { calculate: 'datum.totalDesktop + datum.totalTablet || 0.66', as: 'max' },
            ],
            layer: [
              {
                mark: {
                  ...barMark,
                  size: bigSize,
                  opacity: 0.7,
                },
              },
              {
                transform: [
                  { filter: pastValueRule },
                  { calculate: pastDesktopRule, as: 'min' },
                  { calculate: pastDesktopAndTabletRule, as: 'max' },
                ],
                mark: {
                  ...barMark,
                  size: smallSize,
                  opacity: 0.3,
                  yOffset: barChartYOffset,
                },
                encoding: {
                  color: colorField,
                },
              },
              ...compsets.value.map((c) => ({
                transform: [
                  { filter: `datum.pastValue && datum.patternId == ${c.patternId}` },
                  { calculate: pastDesktopRule, as: 'min' },
                  { calculate: 'datum.totalPastDesktop + datum.totalPastTablet || 0.66', as: 'max' },
                ],
                mark: {
                  ...barMark,
                  size: smallSize,
                  opacity: 0.3,
                  yOffset: barChartYOffset,
                },
                encoding: {
                  color: colorField,
                  fill: { value: `url(#pattern_${c.patternId})` },
                },
              }
              )),
              {
                transform: [
                  {
                    joinaggregate: [
                      { op: 'argmax', field: 'max', as: 'highest' },
                    ],
                  },
                  { filter: `datum.max - datum.min > ${minThreshold}` },
                  { calculate: `'${i18n.global.t('Tablet').charAt(0)} ' + format(datum.value, '${dataFormat}')`, as: 'formattedValue' },
                ],
                ...textLayerConfig,
              },
            ],
          },

          { // desktop bar
            transform: [
              { filter: 'datum.source === "Desktop"' },
              { calculate: '0', as: 'min' },
              { calculate: totalDesktopRule, as: 'max' },
            ],
            layer: [
              {
                mark: {
                  ...barMark,
                  size: bigSize,
                  opacity: 1,
                },
              },
              {
                transform: [
                  { filter: pastValueRule },
                  { calculate: '0', as: 'min' },
                  { calculate: pastDesktopRule, as: 'max' },
                ],
                mark: {
                  ...barMark,
                  size: smallSize,
                  opacity: 0.5,
                  yOffset: barChartYOffset,
                },
                encoding: {
                  color: colorField,
                },
              },
              ...compsets.value.map((c) => ({
                transform: [
                  { filter: `datum.pastValue && datum.patternId == ${c.patternId}` },
                  { calculate: '0', as: 'min' },
                  { calculate: pastDesktopRule, as: 'max' },
                ],
                mark: {
                  ...barMark,
                  size: smallSize,
                  opacity: 0.5,
                  yOffset: barChartYOffset,
                },
                encoding: {
                  color: colorField,
                  fill: { value: `url(#pattern_${c.patternId})` },
                },
              }
              )),
              {
                transform: [
                  {
                    joinaggregate: [
                      { op: 'argmax', field: 'max', as: 'highest' },
                    ],
                  },
                  { filter: `datum.max - datum.min > ${minThreshold}` },
                  { calculate: `'${i18n.global.t('Desktop').charAt(0)} ' + format(datum.value, '${dataFormat}')`, as: 'formattedValue' },
                ],
                ...textLayerConfig,
              },
            ],
          },
        ],
      },
    ],
  };
};

export default getStackedBarChartSpec;
