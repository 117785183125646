import { track } from '@/utils/analytics';
import bdLogger from '@/utils/bdLogger';
import getStats from '@/utils/getStats';
import setLocaleConfig from '@/utils/setLocaleConfig';
import getTotalScores from '@/utils/getTotalScores';
import getOverTimeScores from '@/utils/getOverTimeScores';

import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';
import isAbortError from '@/utils/isAbortError';

const {
  currentPermissions,
  isSummarySection,
  setRunningCalls,
  sectionName,
} = useSectionState();

const {
  isOverview,
  propertyId,
  isValid,
  featureFlags,
} = useMainState();

const sectionsWithScore = [
  'conversion',
  'revenue',
  'bookings',
  'demand',
  'disparities',
];

const handleOnPropertyChange = async (options = {}) => {
  bdLogger.log(
    `User selected property: ${propertyId.value}, Overview: ${isOverview.value}, Current permissions: ${Object.keys(currentPermissions.value).join(', ')}`,
  );

  // only track user selected properties
  if (options.userInput) {
    track('hotel selector');
  }

  setLocaleConfig();

  if (options.disableStats
      || (propertyId.value > 0 && !isValid.value)) {
    return;
  }

  setRunningCalls('score');

  if (isSummarySection.value
    && !isOverview.value) {
    /*  Get over-time scores
      Applies to:
        - Summary Property
    */
    if (!featureFlags.value['hide-property-score']) {
      getOverTimeScores();
    }
  } else if (isSummarySection.value
      || (!isOverview.value && sectionsWithScore.indexOf(sectionName.value) !== -1)) {
  /*  Get total scores
    Applies to:
      - Summary OV/Property
      - Section Property
  */

    getTotalScores();
  }

  if (isSummarySection.value && isOverview.value) {
    // Summary overview / avoid call for stats
    return;
  }

  try {
    await getStats({
      reset: true,
      getGeolocationStats: !isSummarySection.value,
    });
  } catch (err) {
    if (!isAbortError(err)) {
      console.error(err);
    }
  }
};

export default handleOnPropertyChange;
