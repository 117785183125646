import getDefaultSectionDataConfig from '@/utils/getDefaultSectionDataConfig';
import {
  commonPipeConfig,
} from '@/state';

import {
  builderDescriptions,
} from '@/config/infoDescriptions';

const data = getDefaultSectionDataConfig([
  ...commonPipeConfig,
]);

const metrics = [{
  name: 'default',
  desc: builderDescriptions.default.desc,
}];

export default function useBuilderState() {
  return {
    data,
    metrics,
  };
}
