import {
  otasPipe,
} from '@/state';
import fetchRequest from '@/utils/fetchRequest';
import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';

const getOtasData = async () => {
  const { setOtas } = useFilterState();
  const { setPipeStatus } = useSectionState();

  const { name: otasPipeName } = otasPipe;

  setPipeStatus(otasPipeName, { loading: true });

  try {
    const {
      meta,
    } = await fetchRequest('disparities/average', { overrides: { noFilters: true } });

    setOtas(meta.otas);

    setPipeStatus(otasPipeName, { loading: false });
  } catch {
    setPipeStatus(otasPipeName, { hasError: true });
  }
};

export default getOtasData;
