import getUserCharts from '@/utils/getUserCharts';
import getUserChartsConfig from '@/utils/getUserChartsConfig';
import useSectionState from '@/state/useSectionState';
import useBuilderState from '@/state/useBuilderState';
import useFilterState from '@/state/useFilterState';
import { clearDynamicPipes } from '@/state/sectionStateConfig';

const initBuilderSection = async () => {
  const {
    metrics,
    data,
  } = useBuilderState();

  const { setSelectedMetric } = useFilterState();

  const {
    setSectionMetricData,
    setSectionCharts,
    setSectionTables,
    setSectionMetrics,
  } = useSectionState();

  setSectionCharts([]);
  setSectionTables([]);
  clearDynamicPipes();
  setSelectedMetric('default');
  setSectionMetricData(data);
  setSectionMetrics(metrics);

  const userCharts = await getUserCharts();
  const { data: updatedData, charts } = getUserChartsConfig(userCharts);

  setSectionMetricData(updatedData);
  setSectionCharts(charts);
  setSectionTables([]);
};

export default initBuilderSection;
