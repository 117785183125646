<template>
  <div
    :class="[
      'py-5 px-6 bg-white border border-base-400',
      'rounded-xl shadow-tooltip-base max-w-tooltip-base'
    ]"
  >
    <slot />
  </div>
</template>
<script>
import { onMounted, onUpdated } from 'vue';

export default {
  emits: ['mounted', 'updated'],
  setup(props, { emit }) {
    onMounted(() => emit('mounted'));
    onUpdated(() => emit('updated'));
  },
};
</script>
