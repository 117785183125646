import protectRoutes from '@/utils/protectRoutes';
import initBuilderSection from '@/utils/initBuilderSection';

const sectionRoutes = protectRoutes([
  {
    children: [
      {
        path: '/summary/:type/:propertyId?',
        name: 'summary',
        component: () => import('./views/Summary/Summary.vue'),
      },
    ],
    meta: {
      requiresPropertyUser: true,
    },
  },
  {
    children: [
      {
        path: '/conversion/:type?/:propertyId?',
        name: 'conversion',
        component: () => import('./views/Conversion/Conversion.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/revenue/:type?/:propertyId?',
        name: 'revenue',
        component: () => import('./views/Revenue/Revenue.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/bookings/:type?/:propertyId?',
        name: 'bookings',
        component: () => import('./views/Bookings/Bookings.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/demand/:type?/:propertyId?',
        name: 'demand',
        component: () => import('./views/Demand/Demand.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/booking-window/:type?/:propertyId?',
        name: 'booking-window',
        component: () => import('./views/BookingWindow/BookingWindow.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/length-of-stay/:type?/:propertyId?',
        name: 'length-of-stay',
        component: () => import('./views/LengthOfStay/LengthOfStay.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/rates/:type?/:propertyId?',
        name: 'rates',
        component: () => import('./views/Rates/Rates.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/disparities/:type?/:propertyId?',
        name: 'disparities',
        component: () => import('./views/Disparities/Disparities.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/visitor-profile/:type?/:propertyId?',
        name: 'visitors',
        component: () => import('./views/VisitorProfile/VisitorProfile.vue'),
      },
    ],
  },
  {
    children: [
      {
        path: '/opportunities/:type?/:propertyId?',
        name: 'opportunities',
        component: () => import('./views/Opportunities/Opportunities.vue'),
      },
    ],
  },
  {
    beforeEnter: initBuilderSection,
    children: [
      {
        path: '/builder/:type?/:propertyId?',
        name: 'builder',
        component: () => import('./views/Builder/Builder.vue'),
      },
    ],
  },
].map((route) => ({
  ...route,
  component: () => import('@/layout/Benchmark/Benchmark.vue'),
})), {
  requiresDataAccess: true,
  isDataRoute: true,
  showHeader: true,
  showSideNav: true,
});

export default sectionRoutes;
