import checkInternalUser from '@/utils/checkInternalUser';

export const showAppcues = (id) => checkInternalUser(() => {
  window.Appcues.show(id);
}, { allow: false });

export const setAppcuesFlow = (id) => localStorage.setItem('appcuesFlowId', id);

export const checkAppcuesFlow = () => {
  const appcuesFlowId = localStorage.getItem('appcuesFlowId');
  if (appcuesFlowId) {
    // Start the flow
    showAppcues(appcuesFlowId);
    // Remove localStorage item
    localStorage.removeItem('appcuesFlowId');
  }
};

export const resetAppcuesData = () => window.Appcues?.reset();
