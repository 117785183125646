const sectionExceptions = {
  conversion: {},
  revenue: {},
  bookings: {},
  demand: {},
  'booking-window': {
    hideScore: true,
    disableCalendarStayDates: true,
  },
  'length-of-stay': {
    hideScore: true,
  },
  rates: {
    hideScore: true,
  },
  disparities: {
  },
  visitors: {
    hideScore: true,
    disabledViews: {
      calendar: true,
    },
    defaultView: 'charts',
  },
  user_properties: {
    hidePropertiesDropdown: true,
  },
  user_account: {
    hidePropertiesDropdown: true,
  },
  contact: {
    hidePropertiesDropdown: true,
  },
  email_notifications: {
    hidePropertiesDropdown: true,
  },
  onboarding_intro: {
    hidePropertiesDropdown: true,
  },
  onboarding_properties: {
    hidePropertiesDropdown: true,
  },
  brand_compset: {
    hidePropertiesDropdown: true,
  },
  proximity_compset: {
    hidePropertiesDropdown: true,
  },
  thn_compset: {
    hidePropertiesDropdown: true,
  },
  custom_compset: {
    hidePropertiesDropdown: true,
  },
  property_custom_compset: {
    hidePropertiesDropdown: true,
  },
  brand_custom_compset: {
    hidePropertiesDropdown: true,
  },
  destinations_compset: {
    hidePropertiesDropdown: true,
  },
  proximity_map: {
    hidePropertiesDropdown: true,
  },
  destinations_compset_edit: {
    hidePropertiesDropdown: true,
  },
  opportunities: {
    disableStats: true,
  },
  property_profile: {
    disableStats: true,
  },
  property_preferences: {
    disableStats: true,
  },
  thn_setup: {
    disableStats: true,
  },
  builder: {
    hideScore: true,
    disabledViews: {
      calendar: true,
    },
  },
};

export default sectionExceptions;
