import { computed, ref } from 'vue';

const DEFAULT_SIDES = ['top', 'right', 'bottom', 'left'];

// Save tooltip's preferred ordered sides (if specified)
const tpPreferredSides = ref([]);

// Join tooltip's preferred sides with the default ones so we always have all 4 sides
const computedSides = computed(() => {
  const jointArray = [...tpPreferredSides.value, ...DEFAULT_SIDES];
  return jointArray.filter((item, index) => jointArray.indexOf(item) === index);
});

const tpRef = ref(null);
const tpContent = ref({});
const tpComponent = ref('');

const tpVisible = ref(false);

const tpX = ref(0);
const tpY = ref(0);

// Offsets:
// tgOffset: offset between the target element and the tooltip (space between them).
// tpOffset: offset between the tooltip and the viewport.
const tgOffset = 8;
const tpOffset = 15;

// Previos event target positions and data
const prevTop = ref(null);
const prevLeft = ref(null);
const prevTarget = ref(null);

// Last time content was updated. Used to force the tooltip and target box updates
const lastContentUpdate = ref(null);

// Element Positions:
// - Target Element position data based on prevTarget (element that triggers the tooltip)
const tgtBox = computed(
  () => lastContentUpdate.value && (prevTarget.value && prevTarget.value.getBoundingClientRect()),
);

// - Tooltip Element position data based on tooltip dynamic reference
const tpBox = computed(
  () => prevTarget.value && tpRef.value && tpRef.value.getBoundingClientRect(),
);

// Orientation (which side are we calculating the offset for?)
const orientation = ref('v'); // v: vertical / h: horizontal

const leftSpace = computed(() => (orientation.value === 'v'
  ? (window.innerHeight - tgtBox.value.bottom)
  : tgtBox.value.left));

const rightSpace = computed(() => (orientation.value === 'v'
  ? tgtBox.value.top
  : (window.innerWidth - tgtBox.value.right)));

// Sides of both element and tooltip, depending on which direction are we calculating the offset for.
// Target Side
const tgSide = computed(() => (orientation.value === 'v'
  ? tgtBox.value.height
  : tgtBox.value.width));

// Tooltip Side
const tpSide = computed(() => (orientation.value === 'v'
  ? tpBox.value.height
  : tpBox.value.width));

// Check if the target is cut by the viewport on any side
const overflowXSides = computed(
  () => tgtBox.value.left < 0 || tgtBox.value.right > window.innerWidth,
);
const overflowYSides = computed(
  () => tgtBox.value.top < 0 || tgtBox.value.bottom > window.innerHeight,
);

const tpShownSide = ref(null);

// Methods
const setTpComponent = (type) => { tpComponent.value = `bm-${type}-tooltip`; };

const setTpVisible = (v) => { tpVisible.value = v; };

const setContent = (c) => {
  lastContentUpdate.value = Date.now();
  tpContent.value = c;
};

const setOrientation = (o) => { orientation.value = o; };

const setPrevTarget = (t) => { prevTarget.value = t; };

// eslint-disable-next-line consistent-return
const calcOffset = () => {
  // Is target wider than the target button?
  // Always centered
  if (tgSide.value >= tpSide.value) {
    return (leftSpace.value + tgSide.value / 2) - (tpSide.value / 2);
  }

  // Tooltip is wider
  // Case 1: center

  // Calculate the remaining space that would be left on both sides
  // (adding one offset per side (* 2) so it does not appear on the edge of the viewport).
  // Divided by 2 so we get one side.
  const overflowingSideSpace = ((tpSide.value + (2 * tpOffset)) - tgSide.value) / 2;
  if (overflowingSideSpace < leftSpace.value && overflowingSideSpace < rightSpace.value) {
    // It fits in the center
    return orientation.value === 'v'
      ? (rightSpace.value + (tgSide.value / 2)) - (tpSide.value / 2)
      : (leftSpace.value + (tgSide.value / 2)) - (tpSide.value / 2);
  }

  // Case 2: Left side is not wide enough. Tooltip should be move to the right.
  // As left is the problem, start by adding the offset and place the start point in here.
  // Then, the tooltip will be place according to the all remaining space to the right.
  if (leftSpace.value < overflowingSideSpace) {
    return orientation.value === 'v' ? (window.innerHeight - (tpOffset + tpSide.value)) : tpOffset;
  }

  if (rightSpace.value < overflowingSideSpace) {
    return orientation.value === 'v' ? tpOffset : (window.innerWidth - (tpOffset + tpSide.value));
  }
};

const addOffsets = (s) => tgOffset + s + tpOffset;

const sidesLogic = {
  top: {
    pass: () => (tgtBox.value.top >= (addOffsets(tpBox.value.height))) && !overflowXSides.value,
    set: () => {
      setOrientation('h');
      tpShownSide.value = 'top';
      tpX.value = calcOffset();
      tpY.value = tgtBox.value.top - (tgOffset + tpBox.value.height);
    },
  },
  right: {
    pass: () => ((window.innerWidth - tgtBox.value.right) >= (addOffsets(tpBox.value.width)))
      && !overflowYSides.value,
    set: () => {
      setOrientation('v');
      tpShownSide.value = 'right';
      tpX.value = tgtBox.value.right + tgOffset;
      tpY.value = calcOffset();
    },
  },
  bottom: {
    pass: () => ((window.innerHeight - tgtBox.value.bottom) >= (addOffsets(tpBox.value.height)))
      && !overflowXSides.value,
    set: () => {
      setOrientation('h');
      tpShownSide.value = 'bottom';
      tpX.value = calcOffset();
      tpY.value = tgtBox.value.bottom + tgOffset;
    },
  },
  left: {
    pass: () => (tgtBox.value.left >= (addOffsets(tpBox.value.width))) && !overflowYSides.value,
    set: () => {
      setOrientation('v');
      tpShownSide.value = 'left';
      tpX.value = (tgtBox.value.left - (addOffsets(tpBox.value.width)));
      tpY.value = calcOffset();
    },
  },
};

const calcPosition = () => {
  // Early exit
  if (!tgtBox.value || !tpBox.value) {
    return false;
  }

  let pass = false;

  computedSides.value.some((side) => {
    if (sidesLogic[side].pass()) {
      sidesLogic[side].set();
      pass = true;
      return true;
    }
    return false;
  });
  return pass;
};

// Init
const initTooltip = (ev, type = 'info', content = {}, prefSides = []) => {
  // Save target that triggered the tooltip inisialization
  const evBox = ev.target.getBoundingClientRect();
  if (
    ev.target.getBoundingClientRect() === prevTarget.value?.getBoundingClientRect()
    && (prevTop.value === evBox.top && prevLeft.value === evBox.left)
  ) {
    setTpVisible(true);
  } else {
    prevTop.value = evBox.top;
    prevLeft.value = evBox.left;
    setPrevTarget(ev.target);

    // Set preferred sides
    tpPreferredSides.value = prefSides;

    // Set tooltip type (info / chart)
    setTpComponent(type);

    // Set its inner data to show
    setContent({ ...content });
  }
};

const mountTooltip = () => {
  // Calculate the side where the tooltip will appear
  if (calcPosition()) {
    // Make it visible!
    setTpVisible(true);
  }
};

const unmountTooltip = () => setTpVisible(false);

export default function useTooltipStateV2() {
  return {
    tpRef,
    tpContent,
    tpComponent,

    // Styles
    tpVisible,

    // Position
    tpX,
    tpY,

    // Data
    tpPreferredSides,
    tpShownSide,
    computedSides,
    lastContentUpdate,

    // Methods
    setTpVisible,
    setPrevTarget,
    initTooltip,
    mountTooltip,
    unmountTooltip,
    setContent,
  };
}
