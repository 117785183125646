import moment from 'moment';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from 'tailwind-config';

import calculateDiff from '@/utils/calculateDiff';
import formatValue from '@/utils/formatValue';
import getPercentBetween from '@/utils/getPercentBetween';

import {
  percentFormat,
} from '@/config/valueFormatConfig';

const { theme: tailwindTheme } = resolveConfig(tailwindConfig);

const getNormalizedPercent = (val) => {
  // transform to percent and parse into number (without %)
  if (Number.isFinite(val)) {
    return parseFloat(
      formatValue(val, percentFormat),
    );
  }

  // fallback to undefined and let the component decide its default value
  return undefined;
};

const getMinMax = (arr) => [Math.min(...arr), Math.max(...arr)];

const mergeCalendarValues = ({
  baseData,
  secondaryData,
  selectedFirstCompsetTheme,
  selectedSecondCompsetTheme,
  isDisparitiesSection,
  isPremiumPlan,
}) => {
  // store positive and negative differences separately
  const negativeDiffs = [];
  const positiveDiffs = [];

  const {
    values,
    minSize,
    maxSize,
  } = baseData
  // apply secondaryData variables over baseData
    .reduce((acc, curr) => {
      const secondaryItem = secondaryData.filter((item) => item.date === curr.date)[0];

      if (!secondaryItem) {
        return acc;
      }

      // final data structure
      const mergedItem = {
        diff: calculateDiff(curr.value, secondaryItem.value),
        date: curr.date,
        totalCalendarAvg: Math.round(curr.calendarAvg + secondaryItem.calendarAvg),
        barChartData: [
          isPremiumPlan
            ? curr
            : Object.assign(curr, { compset: 'Hotel' }),
          secondaryItem,
        ],
      };

      if (isDisparitiesSection && mergedItem.diff !== 0) {
        mergedItem.diff *= -1;
      }

      // push month value
      acc.values.push(mergedItem);
      // update min-max range of variables
      if (mergedItem.diff < 0) {
        negativeDiffs.push(mergedItem.diff);
      }
      if (mergedItem.diff > 0) {
        positiveDiffs.push(mergedItem.diff);
      }
      if (mergedItem.totalCalendarAvg < acc.minSize) {
        acc.minSize = mergedItem.totalCalendarAvg;
      }
      if (mergedItem.totalCalendarAvg > acc.maxSize) {
        acc.maxSize = mergedItem.totalCalendarAvg;
      }

      // day is not valid when both values are 0
      mergedItem.isValid = curr.value > 0
      || secondaryItem.value > 0;

      return acc;
    }, {
      values: [],
      minSize: Number.MAX_SAFE_INTEGER,
      maxSize: 0,
    });

  // get min-max range for each side
  const [maxNegativeDiff, minNegativeDiff] = getMinMax(negativeDiffs);
  const [minPositiveDiff, maxPositiveDiff] = getMinMax(positiveDiffs);

  /*
    Creates the following structure:

    { Month
      6: { Days
        21: {<day_data>},
        22: {...},
        23: {...},
      }
    }
  */
  const valuesByMonth = values
    .reduce((acc, curr) => {
      // get month and day
      const monthTimestamp = moment(curr.date).startOf('month').unix();
      const day = moment(curr.date).date();

      // create month item if it does not already exist
      if (!acc[monthTimestamp]) {
        acc[monthTimestamp] = {};
      }

      const isPositiveDiff = curr.diff > 0;

      const tmpMinDiff = isPositiveDiff ? minPositiveDiff : maxNegativeDiff;
      const tmpMaxDiff = isPositiveDiff ? maxPositiveDiff : minNegativeDiff;
      let theme;
      let opacity;

      // assign theme color
      if (curr.diff === 0) {
        theme = tailwindTheme.colors.base['400'];

        opacity = 100;
      } else {
        if (curr.diff < 0) {
          theme = selectedSecondCompsetTheme;
        } else if (curr.diff > 0) {
          theme = selectedFirstCompsetTheme;
        }

        opacity = getPercentBetween({
          min: tmpMinDiff,
          max: tmpMaxDiff,
          current: curr.diff,
          round: true,
        });
      }

      const radius = getPercentBetween({
        min: minSize,
        max: maxSize,
        current: curr.totalCalendarAvg,
        round: true,
      });

      const formattedItem = {
        ...curr,
        theme,
        radius,
        opacity,
      };

      acc[monthTimestamp][day] = formattedItem;

      return acc;
    }, {});

  return {
    valuesByMonth,
    minNegativeDiff: getNormalizedPercent(minNegativeDiff),
    maxNegativeDiff: getNormalizedPercent(maxNegativeDiff),
    minPositiveDiff: getNormalizedPercent(minPositiveDiff),
    maxPositiveDiff: getNormalizedPercent(maxPositiveDiff),
    minSize,
    maxSize,
  };
};

export default mergeCalendarValues;
