const clearLocalStorage = ({ removeOneClickToken } = {}) => {
  localStorage.removeItem('bmData');
  localStorage.removeItem('bmDebug');

  if (removeOneClickToken) {
    localStorage.removeItem('one_click_session_token');
  }
};

export default clearLocalStorage;
