import useSectionState from '@/state/useSectionState';
import i18n from '@/config/i18n';

const {
  getCompsetData,
} = useSectionState();

/*
    Fills missing source data for the compset on the right side
    Sets valid field only if both compsets have it in common
*/
const handleMultiDivergingBarChartData = (
  data,
  compareFirstCompset,
  compareSecondCompset,
) => data.reduce((acc, curr) => {
  const { t } = i18n.global;

  const result = acc;

  // is current hotel
  if (curr.compsetTag === compareFirstCompset && curr.value > 0.0005) {
    // compset on the right doesn't have this source
    if (!data.filter((i) => i.compsetTag === compareSecondCompset
      && i.source === curr.source).length) {
      const compsetData = getCompsetData(compareSecondCompset);

      // create empty item with source
      result.push({
        ...compsetData,
        source: t(curr.source),
        value: 0,
        valid: true,
      });
    }

    // mark hotel source as valid
    curr.valid = true;

  // is compset on the right and the current source is "common" between both compsets
  } else if (curr.compsetTag === compareSecondCompset
      && data.filter((i) => i.compsetTag === compareFirstCompset
        && i.source === curr.source
        && i.value > 0.0005).length
  ) {
    // make it valid
    curr.valid = true;
  } else {
    // skip this item (not any of the compsets we are interested in)
    return result;
  }

  result.push({
    ...curr,
    source: t(curr.source),
  });

  return result;
}, [])
  .filter((i) => i.valid)
  .sort((a, b) => b.value - a.value);

export default handleMultiDivergingBarChartData;
