import apiHelper from '@/utils/apiHelper';

const navigate = async (path, options = {}) => {
  if (typeof path !== 'string') {
    return;
  }

  let link;

  if (options.fetchUrl) {
    const { data } = await apiHelper('GET', path);
    link = data.data.link;
  } else if (options.external) {
    link = path;
  } else {
    link = `${window.location.protocol}//${window.location.host}${path}`;
  }

  if (options.newTab) {
    window.open(link, '_blank').focus();
  } else {
    window.location.href = link;
  }
};

export default navigate;
