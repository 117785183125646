import {
  geolocationVisitsPipe,
} from '@/state';

import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';

import fetchRequest from '@/utils/fetchRequest';
import updateSectionData from '@/utils/updateSectionData';

const {
  setPipeStatus,
  sectionName,
  dataId,
  currentPermissions,
} = useSectionState();

const { propertyId } = useMainState();

const sections = {
  // total users
  conversion: 'visitors',
  revenue: 'revenue',
  bookings: 'bookings',
  visitors: 'visitors',
  builder: 'visitors',
  // search users
  demand: 'demand',
  'booking-window': 'booking-window',
  'length-of-stay': 'length-of-stay',
  rates: 'rates',
  // total searches
  disparities: 'disparities',
};

// endpoint pipes by section

const getGeolocationVisitsData = async () => {
  const currentDataId = dataId.value;
  const pid = propertyId.value;

  // main geolocation visits pipe (status handling)
  const { name: geolocationVisitsPipeName } = geolocationVisitsPipe;
  // get current section's geolocation visits pipe for the request (data)
  setPipeStatus(geolocationVisitsPipeName, { loading: true });

  try {
    const overrides = {
      noFilters: true,
      params: [{ name: 'section', value: sections[sectionName.value] }],
    };

    const {
      data,
    } = await fetchRequest(geolocationVisitsPipeName, { overrides });

    const formattedGeolocationData = {
      all: [],
      country: [],
      region: [],
      city: [],
    };

    Object.keys(data).forEach((key) => {
      // current location list
      const list = data[key];
      // rename variables, swap value (visits) with id, so the filter directly updates the list with the selected ID as "value"
      const formattedList = list.map((item) => {
        let newName = item.name;

        if (key.match(/region|city/)) {
          newName = `${newName}, ${item.countryCode}`;
        }

        return {
          ...item,
          name: newName,
          label: newName,
          value: `${item.id}`,
          itemCount: item.value,
        };
      });

      // cleanFilterValues(geolocations, formattedList.map(({ value }) => value));

      // push new list
      formattedGeolocationData[key] = formattedList
      // TMP PATCH
        .reduce((acc, curr) => {
          const matchingItem = acc.filter((item) => item.value === curr.value)[0];

          if (matchingItem) {
            matchingItem.itemCount += curr.itemCount;
          } else {
            acc.push(curr);
          }

          return acc;
        }, []);

      // push to "all" tab
      if (currentPermissions.value.geofilter) {
        formattedGeolocationData.all.push(...formattedList);
      } else {
        formattedGeolocationData.all = formattedGeolocationData.country;
      }
    });

    // prepare data structure
    const newData = {
      geolocationVisits: formattedGeolocationData,
    };
    // save
    updateSectionData({
      pid,
      newData,
      currentDataId,
    });

    setPipeStatus(geolocationVisitsPipeName, { loading: false });
  } catch {
    setPipeStatus(geolocationVisitsPipeName, { hasError: true });
  }
};

export default getGeolocationVisitsData;
