import { ref, watch } from 'vue';

import env from '@/config/env';

// true debug env flag, unable to modify
const isDebugEnv = env.VUE_APP_DEBUG === 'true';
// debug ref, initial value is taken from debug env flag and should not be modified (test only)
const isDebugActive = ref(isDebugEnv);
// debug flags retrieved from the storage
const debugData = JSON.parse(localStorage.getItem('bmDebug')) ?? {};
// set initial values
const disableCountDown = ref(!!debugData.disableCountDown);
const disableAddHotelFilter = ref(!!debugData.disableAddHotelFilter);
const disableInternal = ref(!!debugData.disableInternal);
const disableRequestCaching = ref(!!debugData.disableRequestCaching);
const chartsDebug = ref(!!debugData.chartsDebug);
const logActivity = ref(!!debugData.logActivity);
const enableCalendar = ref(!!debugData.enableCalendar);
const isDestinationUser = ref(!!debugData.isDestinationUser);
const overrideCompsetsLimit = ref(!!debugData.overrideCompsetsLimit);
const isTermsModalMuted = ref(!!debugData.isTermsModalMuted);

const updateDebugData = () => {
  const newData = {
    disableCountDown: disableCountDown.value,
    disableAddHotelFilter: disableAddHotelFilter.value,
    disableInternal: disableInternal.value,
    chartsDebug: chartsDebug.value,
    logActivity: logActivity.value,
    disableRequestCaching: disableRequestCaching.value,
    enableCalendar: enableCalendar.value,
    isDestinationUser: isDestinationUser.value,
    overrideCompsetsLimit: overrideCompsetsLimit.value,
    isTermsModalMuted: isTermsModalMuted.value,
  };

  localStorage.setItem('bmDebug', JSON.stringify(newData));
};

watch([
  disableCountDown,
  disableAddHotelFilter,
  disableInternal,
  chartsDebug,
  logActivity,
  disableRequestCaching,
  enableCalendar,
  isDestinationUser,
  overrideCompsetsLimit,
  isTermsModalMuted,
], updateDebugData);

function muteTermsModal() {
  isTermsModalMuted.value = true;
}

export default function useDebugState() {
  return {
    isDebugActive,
    disableCountDown,
    disableAddHotelFilter,
    disableInternal,
    chartsDebug,
    logActivity,
    disableRequestCaching,
    enableCalendar,
    isDestinationUser,
    overrideCompsetsLimit,
    isTermsModalMuted,
    muteTermsModal,
  };
}
