import { computed } from 'vue';

import moment from 'moment';

import useFilterState from '@/state/useFilterState';
import useSectionState from '@/state/useSectionState';

import {
  disablePastDays,
  disablePastNumDays,
  disableFutureDates,
} from '@/utils/disabledCalendarFn';
import getDateRange from '@/utils/getDateRange';
import { track } from '@/utils/analytics';

const {
  currentPermissions,
  isCalendarView,
} = useSectionState();

const {
  dateBegin,
  setDates,
  setSelectedTag,
  setApplyCtpStayDates,
  unsetDates,
  getCtpDateBegin,
  getCtpDateEnd,
  ctpTag,
} = useFilterState();

const ctpLimitDays = 60;

// COMPARE TO PAST
export const isCtpDateSelectorDisabled = computed(
  () => (!currentPermissions.value.dates && disablePastDays({ date: dateBegin.value, from: moment().subtract(ctpLimitDays, 'days') })) || isCalendarView.value,
);

export const minCTPMonth = computed(() => {
  if (!currentPermissions.value.dates) {
    return moment().subtract(ctpLimitDays, 'days').startOf('month').unix();
  }
  return null;
});

export const maxCTPMonth = computed(() => moment(dateBegin.value * 1000).startOf('month').unix());

export const onCTPDatesClear = () => {
  unsetDates('ctp');
  setSelectedTag('ctp', '');
  setApplyCtpStayDates(false);
};

// Define its config and isDisabled function
export const isCTPDisabled = (date) => {
  const disabledFunctions = [
    (item) => disableFutureDates({
      date: item.date,
      from: moment(dateBegin.value * 1000).subtract(1, 'day'),
    }),
  ];

  // If user is not premium add function to disable past the last 30 days
  if (!currentPermissions.value.dates) {
    disabledFunctions.push(
      (item) => disablePastDays({ date: item.date, from: moment().subtract(ctpLimitDays, 'days') }),
      (item) => disablePastNumDays({ days: ctpLimitDays, date: item.date }),
    );
  }

  return disabledFunctions.some((fn) => {
    if (typeof fn === 'function') {
      return fn({ date });
    } return false;
  });
};

// When selecting CTP Dates (custom or predefined option)
export const onCTPSelect = ({
  db,
  de,
  tag,
  label,
}) => {
  // Early exit
  if (!tag) {
    return;
  }

  track(`CTP dates -  ${tag}`);

  setSelectedTag('ctp', tag);

  const isCustomDates = tag === 'custom';

  const tmpDb = isCustomDates ? db : getCtpDateBegin(tag).unix();
  const tmpDe = isCustomDates ? de : getCtpDateEnd(tag).unix();
  // set new dates if not disabled, otherwise clear dates
  if (!isCTPDisabled(tmpDb) && !isCTPDisabled(tmpDe)) {
    setDates({
      type: 'ctp',
      db: tmpDb,
      de: tmpDe,
      isTimestamp: true,
      userInput: true,
      label,
    });
  } else {
    onCTPDatesClear();
  }
};

export const compareToPastConfig = computed(() => {
  const invalidPreviousPeriod = !currentPermissions.value.dates // no restrictions
  // OR: not premium and exceeding 30 days since VD begin
    && disablePastNumDays({ days: ctpLimitDays, date: getCtpDateEnd('pp').unix() });

  const invalidPreviousYear = !currentPermissions.value.dates;

  if (invalidPreviousPeriod && invalidPreviousYear) {
    onCTPDatesClear();
  }

  const ctpOptions = [
    {
      label: 'Previous Period',
      sublabel: getDateRange(
        getCtpDateBegin('pp'),
        getCtpDateEnd('pp'),
      ),
      tag: 'pp',
      premium: invalidPreviousPeriod,
    },
    {
      label: 'Same Period on Past Year',
      sublabel: getDateRange(
        getCtpDateBegin('py'),
        getCtpDateEnd('py'),
      ),
      tag: 'py',
      premium: invalidPreviousYear,
    },
  ];

  const ctpCalendarConfig = {
    datesAlign: 'right',
    isDisabled: isCTPDisabled,
  };

  return {
    calendarConfig: ctpCalendarConfig,
    clearButton: true,
    defaultOptions: ctpOptions,
    defaultLabel: (ctpOptions
      .filter((i) => i.tag === ctpTag.value)[0] || {}).label || 'Compare To Past',
    tagSelected: ctpTag.value,
    onDatesSelect: onCTPSelect,
    onDatesClear: onCTPDatesClear,
    side: 'right',
  };
});
