import moment from 'moment';

import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from 'tailwind-config';

import getRandomBetween from './getRandomBetween';
import i18n from '@/config/i18n';

const { t } = i18n.global;

const { theme } = resolveConfig(tailwindConfig);

const sectionDivideParam = {
  conversion: 200000,
  revenue: 2,
  bookings: 100,
  demand: 1,
  'booking-window': 100,
  'length-of-stay': 100,
  rates: 20,
  disparities: 1000,
};

const getRandomCalendarData = ({ hotelName, sectionName, isOverview }) => {
  const values = [];

  const ownCompsetValues = {
    compset: t(isOverview ? 'THN Network' : 'Brand'),
    compsetTag: isOverview ? 'thn' : 'brand',
    color: isOverview ? theme.colors.network['500'] : theme.colors.brand['500'],
  };

  [
    ownCompsetValues,
    {
      color: theme.colors.hotel['500'],
      compset: hotelName,
      compsetTag: 'own',
    },
  ].forEach(({ color, compset, compsetTag }) => {
    values.push(...Array.from(Array(60))
      .map((item, idx) => ({
        color,
        compset,
        compsetTag,
        calendarAvg: getRandomBetween(2000, 4000),
        date: moment().subtract(60 - idx, 'days').unix() * 1000,
        ready: true,
        value: getRandomBetween(1000, 10000) / sectionDivideParam[sectionName],
      })));
  });

  return values;
};

export default getRandomCalendarData;
