const appData = {};

const setAppData = (props) => {
  Object.keys(props).forEach((key) => {
    if (
      typeof appData[key] === 'object'
      && !Array.isArray(appData[key])) {
      appData[key] = {
        ...appData[key],
        ...props[key],
      };
    } else {
      appData[key] = props[key];
    }
  });
};

const getAppData = () => appData;

export default function useAppState() {
  return {
    setAppData,
    getAppData,
  };
}
