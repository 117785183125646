/** @type {import('tailwindcss').Config} */
const lampoPreset = require('@thehotelsnetwork/lampo/dist/tailwind-preset.js');

module.exports = {
  content: [
    './src/**/*.html',
    './src/**/*.{vue,js,ts}',
  ],
  presets: [
    lampoPreset,
  ],
  theme: {
    extend: {
      lineHeight: {
        tiny: '0.8',
      },
      letterSpacing: {
        lg: '0.2rem',
      },
      height: {
        graphic: '250px',
      },
      minWidth: {
        'lang-label': '4.5rem',
        'countdown-num': '5rem',
        'compset-btn': '6rem',
        'dropdown-sm': '8.5rem',
        dropdown: '13.5rem',
        'dropdown-md': '15.5rem',
        'dropdown-lg': '20rem',
        'options-list': '12rem',
        'compset-box': '30rem',
        tooltip: '15rem',
        noti: '22.5rem',
        'login-form': '35rem',
        sidenav: '16rem',
      },
      maxWidth: {
        noti: '32.5rem',
        'login-modal': '24.5rem',
        'tooltip-base': '17.5rem',
        m: '26.7rem',
        '1/2': '50%',
      },
      borderWidth: {
        1: '1px',
      },
      inset: {
        50: '50%',
      },
      flexGrow: {
        2: '2',
      },
      boxShadow: {
        bm: '0px 0px 4px 2px rgba(187,187,187,0.4)',
        dropdown: '0px 2px 4px 2px rgba(187,187,187,0.4)',
        tooltip: '0 2px 4px 0 rgba(0,0,0,0.1)',
        'tooltip-base': '0 6px 12px 0 rgba(0,0,0,0.12)',
        'unlock-emails': '0px 4px 17px 0px rgba(0,0,0,0.22)',
        card: '0 1px 8px 0 rgba(0, 0, 0, 0.15)',
        'card-hover': '0 1px 8px 0 rgba(0, 0, 0, 0.20)',
      },
      opacity: {
        15: '0.15',
      },
      zIndex: {
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
        header: 800,
        notifications: 1100,
        modal: 900,
        tooltip: 1000,
      },
    },
  },
  variants: {
    cursor: ['responsive', 'hover', 'focus', 'disabled'],
    opacity: ['disabled'],
  },
};
