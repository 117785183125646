const calculateDiff = (
  dividend, // valor actual
  divisor, // valor pasado
) => {
  // set 0 as fallback (undefined, null)
  const tmpDividend = dividend || 0;
  const tmpDivisor = divisor || 0;

  if (tmpDivisor === 0) {
    return tmpDividend === 0
      ? 0
      : 1;
  }

  return (tmpDividend - tmpDivisor) / tmpDivisor;
};

export default calculateDiff;
