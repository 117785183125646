/* eslint-disable camelcase */
import { reactive, computed } from 'vue';

import useMainState from '@/state/useMainState';
import useDebugState from '@/state/useDebugState';

const { isDestinationUser } = useDebugState();
const { userProperties } = useMainState();

const userData = reactive({
  firstName: '',
  lastName: '',
  accountKey: '',
  premiumLevel: 1,
  language: 'en',
  email: '',
  currency: '',
  id: undefined,
  legacyId: '',
  access: 1,
  personalizationAccess: 0,
  overviewPermissions: 0,
  destinationCountdown: 0,
});
const isKeyUser = () => userData.access === 'key';
const isViewUser = () => userData.access === 'view';
const isDMOUser = computed(() => (userData.permissions?.destination === 2
  || isDestinationUser.value));
const isHybridUser = computed(() => !!(isDMOUser.value && userProperties.value.length));
const isStrictlyDMOUser = computed(() => isDMOUser.value && !userProperties.value.length);

const setUserData = (key, value) => {
  if (key && typeof value !== 'undefined') {
    userData[key] = value;
  }
};

const setUser = (user) => {
  const {
    firstName,
    lastName,
    language,
    email,
    currency,
    partnerUser,
    id,
    legacyId,
    access,
    personalizationAccess,
    hasAcceptedTerms,
    overviewPermissions,
    permissions,
    destinationCountdown,
    demand,
    safe_direct,
    predictive_personalization,
    predictive_audience,
  } = user;

  setUserData('language', language);
  setUserData('firstName', firstName);
  setUserData('lastName', lastName);
  setUserData('email', email);
  setUserData('currency', currency);
  setUserData('id', id);
  setUserData('legacyId', legacyId);
  setUserData('access', access);
  setUserData('personalizationAccess', personalizationAccess);
  setUserData('hasAcceptedTerms', hasAcceptedTerms);
  setUserData('overviewPermissions', overviewPermissions);
  setUserData('permissions', permissions);
  setUserData('destinationCountdown', destinationCountdown);
  setUserData('demand', demand);
  setUserData('safe_direct', safe_direct);
  setUserData('predictive_personalization', predictive_personalization);
  setUserData('predictive_audience', predictive_audience);

  if (partnerUser && partnerUser.accountKey) {
    setUserData('accountKey', partnerUser.accountKey);
  }
};

const isDemoUser = computed(() => [
  1034283, // productdemo@thehotelsnetwork.com
  1038073, // benchdirect_demo@thehotelsnetwork.com
  1041305, // IQS_benchdirect@thehotelsnetwork.com
  1037485, // tinybirdsdemo@thehotelsnetwork.com
  // multilanguage demo users
  1039331,
  1039332,
  1039333,
  1039334,
  1039335,
].indexOf(userData.legacyId) !== -1);

export default function useUserState() {
  return {
    userData,
    isDMOUser,
    isHybridUser,
    isStrictlyDMOUser,
    setUser,
    setUserData,
    isKeyUser,
    isViewUser,
    isDemoUser,
  };
}
