import {
  tooltip,
} from '@/config/charts';

const getCorrelatedLineChartSpec = ({
  data,
  legendValues,
  xConfig,
  yConfig,
}) => ({
  data: {
    values: data,
  },
  autosize: { type: 'fit', contains: 'padding' },
  height: 400,
  width: 'container',
  padding: 5,
  config: {
    view: { stroke: null },
    axis: {
      title: null,
      ticks: false,
      grid: false,
      tickRound: false,
      // tickCount: 1,
      bandPosition: 0.5,
      labelFont: 'Avenir',
      labelFontSize: 16,
      labelColor: '#767676',
      labelPadding: 10,
      labelFontWeight: 500,
      domainColor: '#C1C1C1',
      labelAngle: 0,
      labels: true,
      titleFontStyle: 'Avenir',
      titleFontSize: 14,
      titleColor: '#767676',
      titleFontWeight: 500,
      titlePadding: 20,
    },
    text: { font: 'Avenir', fontSize: 16 },
  },
  encoding: {
    x: {
      field: 'over',
      type: 'quantitative',
      // type: 'ordinal',
      // bin: { step: 5 },
      axis: {
        labelOverlap: true,
        labelAlign: 'center',
        labelPadding: 20,
        labelAngle: 340,
        tickOffset: { expr: '(bandwidth("x") /2) * -1' },
        orient: 'bottom',
        grid: false,
        title: xConfig.title,
        // format: xConfig.dataFormat,
      },
    },
    opacity: { condition: { value: 1, selection: 'compset' }, value: 0 },
  },
  transform: [
    {
      filter: 'datum.value !== null && (datum.value >= 0 || datum.pastValue > 0) && isNumber(datum.over)',
    },
    {
      joinaggregate: [{ op: 'values', field: 'over', as: 'matches' }],
      groupby: ['over', 'value'],
    },
  ],
  layer: [
    {
      encoding: {
        color: {
          field: 'compset',
          type: 'nominal',
          scale: { range: { field: 'color' } },
          legend: {
            offset: 25,
            title: null,
            values: legendValues,
            orient: 'bottom',
            symbolType: 'circle',
            symbolSize: 200,
            labelFont: 'Avenir',
            labelFontWeight: 500,
            labelFontSize: 14,
            labelLimit: 180,
          },
        },
      },
      layer: [
        {
          selection: {
            compset: {
              type: 'multi',
              fields: ['compset'],
              bind: 'legend',
              toggle: 'true',
            },
          },
          encoding: {
            y: {
              field: 'value',
              type: 'quantitative',
              axis: {
                tickRound: false,
                title: yConfig.title,
                format: yConfig.dataFormat,
              },
            },
            strokeWidth: {
              condition: { selection: 'linehover', value: 5 },
              value: 3,
            },
          },
          mark: { type: 'line', interpolate: 'monotone' },
        },
        {
          mark: { type: 'rule', strokeWidth: 1.5 },
          encoding: {
            color: { value: '#C1C1C1' },
            opacity: {
              condition: { value: 1, selection: 'hover' },
              value: 0,
            },
          },
        },
        {
          mark: {
            type: 'circle',
            size: 175,
            stroke: '#FFFFFF',
            strokeWidth: 3,
            tooltip,
          },
          encoding: {
            opacity: {
              condition: { value: 1, selection: 'hover' },
              value: 0,
            },
            y: { field: 'value', type: 'quantitative' },
          },
          selection: {
            hover: {
              type: 'multi',
              fields: ['over', 'value'],
              nearest: true,
              on: 'mouseover',
              empty: 'none',
              clear: 'mouseout',
            },
            linehover: {
              type: 'single',
              on: 'mouseover',
              nearest: true,
              empty: 'none',
              fields: ['compset'],
              clear: 'mouseout',
            },
          },
        },
      ],
    },
    {
      encoding: {
        color: { field: 'color', type: 'nominal', scale: null },
      },
      layer: [
        {
          encoding: {
            y: {
              field: 'pastValue',
              type: 'quantitative',
            },
          },
          mark: {
            type: 'line',
            interpolate: 'monotone',
            strokeWidth: 1.5,
            strokeDash: [6],
            opacity: 0.5,
          },
        },
        {
          mark: {
            type: 'circle',
            size: 100,
            tooltip: { content: 'data' },
            opacity: 0.5,
          },
          encoding: {
            opacity: {
              condition: { value: 1, selection: 'hover' },
              value: 0,
            },
            y: { field: 'value', type: 'quantitative' },
          },
        },
      ],
    },
  ],
});

export default getCorrelatedLineChartSpec;
