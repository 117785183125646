import formatDate from '@/utils/formatDate';
import formatValue from '@/utils/formatValue';
import setLocaleConfig from '@/utils/setLocaleConfig';
import getPropertyData from '@/utils/getPropertyData';

import i18n from '@/config/i18n';

const { t } = i18n.global;

const constructTable = (config, data) => {
  const isTimeBased = config.type === 'time';
  const isRankType = config.type === 'rank';
  const isBreakdownType = config.type === 'breakdown';
  const isCurvesType = config.type === 'curves';
  const isSourceType = isRankType
    && config.rowKey === 'source';

  if ((isTimeBased && !config.interval)
    || (!config || !data)) {
    return null;
  }

  let dataCopy = [...data];

  if (isTimeBased) {
    // sort by time
    dataCopy = dataCopy.sort((a, b) => new Date(a.date) - new Date(b.date));
  } else if (isSourceType) {
    // sort by value
    dataCopy = dataCopy.sort((a, b) => b.value - a.value);
  } else if (isCurvesType) {
    dataCopy = dataCopy.sort((a, b) => a.step - b.step);
  }

  // set locale according to received curr
  if (config.currency) {
    setLocaleConfig({ currency: config.currency });
  }

  // Create rows

  const rows = dataCopy
    .reduce((acc, obj) => {
      /*
      * rowName is either the metric or the date of the row, and belongs
      * to the first column of the table. it will be needed to organize
      * all the data.
      */
      let rowName = obj[config.rowKey];

      if (!rowName) {
        return acc;
      }

      // value that will be associated with the key
      let {
        value,
        pastValue,
        compset,
      } = obj;

      const rawPastValue = pastValue;
      const rawValue = value;

      const {
        isOwn,
        compsetTag,
        patternId,
        diff,
        propertyId,
        subTag,
      } = obj;

      if (config.isCtp && diff === undefined) {
        return acc;
      }

      let formattedDiff;

      let result = acc;

      if (isTimeBased) {
        rowName = formatDate(rowName, config.interval);
      } else if (isBreakdownType) {
        rowName = getPropertyData(rowName).name;
        if (subTag) {
          compset = subTag;
        }
      } else if (isCurvesType) {
        rowName = `${rowName} ${t('days')}`;
      }

      if (!isBreakdownType) {
        rowName = t(rowName);
      }

      if (config.dataFormat) {
        formattedDiff = (formatValue(diff, '.1~%') || '-').replace(/\s/g, '');
        pastValue = formatValue(pastValue, config.dataFormat);
        value = formatValue(value, config.dataFormat);
      }

      // Look for existing row or create a new one
      let row = result.filter((i) => i.rowName === rowName)[0];

      if (!row) {
        row = {
          rowName,
          propertyId,
          values: [],
        };
      }

      // save values
      if (!row.values.filter((c) => c.compsetTag === compsetTag).length) {
        row.values
          .push({
            compset,
            compsetTag,
            isOwn,
            value,
            rawValue,
            pastValue,
            rawPastValue,
            diff,
            formattedDiff,
            patternId, // for sorting purposes
            dataFormat: config.dataFormat,
          });
      }

      // Filter out the row
      result = result.filter((i) => i.rowName !== rowName);
      // Add the new modified row
      result.push(row);

      return result;
    }, []);

  const sortedRows = rows.map((row) => ({
    ...row,
    values: [...row.values].sort((prev, next) => prev.patternId - next.patternId),
  }));

  // set locale back to initial value
  setLocaleConfig();

  return sortedRows;
};

export default constructTable;
